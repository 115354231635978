import { CancelToken } from "axios";

import { GolferApiClientAuto } from "api/ApiClient";

export interface PromoCodeInfo {
  kind: "percentage" | "fixed";
  status: "active" | "inactive";
  discount: number;
  code: string;
}

export async function getPromoCode(
  code: string,
  cancelToken?: CancelToken
): Promise<PromoCodeInfo> {
  const response = await GolferApiClientAuto.request<{
    promo_code: PromoCodeInfo;
  }>({
    method: "GET",
    url: `/golfer/promo_codes/${code}`,
    cancelToken: cancelToken,
  });

  return response.data.promo_code;
}
