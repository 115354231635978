import axios from "axios";
import useSWR from "swr";

import { useOrganizerSession } from "utils/organizer-session";
import { getTournamentTeams, TournamentTeam } from "./getTournamentTeams";

export function useTournamentTeams(
  tournamentId: number | undefined
): TournamentTeam[] {
  const { token } = useOrganizerSession();

  const { data } = useSWR(
    token && tournamentId ? [token, "tournament-teams", tournamentId] : null,
    (
      token: string,
      _: string,
      tournamentId: number
    ): Promise<TournamentTeam[]> => {
      const cancelSource = axios.CancelToken.source();
      const promise = getTournamentTeams(
        token,
        tournamentId,
        cancelSource.token
      );
      (promise as any).cancel = () =>
        cancelSource.cancel("request was cancelled");
      return promise;
    }
  );

  return data ?? [];
}
