import axios, { AxiosRequestConfig } from "axios";
import { AppConfig } from "config/AppConfig";

export const BaseApiClientConfig: AxiosRequestConfig = {
  baseURL: AppConfig.Api.BaseUrl,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};

export const BaseApiClient = axios.create(BaseApiClientConfig);
