import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Link, Typography, LinearProgress } from "@material-ui/core";
import { Tournament } from "api/types";

interface JoinTournamentHeaderProps {
  tournament: Tournament | undefined;
  progress: number;
  step?: string;
  action?: React.ReactElement;
}

export default function JoinTournamentHeader({
  tournament,
  action,
  progress,
  step,
}: JoinTournamentHeaderProps) {
  return (
    <Box>
      <Box py={2}>
        <Typography variant="h3" component="h1" display="inline">
          Register for{" "}
          {tournament ? (
            <Link
              color="inherit"
              underline="none"
              component={RouterLink}
              to={`/tournament/${tournament.slug}`}
            >
              the {tournament.name}
            </Link>
          ) : (
            "a Tournament"
          )}{" "}
        </Typography>
        {action &&
          React.cloneElement(action, {
            style: { verticalAlign: "text-bottom" },
          })}
      </Box>
      <Box py={2}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      {step && (
        <Box py={2}>
          <Typography variant="h4" component="h2">
            {step}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
