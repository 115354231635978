import { CancelToken } from "axios";
import { OrganizerApiClientAuto } from "api/ApiClient";
import { GolfCourse } from "api/types";

export interface UpdateScoreFormModel {
  score: string | undefined;
  course: GolfCourse | undefined;
  tee_id: number | undefined;
}

export async function updateTournamentRegistrationScore(
  tournamentId: number,
  registrationId: number,
  formModel: UpdateScoreFormModel,
  cancelToken?: CancelToken
): Promise<void> {
  await OrganizerApiClientAuto.request({
    method: "PATCH",
    url: `/organizer/organization/tournaments/${tournamentId}/registrations/${registrationId}`,
    data: {
      score: formModel.score,
      course_id: formModel.course?.id,
      tee_id: formModel.tee_id,
    },
    cancelToken: cancelToken,
  });
}
