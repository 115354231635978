import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export function useNextLocation(defaultLocation: string): string {
  const { hash } = useLocation();

  const nextLocation = useMemo(() => {
    const params = new URLSearchParams(hash.replace(/^#/, ""));
    const next = params.get("next");
    if (next) {
      return decodeURIComponent(next);
    } else {
      return defaultLocation;
    }
  }, [hash, defaultLocation]);

  return nextLocation;
}

export function getLocationWithNext(pathname: string, next: string): string {
  return `${pathname}#next=${encodeURIComponent(next)}`;
}
