import React from "react";
import { Skeleton, SkeletonProps } from "@material-ui/lab";

type TextSkeletonVariant = "h2" | "h3" | "h6" | "body1" | "body2";

interface TextSkeletonProps extends Omit<SkeletonProps, "variant"> {
  variant: TextSkeletonVariant;
}

const SkeletonHeight: { [key in TextSkeletonVariant]: number } = {
  h2: 72,
  h3: 56.41,
  h6: 32,
  body1: 26.88,
  body2: 24,
};

export function TextSkeleton({ variant, ...restProps }: TextSkeletonProps) {
  return (
    <Skeleton variant="text" height={SkeletonHeight[variant]} {...restProps} />
  );
}
