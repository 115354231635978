import memoizeOne from "memoize-one";
import * as yup from "yup";

import {
  JoinTournamentFormModel,
  TournamentRegistrationMode,
  JoinTournamentFormModelErrors,
} from "api/golfer/tournament-registrations";
import { GuestTournament } from "api/guest/tournaments";
import { TournamentFormat, TournamentTeam, TShirtSize } from "api/types";
import { makeValidator } from "utils/forms";

export const POSSIBLE_NUMBER_OF_PRE_PAID_SLOTS = [1, 2, 3].map(String);

function getInitialMode(
  tournament: GuestTournament | undefined,
  initialTeamId: number
): TournamentRegistrationMode {
  if (
    tournament &&
    tournament.form !== TournamentFormat.Individual &&
    Number.isInteger(initialTeamId)
  ) {
    return TournamentRegistrationMode.JoinTeam;
  } else if (tournament && tournament.form === TournamentFormat.Individual) {
    return TournamentRegistrationMode.Individual;
  } else {
    return TournamentRegistrationMode.CreateTeam;
  }
}

export const makeInitialFormModel = memoizeOne(function makeInitialFormModel(
  tournament: GuestTournament | undefined,
  teams: TournamentTeam[],
  search: string
): JoinTournamentFormModel {
  const params = new URLSearchParams(search);
  const initialTeamId = parseInt(params.get("team_id") ?? "");

  return {
    mode: getInitialMode(tournament, initialTeamId),
    require_stripe_token: true,
    stripe_token: undefined,
    promo_code: "",
    mulligan: false,
    notes: "",
    is_tshirt_required: tournament?.offering_tshirt ?? false,
    tshirt_size: undefined,
    tos_accepted: false,
    join_team: {
      team:
        getInitialMode(tournament, initialTeamId) ===
        TournamentRegistrationMode.JoinTeam
          ? teams.find((t) => t.id === initialTeamId)
          : undefined,
      code: "",
    },
    new_team: {
      name: "",
      code: "",
      with_pre_paid_slots: false,
      number_of_pre_paid_slots: POSSIBLE_NUMBER_OF_PRE_PAID_SLOTS[0],
    },
  };
});

const CreateTournamentRegistrationSchema: yup.ObjectSchema<Partial<
  JoinTournamentFormModel
>> = yup
  .object({
    tshirt_size: yup.string().when("is_tshirt_required", {
      is: true,
      then: yup.string().oneOf(Object.values(TShirtSize)).required("required"),
    }) as yup.Schema<TShirtSize | undefined>,
    join_team: yup
      .object<JoinTournamentFormModel["join_team"]>()
      .when("mode", {
        is: TournamentRegistrationMode.JoinTeam,
        then: yup
          .object<JoinTournamentFormModel["join_team"]>({
            team: yup.object<TournamentTeam>().required("required"),
            code: yup.string().required("required"),
          })
          .defined(),
      })
      .defined(),
    new_team: yup
      .object<JoinTournamentFormModel["new_team"]>()
      .when("mode", {
        is: TournamentRegistrationMode.CreateTeam,
        then: yup
          .object<Partial<JoinTournamentFormModel["new_team"]>>({
            name: yup.string().required("required"),
            code: yup.string().required("required"),
            number_of_pre_paid_slots: yup.string().when("with_pre_paid_slots", {
              is: true,
              then: yup.string().required("required"),
            }),
          })
          .defined(),
      })
      .defined(),
  })
  .defined();

export const validateJoinTournamentFormModel = makeValidator<
  JoinTournamentFormModel,
  JoinTournamentFormModelErrors
>(CreateTournamentRegistrationSchema);

export function getNumberOfAdditionalSlots(
  mode: TournamentRegistrationMode,
  with_pre_paid_slots: boolean,
  number_of_pre_paid_slots: string
): number {
  if (mode !== TournamentRegistrationMode.CreateTeam) {
    return 0;
  }
  if (!with_pre_paid_slots) {
    return 0;
  }
  const parsed = parseInt(number_of_pre_paid_slots);
  if (!new Set([1, 2, 3]).has(parsed)) {
    return 0;
  }
  return parsed;
}
