import React, { useMemo } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Button, Link, Typography, Box } from "@material-ui/core";

import { OrganizerPage } from "components/layout";
import { TextSkeleton } from "components/core/Skeleton";
import { CopyToClipboardIcon } from "components/core/CopyToClipboardIcon";
import { useTournamentById } from "api/organizer/tournaments";

export default function TournamentCongratsPage() {
  const { tournamentId } = useParams<{ tournamentId: string }>();

  const { tournament } = useTournamentById(parseInt(tournamentId));

  const tournamentLink = useMemo<string | null>(
    () =>
      tournament
        ? `${window.location.origin}/tournament/${tournament.slug}`
        : null,
    [tournament]
  );

  return (
    <OrganizerPage contentProps={{ alignItems: "center" }}>
      <Box pt={5} />
      <Typography variant="h3" align="center">
        Congratulations!
      </Typography>
      <Typography variant="h3" align="center">
        Your Tournament has been published!
      </Typography>
      <Box pt={7} />
      <Typography variant="body1" align="center">
        You can start promoting your tournament by sharing your registration
        link:
      </Typography>
      <Box pt={1.25} />
      {tournamentLink ? (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          maxWidth="100%"
        >
          <Link
            href={tournamentLink}
            variant="body1"
            align="center"
            style={{
              fontWeight: 500,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {tournamentLink}
          </Link>
          <span>&nbsp;</span>
          <CopyToClipboardIcon
            textToCopy={tournamentLink}
            size="small"
            style={{ fontSize: "1.15rem" }}
          />
        </Box>
      ) : (
        <TextSkeleton variant="body1" width="40%" />
      )}
      <Box pt={7} />
      <Button
        component={RouterLink}
        to="/organizer/dashboard"
        style={{ width: 250 }}
      >
        Go to Dashboard
      </Button>
    </OrganizerPage>
  );
}
