import ReactCrop from "react-image-crop";

// Credits: https://github.com/DominicTobias/react-image-crop#faq
/**
 * @param {HTMLImageElement} image - Image File Object
 * @param {Object} crop - crop Object
 * @param {String} fileName - Name of the returned file in Promise
 */
export function getCroppedImg(
  image: HTMLImageElement,
  crop: Required<ReactCrop.Crop>,
  fileName: string
): Promise<File> {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width * scaleX;
  canvas.height = crop.height * scaleX;
  const ctx = canvas.getContext("2d")!;

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleX
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (blob) {
          resolve(blobToFile(blob, `${fileName}.jpg`));
        } else {
          reject(Error("something went wrong"));
        }
      },
      "image/jpeg",
      1
    );
  });
}

function blobToFile(b: Blob, fileName: string): File {
  const file = b as any;
  file.name = fileName;
  file.lastModified = new Date();
  return file as File;
}

export function isEligibleCrop(
  crop: ReactCrop.Crop
): crop is Required<ReactCrop.Crop> {
  return (
    typeof crop.aspect === "number" &&
    typeof crop.x === "number" &&
    typeof crop.y === "number" &&
    typeof crop.width === "number" &&
    typeof crop.height === "number" &&
    typeof crop.unit === "string"
  );
}
