import React, { useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Link,
  Button,
  Grid,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { formatISO9075 } from "date-fns";

import { OrganizerPage } from "components/layout";
import { Tournament } from "api/types";
import { TextSkeleton } from "components/core/Skeleton";
import {
  OrganizerTournamentRegistration,
  exportTournamentRegistrations,
  TournamentRegistrationsExportType,
} from "api/organizer/tournament-registrations";
import { DownloadFileButton } from "components/core/DownloadFileButton";

import { RegistrationScoreEntry } from "./TournamentScoresPage.utils";
import { RegistrationEntryTableRow } from "./components/RegistrationEntryTableRow";

interface TournamentScoresPageProps {
  tournament: Tournament | undefined;
  entries: RegistrationScoreEntry[];
  onRegistrationUpdated: (
    updatedRegistration: OrganizerTournamentRegistration
  ) => void;
}

export default function TournamentScoresPage({
  tournament,
  entries,
  onRegistrationUpdated,
}: TournamentScoresPageProps) {
  const download = useCallback(
    () =>
      exportTournamentRegistrations(
        tournament?.id!,
        TournamentRegistrationsExportType.Scores
      ),
    [tournament]
  );

  const getFileName = useCallback(
    () => `${tournament?.name} - Scores - ${formatISO9075(new Date())}.csv`,
    [tournament]
  );

  return (
    <OrganizerPage>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {tournament ? (
            <Typography variant="h2" component="h1">
              <Link
                color="inherit"
                underline="none"
                component={RouterLink}
                to={`/tournament/${tournament.slug}`}
              >
                {tournament.name}
              </Link>{" "}
              - Score Manager
            </Typography>
          ) : (
            <TextSkeleton variant="h2" width="50%" />
          )}
        </Grid>
        <Grid item xs={12}>
          <Button
            component={RouterLink}
            to="/organizer/dashboard"
            style={{ marginRight: 8 }}
            size="small"
          >
            Back to Dashboard
          </Button>
          <DownloadFileButton
            download={download}
            getFileName={getFileName}
            size="small"
          >
            Export to CSV
          </DownloadFileButton>
        </Grid>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                {[
                  "Team",
                  "Score (in Strokes)",
                  "Course Played",
                  "Tee",
                  "Net to PAR",
                  "",
                ].map((col, index) => (
                  <TableCell key={index}>
                    <Typography variant="body2">{col}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {entries.map((entry) => (
                <RegistrationEntryTableRow
                  key={entry.id}
                  entry={entry}
                  onRegistrationUpdated={onRegistrationUpdated}
                />
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </OrganizerPage>
  );
}
