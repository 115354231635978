import { useCallback } from "react";
import useSWR from "swr";
import axios from "axios";

import { useOrganizerSession } from "utils/organizer-session";
import { getOrganizerTournamentRegistrations } from "./getOrganizerTournamentRegistrations";
import { OrganizerTournamentRegistration } from "./OrganizerTournamentRegistration.types";

interface UseOrganizerTournamentRegistrationsResult {
  registrations: OrganizerTournamentRegistration[];
  isInitialized: boolean;
  onRegistrationUpdated: (r: OrganizerTournamentRegistration) => void;
}

export function useOrganizerTournamentRegistrations(
  tournamentId: number
): UseOrganizerTournamentRegistrationsResult {
  const { token } = useOrganizerSession();
  const { data, mutate } = useSWR(
    token ? [token, "organizer-tournament-registrations", tournamentId] : null,
    (
      token: string,
      _: any,
      tournamentId: number
    ): Promise<OrganizerTournamentRegistration[]> => {
      const cancelSource = axios.CancelToken.source();
      const promise = getOrganizerTournamentRegistrations(
        token,
        tournamentId,
        cancelSource.token
      );
      (promise as any).cancel = () =>
        cancelSource.cancel("request was cancelled");
      return promise;
    }
  );

  const onRegistrationUpdated = useCallback(
    (updatedRegistration: OrganizerTournamentRegistration) => {
      mutate(
        async (
          registrations: OrganizerTournamentRegistration[] | undefined
        ): Promise<OrganizerTournamentRegistration[]> => {
          return registrations
            ? registrations.map((r) =>
                r.id === updatedRegistration.id ? updatedRegistration : r
              )
            : [];
        }
      );
    },
    [mutate]
  );

  return {
    registrations: data ?? [],
    isInitialized: Boolean(data),
    onRegistrationUpdated,
  };
}
