import React, { useCallback, useMemo } from "react";
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { matchPath } from "react-router-dom";

import {
  GolferRegistrationModel,
  registerAndGetGolfer,
  parseGolferRegistrationErrors,
} from "api/golfer/account";
import { useUnitedStates } from "api/common/hooks";
import {
  EnsureGolferNotLoggedIn,
  useGolferSession,
} from "utils/golfer-session";
import { useTournamentBySlug } from "api/guest/tournaments/hooks";
import { GolferPostLoginLocationContainer } from "config/globals";
import { useLSValue } from "utils/createLSValueContainer";
import { getApiErrorMessage } from "utils/network";

import CreateGolferAccountPage from "./CreateGolferAccountPage";
import {
  makeInitialFormModel,
  validateGolferRegistrationModel,
} from "./CreateGolferAccountPage.utils";

function CreateGolferAccountPageContainer() {
  const { logIn } = useGolferSession();

  const onSubmit = useCallback(
    async (formModel: GolferRegistrationModel): Promise<any> => {
      try {
        const { token, user } = await registerAndGetGolfer(formModel);
        logIn(token, user);
      } catch (err) {
        const errors = parseGolferRegistrationErrors(err);
        if (Object.keys(errors).length > 0) {
          return errors;
        } else {
          return { [FORM_ERROR]: getApiErrorMessage(err) };
        }
      }
    },
    [logIn]
  );

  const country = useUnitedStates();

  const postLoginLocation = useLSValue(GolferPostLoginLocationContainer);

  const tournamentSlug = useMemo<string | undefined>(() => {
    if (!postLoginLocation) {
      return undefined;
    }
    const match = matchPath<{ slug: string }>(postLoginLocation.pathname, {
      path: "/tournament/:slug/register",
      exact: true,
    });
    return match?.params.slug;
  }, [postLoginLocation]);

  const { tournament } = useTournamentBySlug(tournamentSlug);

  return (
    <Form<GolferRegistrationModel>
      onSubmit={onSubmit}
      initialValues={makeInitialFormModel(country)}
      validate={validateGolferRegistrationModel}
      validateOnBlur
    >
      {({ handleSubmit, submitting, submitError }) => (
        <CreateGolferAccountPage
          withTournament={Boolean(tournamentSlug)}
          tournament={tournament}
          handleSubmit={handleSubmit}
          submitting={submitting}
          submitError={submitError}
        />
      )}
    </Form>
  );
}

export default EnsureGolferNotLoggedIn(CreateGolferAccountPageContainer);
