import EventEmitter from "eventemitter3";

type AuthTokenChangeListener = (token: string | null) => void;

export interface AuthTokenStorage {
  getAuthToken: () => string | null;
  setAuthToken: (token: string) => void;
  clearAuthToken: () => void;
  addChangeListener: (f: AuthTokenChangeListener) => void;
  removeChangeListener: (f: AuthTokenChangeListener) => void;
}

export function createAuthTokenStorage(
  localStorageKey: string
): AuthTokenStorage {
  const emitter = new EventEmitter();

  window.addEventListener("storage", ({ key, newValue }) => {
    if (key === localStorageKey) {
      emitter.emit("change", newValue);
    }
  });

  return {
    getAuthToken: () => localStorage.getItem(localStorageKey),
    setAuthToken(newToken: string) {
      localStorage.setItem(localStorageKey, newToken);
      emitter.emit("change", newToken);
    },
    clearAuthToken() {
      localStorage.removeItem(localStorageKey);
      emitter.emit("change", null);
    },
    addChangeListener(listener: AuthTokenChangeListener) {
      emitter.on("change", listener);
    },
    removeChangeListener(listener: AuthTokenChangeListener) {
      emitter.off("change", listener);
    },
  };
}
