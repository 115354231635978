import { AxiosRequestConfig } from "axios";

export function addTokenToRequestConfig(
  config: AxiosRequestConfig,
  token: string | undefined
): AxiosRequestConfig {
  if (!token) {
    return config;
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Token token="${token}"`,
    },
  };
}
