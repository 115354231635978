import React, { useCallback } from "react";
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";

import { Tournament } from "api/types";
import {
  TournamentFormModel,
  parseTournamentErrors,
} from "api/organizer/tournaments";
import { searchCourses } from "api/organizer/courses";
import { Organization } from "api/organizer/organization";
import { getApiErrorMessage } from "utils/network";

import TournamentForm from "./TournamentForm";
import {
  makeInitialFormModel,
  validateTournamentFormModel,
} from "./TournamentForm.utils";

interface TournamentFormContainerProps {
  saveFormModel: (formModel: TournamentFormModel) => Promise<void>;
  initialTournament?: Tournament;
  organization: Organization | undefined;
  disabled?: boolean;
  isPublishedTournamentMode?: boolean;
}

export default function TournamentFormContainer({
  saveFormModel,
  initialTournament,
  organization,
  disabled = false,
  isPublishedTournamentMode = false,
}: TournamentFormContainerProps) {
  const onSubmit = useCallback(
    async (formModel: TournamentFormModel): Promise<any> => {
      try {
        await saveFormModel(formModel);
      } catch (err) {
        const errors = parseTournamentErrors(err);
        if (Object.keys(errors).length > 0) {
          return errors;
        } else {
          return { [FORM_ERROR]: getApiErrorMessage(err) };
        }
      }
    },
    [saveFormModel]
  );

  return (
    <Form<TournamentFormModel>
      onSubmit={onSubmit}
      initialValues={makeInitialFormModel(initialTournament)}
      validate={validateTournamentFormModel}
      validateOnBlur
    >
      {({ handleSubmit, submitting, submitError }) => (
        <TournamentForm
          handleSubmit={handleSubmit}
          submitting={submitting}
          submitError={submitError}
          searchCourses={searchCourses}
          organization={organization}
          disabled={disabled}
          isPublishedTournamentMode={isPublishedTournamentMode}
        />
      )}
    </Form>
  );
}
