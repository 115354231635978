import React, { useCallback } from "react";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  Grid,
  InputLabel,
  OutlinedInput,
  Grow,
  Typography,
  Link,
  FormHelperText,
} from "@material-ui/core";

import { GolferPage } from "components/layout";
import { Alert } from "components/core/Alert";
import { loginAndGetGolfer } from "api/golfer/account";
import {
  useGolferSession,
  EnsureGolferNotLoggedIn,
} from "utils/golfer-session";
import { getApiErrorMessage } from "utils/network";

interface LoginFormModel {
  email: string;
  password: string;
}

function LoginPage() {
  const { logIn } = useGolferSession();

  const onSubmit = useCallback(
    async (formModel: LoginFormModel): Promise<any> => {
      try {
        const { token, user } = await loginAndGetGolfer(
          formModel.email,
          formModel.password
        );
        logIn(token, user);
      } catch (err) {
        return { [FORM_ERROR]: getApiErrorMessage(err) };
      }
    },
    [logIn]
  );

  return (
    <GolferPage contentProps={{ style: { maxWidth: 400 } }}>
      <Form<LoginFormModel>
        onSubmit={onSubmit}
        initialValues={{ email: "", password: "" }}
      >
        {({ handleSubmit, submitting, submitError }) => (
          <form onSubmit={handleSubmit}>
            <Typography variant="h4" component="h1" align="center">
              Golfer login
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grow in={Boolean(submitError)}>
                  <Alert>
                    <Typography variant="body1">
                      Could not login you. Please check your email and password.
                    </Typography>
                  </Alert>
                </Grow>
              </Grid>
              <Field<string> name="email">
                {({ input, meta }) => (
                  <Grid item xs={12}>
                    <InputLabel
                      htmlFor="golfer-email"
                      error={meta.touched && meta.error}
                    >
                      Email
                    </InputLabel>
                    <OutlinedInput
                      fullWidth
                      id="golfer-email"
                      type="email"
                      placeholder="john.doe@example.com"
                      autoFocus
                      error={meta.touched && meta.error}
                      {...input}
                    />
                  </Grid>
                )}
              </Field>
              <Field<string> name="password">
                {({ input, meta }) => (
                  <Grid item xs={12}>
                    <InputLabel
                      htmlFor="golfer-password"
                      error={meta.touched && meta.error}
                    >
                      Password
                    </InputLabel>
                    <OutlinedInput
                      fullWidth
                      id="golfer-password"
                      type="password"
                      error={meta.touched && meta.error}
                      {...input}
                    />
                    <FormHelperText
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Link component={RouterLink} to="/golfer/forgot-password">
                        Forgot password?
                      </Link>
                    </FormHelperText>
                  </Grid>
                )}
              </Field>
              <Grid item xs={12}>
                <Button fullWidth type="submit" disabled={submitting}>
                  Login
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography align="right">
                  Don't have an account?{" "}
                  <Link component={RouterLink} to="/golfer/create-account">
                    Register
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </form>
        )}
      </Form>
    </GolferPage>
  );
}

export default EnsureGolferNotLoggedIn(LoginPage);
