import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  ButtonBase,
  Box,
  Typography,
  makeStyles,
  Avatar,
  Button,
} from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import { format, parseISO } from "date-fns";

import { GuestTournament } from "api/guest/tournaments";
import { FlexGolfTheme } from "config/theme";
import { Tournament } from "api/types";

interface FeaturedTournamentCardProps {
  tournament: GuestTournament;
}

export default function FeaturedTournamentCard({
  tournament,
}: FeaturedTournamentCardProps) {
  const classes = useStyles();

  return (
    <ButtonBase
      component={RouterLink}
      to={`/tournament/${tournament.slug}`}
      className={classes.root}
    >
      <Avatar
        variant="square"
        style={{
          alignSelf: "center",
          width: 82,
          height: 82,
          backgroundColor: "transparent",
        }}
        src={tournament.organization.logo?.medium}
        alt="Organization Logo"
      >
        <ImageIcon style={{ color: "#9eadb9", fontSize: "1.5rem" }} />
      </Avatar>
      <Typography align="center">{tournament.organization.name}</Typography>
      <Typography align="center" variant="h5" style={{ fontWeight: "bold" }}>
        {tournament.name}
      </Typography>
      <Typography align="center" variant="caption">
        {formatTournamentDates(tournament)}
      </Typography>
      <Box display="flex" justifyContent="center">
        <Button variant="contained" component="div">
          Register
        </Button>
      </Box>
    </ButtonBase>
  );
}

const useStyles = makeStyles((theme: FlexGolfTheme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    padding: theme.spacing(3),
    backgroundColor: theme.darkBlock.background,
    color: theme.darkBlock.text,
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
}));

export function formatTournamentDates(tournament: Tournament): string {
  const start = format(parseISO(tournament.start_date), "MMMM do");
  const end = format(parseISO(tournament.end_date), "MMMM do");

  return `${start} — ${end}`;
}
