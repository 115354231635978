import React from "react";

import { useOrganizerSession } from "utils/organizer-session";

import { Page } from "../Page";
import { PageContent, PageContentProps } from "../PageContent";
import { PageFooter } from "../PageFooter";
import OrganizerPageHeader from "./OrganizerPageHeader";

interface OrganizerPageProps {
  children: React.ReactNode;
  contentProps?: Omit<PageContentProps, "children">;
}

export default function OrganizerPage({
  children,
  contentProps = {},
}: OrganizerPageProps) {
  const { status, user, logOut } = useOrganizerSession();

  return (
    <Page>
      <OrganizerPageHeader status={status} user={user} onLogout={logOut} />
      <PageContent my={6} alignSelf="center" flexGrow={1} {...contentProps}>
        {children}
      </PageContent>
      <PageFooter />
    </Page>
  );
}
