import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import { AppConfig } from "config/AppConfig";

export function AppRootHelmet() {
  const location = useLocation();

  const currentPageUrl = useMemo(
    () => `${window.location.origin}${location.pathname}`,
    [location]
  );

  return (
    <Helmet>
      <title>{AppConfig.Application.Name}</title>
      <meta name="description" content={AppConfig.Application.Description} />
      <meta property="og:title" content={AppConfig.Application.Name} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={AppConfig.Application.Image} />
      <meta property="og:url" content={currentPageUrl} />
      <meta
        property="og:description"
        content={AppConfig.Application.Description}
      />
      <meta property="og:site_name" content={AppConfig.Application.Name} />
      <meta property="og:locale" content="en_US" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}
