import { CancelToken, AxiosResponse } from "axios";

import { makeGolferApiClient } from "../../ApiClient";
import { GolfCourse } from "../../types";

export async function searchCourses(
  token: string,
  query: string,
  cancelToken?: CancelToken
): Promise<GolfCourse[]> {
  const response = await makeGolferApiClient(token).request<
    void,
    AxiosResponse<{ courses: GolfCourse[] }>
  >({
    method: "GET",
    url: "/organizer/courses",
    params: { query },
    cancelToken: cancelToken,
  });
  return response.data.courses;
}
