import React from "react";
import { Redirect } from "react-router-dom";

import { SessionServiceStatus } from "utils/session";
import { getComponentWrappedName } from "utils/getComponentWrappedName";

import { useOrganizerSession } from "./OrganizerSessionProvider";

export function EnsureOrganizerLoggedIn<P>(
  Component: React.ComponentType<P>,
  redirectTo: string = "/organizer/login"
) {
  function EnsureOrganizerLoggedInWrapper(props: P) {
    const { status } = useOrganizerSession();

    if (status === SessionServiceStatus.Unauthenticated) {
      return <Redirect to={redirectTo} />;
    } else {
      return <Component {...props} />;
    }
  }

  EnsureOrganizerLoggedInWrapper.displayName = getComponentWrappedName(
    "EnsureOrganizerLoggedIn",
    Component
  );

  return EnsureOrganizerLoggedInWrapper;
}
