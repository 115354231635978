import axios, { AxiosInstance } from "axios";
import memoizeOne from "memoize-one";

import { addTokenToRequestConfig } from "config/addTokenToRequestConfig";

import { BaseApiClientConfig } from "./BaseApiClient";
import { GolferSessionService } from "utils/golfer-session";

export const makeGolferApiClient = memoizeOne(function makeGolferApiClient(
  token: string | undefined
): AxiosInstance {
  return axios.create(addTokenToRequestConfig(BaseApiClientConfig, token));
});

export const GolferApiClientAuto = axios.create(BaseApiClientConfig);

export function initializeGolferApiClientAuto(
  sessionService: GolferSessionService
) {
  GolferApiClientAuto.interceptors.request.use(
    function (config) {
      return addTokenToRequestConfig(config, sessionService.getState().token);
    },
    function (error) {
      return Promise.reject(error);
    }
  );
}
