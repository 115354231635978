import { CancelToken } from "axios";

import { BaseApiClient } from "../../ApiClient";
import { PasswordsModel } from "../../types";

export async function resetPassword(
  reset_password_token: string,
  passwords: PasswordsModel,
  cancelToken?: CancelToken
): Promise<void> {
  await BaseApiClient.request({
    method: "PATCH",
    url: "/golfer/password",
    data: {
      ...passwords,
      reset_password_token,
    },
    cancelToken: cancelToken,
  });
}
