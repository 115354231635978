import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Divider,
  Collapse,
  OutlinedInput,
} from "@material-ui/core";
import { Field } from "react-final-form";

import { GolferPage } from "components/layout";
import { Alert } from "components/core/Alert";
import {
  BasicFormRow,
  FormRow,
  FormRowInputLabel,
  FormRowOutlinedInput,
  FormRowWithHelperText,
} from "components/core/FormRow";
import { StateIdSelect } from "components/core/StateIdSelect";
import { Tournament } from "api/types";

import { JoinTournamentHeader } from "../JoinTournamentSection/components/JoinTournamentHeader";
import { AppConfig } from "config/AppConfig";

interface CreateGolferAccountPageProps {
  withTournament: boolean;
  tournament: Tournament | undefined;
  handleSubmit: (...args: any[]) => void;
  submitting: boolean;
  submitError: string | undefined;
}

export default function CreateGolferAccountPage({
  withTournament,
  tournament,
  handleSubmit,
  submitting,
  submitError,
}: CreateGolferAccountPageProps) {
  return (
    <GolferPage>
      {withTournament && (
        <JoinTournamentHeader
          tournament={tournament}
          progress={25}
          step="Step 1: Create a Golfer Account"
          action={
            <Button size="small" component={RouterLink} to="/golfer/login">
              I already have an account
            </Button>
          }
        />
      )}
      {!withTournament && (
        <Box my={1}>
          <Typography variant="h3" component="h1" display="inline">
            Join {AppConfig.Application.Name}!
          </Typography>
          <Button
            size="small"
            component={RouterLink}
            to="/golfer/login"
            style={{ marginLeft: 8, verticalAlign: "text-bottom" }}
          >
            I already have an account
          </Button>
        </Box>
      )}
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        {/* first_name, last_name */}
        <FormRow withLabel={<FormRowInputLabel>Name</FormRowInputLabel>}>
          <FormRowOutlinedInput
            name="first_name"
            type="text"
            placeholder="John"
          />
          <FormRowOutlinedInput
            name="last_name"
            type="text"
            placeholder="Doe"
          />
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="golfer-email">Email</FormRowInputLabel>
          }
        >
          <FormRowOutlinedInput
            id="golfer-email"
            name="email"
            type="email"
            placeholder="john.doe@example.com"
          />
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="golfer-phone">Phone</FormRowInputLabel>
          }
        >
          <FormRowOutlinedInput
            id="golfer-phone"
            name="phone"
            type="tel"
            placeholder="555-555-5555"
          />
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="golfer-street-1">
              Street Address 1
            </FormRowInputLabel>
          }
        >
          <FormRowOutlinedInput
            id="golfer-street-1"
            name="address_attributes.street_1"
            placeholder="123 Charity st."
          />
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="golfer-street-2">
              Street Address 2
            </FormRowInputLabel>
          }
        >
          <FormRowOutlinedInput
            id="golfer-street-2"
            name="address_attributes.street_2"
            placeholder="Suite 123"
          />
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="golfer-city">City</FormRowInputLabel>
          }
        >
          <FormRowOutlinedInput
            id="golfer-city"
            name="address_attributes.city"
          />
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="golfer-state">State</FormRowInputLabel>
          }
        >
          <Field<number | undefined> name="address_attributes.country_id">
            {({ input: { value: countryId } }) => (
              <Field<number | undefined> name="address_attributes.state_id">
                {({ input, meta }) => (
                  <FormRowWithHelperText
                    helperText={
                      meta.touched ? meta.error || meta.submitError : null
                    }
                    isErrored={Boolean(
                      meta.submitError || (meta.touched && meta.error)
                    )}
                  >
                    <StateIdSelect
                      fullWidth
                      style={{ width: 220 }}
                      input={
                        <OutlinedInput
                          error={
                            meta.submitError || (meta.touched && meta.error)
                          }
                        />
                      }
                      id="golfer-state"
                      countryId={countryId}
                      {...input}
                    />
                  </FormRowWithHelperText>
                )}
              </Field>
            )}
          </Field>
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="golfer-zip">Zip</FormRowInputLabel>
          }
        >
          <FormRowOutlinedInput
            style={{ width: 120 }}
            id="golfer-zip"
            type="text"
            name="address_attributes.zip"
            placeholder="12345"
          />
        </FormRow>
        <BasicFormRow>
          <Divider />
        </BasicFormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="golfer-password">
              Password
            </FormRowInputLabel>
          }
        >
          <FormRowOutlinedInput
            id="golfer-password"
            name="password"
            type="password"
            autoComplete="new-password"
          />
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="golfer-confirm-password">
              Confirm Password
            </FormRowInputLabel>
          }
        >
          <FormRowOutlinedInput
            id="golfer-confirm-password"
            name="password_confirmation"
            type="password"
          />
        </FormRow>
        <Collapse in={Boolean(submitError)}>
          <FormRow>
            <Alert fullWidth>
              <Typography variant="body1">{submitError}</Typography>
            </Alert>
          </FormRow>
        </Collapse>
        <FormRow>
          <Button type="submit" disabled={submitting}>
            Create Account
          </Button>
        </FormRow>
      </form>
    </GolferPage>
  );
}
