export interface LocalImage {
  type: "local-image";
  file: File;
}

export interface RemoteImage {
  type: "remote-image";
  src: string;
}

export type ImageInputValue = LocalImage | RemoteImage;

export function isRemoteImageValue(
  image: ImageInputValue
): image is RemoteImage {
  return image.type === "remote-image";
}

export function isLocalImageValue(image: ImageInputValue): image is LocalImage {
  return image.type === "local-image";
}

export function createRemoteImageValue(src: string): RemoteImage {
  return { type: "remote-image", src };
}

export function createLocalImageValue(file: File): LocalImage {
  return { type: "local-image", file };
}
