import React, { useCallback } from "react";
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { useHistory } from "react-router-dom";

import {
  EnsureOrganizerLoggedIn,
  useOrganizerSession,
} from "utils/organizer-session";
import {
  OrganizationFormModel,
  createOrganization,
  updateOrganization,
  parseOrganizationErrors,
  useOrganization,
} from "api/organizer/organization";
import { useUnitedStates } from "api/common/hooks";
import { getApiErrorMessage } from "utils/network";

import UpsertOrganizationPage from "./UpsertOrganizationPage";
import {
  makeInitialFormModel,
  validateOrganizationFormModel,
} from "./UpsertOrganizationPage.utils";

function UpsertOrganizationPageContainer() {
  const history = useHistory();
  const { token } = useOrganizerSession();

  const [organization, isLoading] = useOrganization();
  const country = useUnitedStates();

  const onSubmit = useCallback(
    async (formModel: OrganizationFormModel): Promise<any> => {
      try {
        if (organization) {
          await updateOrganization(token, formModel);
          history.push("/organizer/dashboard");
        } else {
          await createOrganization(token, formModel);
          history.push("/organizer/start-fundraiser");
        }
      } catch (err) {
        const errors = parseOrganizationErrors(err);
        if (Object.keys(errors).length > 0) {
          return errors;
        } else {
          return { [FORM_ERROR]: getApiErrorMessage(err) };
        }
      }
    },
    [token, history, organization]
  );

  return (
    <Form<OrganizationFormModel>
      onSubmit={onSubmit}
      initialValues={makeInitialFormModel(organization, country)}
      validate={validateOrganizationFormModel}
      validateOnBlur
    >
      {({ handleSubmit, submitting, submitError }) => (
        <UpsertOrganizationPage
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          submitting={submitting}
          submitError={submitError}
        />
      )}
    </Form>
  );
}

export default EnsureOrganizerLoggedIn(UpsertOrganizationPageContainer);
