import React from "react";
import { Box, FormHelperText, Fade } from "@material-ui/core";

interface FormRowWithHelperTextProps {
  helperText?: string;
  isErrored?: boolean;
  children: React.ReactNode;
}

export function FormRowWithHelperText({
  helperText,
  isErrored,
  children,
}: FormRowWithHelperTextProps) {
  return (
    <Box width="100%" position="relative">
      {children}
      <Fade in={Boolean(helperText)}>
        <FormHelperText
          error={isErrored}
          style={{ position: "absolute", top: "100%", left: 0 }}
        >
          {helperText}
        </FormHelperText>
      </Fade>
    </Box>
  );
}
