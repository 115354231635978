import { AxiosResponse, CancelToken } from "axios";

import { remapError, remapNestedErrors } from "utils/network/validations";

import { BaseApiClient } from "../../ApiClient";
import { AddressAttributes, AddressAttributesErrors } from "../../types";
import { Golfer, getGolferByToken } from "./getGolferByToken";

export interface GolferRegistrationModel {
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  password: string;
  password_confirmation: string;
  address_attributes: AddressAttributes;
}

export type GolferRegistrationErrors = {
  [k in Exclude<keyof GolferRegistrationModel, "address_attributes">]?: string;
} & {
  address_attributes?: AddressAttributesErrors;
};

export async function register(
  newGolfer: GolferRegistrationModel,
  cancelToken?: CancelToken
): Promise<string> {
  const response = await BaseApiClient.request<
    GolferRegistrationModel,
    AxiosResponse<{ user_access_token: { key: string } }>
  >({
    method: "POST",
    url: "/golfer/registration",
    data: newGolfer,
    cancelToken: cancelToken,
  });
  return response.data.user_access_token.key;
}

export async function registerAndGetGolfer(
  newGolfer: GolferRegistrationModel,
  cancelToken?: CancelToken
): Promise<{ user: Golfer; token: string }> {
  const t = await register(newGolfer, cancelToken);
  return await getGolferByToken(t, cancelToken);
}

export function parseGolferRegistrationErrors(
  error: Error
): GolferRegistrationErrors {
  const errors: GolferRegistrationErrors = {};
  const apiErrors = (error as any).response?.data?.error?.validations ?? {};
  remapError(errors, "first_name", apiErrors.first_name);
  remapError(errors, "last_name", apiErrors.last_name);
  remapError(errors, "email", apiErrors.email);
  remapError(errors, "phone", apiErrors.phone);
  remapError(errors, "password", apiErrors.password);
  remapError(errors, "password_confirmation", apiErrors.password_confirmation);
  remapNestedErrors(
    errors,
    "address_attributes",
    (address_attributes_errors: AddressAttributesErrors) => {
      remapError(
        address_attributes_errors,
        "state_id",
        apiErrors.address?.state_id
      );
      remapError(
        address_attributes_errors,
        "state_id",
        apiErrors.address?.state
      );
      remapError(
        address_attributes_errors,
        "street_1",
        apiErrors.address?.street_1
      );
      remapError(
        address_attributes_errors,
        "street_2",
        apiErrors.address?.street_2
      );
      remapError(address_attributes_errors, "city", apiErrors.address?.city);
      remapError(address_attributes_errors, "zip", apiErrors.address?.zip);
    }
  );
  return errors;
}
