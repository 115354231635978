import React, { useCallback, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  TableCell,
  TableRow,
  Box,
  Avatar,
  Typography,
  Link,
} from "@material-ui/core";
import { useSnackbar } from "notistack";

import { TournamentSponsor } from "api/types";
import { deleteTournamentSponsor } from "api/organizer/tournament-sponsors";
import { getApiErrorMessage } from "utils/network";
import { ConfirmationPopper } from "components/core/ConfirmationPopper";

interface SponsorTableRowProps {
  sponsor: TournamentSponsor;
  onRemoved: () => void;
}

export default function SponsorTableRow({
  sponsor,
  onRemoved,
}: SponsorTableRowProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [isRemoving, setRemoving] = useState(false);

  const onDelete = useCallback(async () => {
    try {
      setRemoving(true);
      await deleteTournamentSponsor(sponsor.tournament_id, sponsor.id);
      onRemoved();
      setRemoving(false);
    } catch (err) {
      setRemoving(false);
      enqueueSnackbar(getApiErrorMessage(err), { variant: "error" });
    }
  }, [enqueueSnackbar, sponsor, onRemoved]);

  return (
    <TableRow style={{ opacity: isRemoving ? 0.6 : 1 }}>
      <TableCell>
        <Typography>{sponsor.name}</Typography>
      </TableCell>
      <TableCell>
        <Avatar
          variant="square"
          src={sponsor.logo?.medium}
          alt={sponsor.name}
          style={{ width: 120, height: 120 }}
          imgProps={{ style: { objectFit: "contain" } }}
        />
      </TableCell>
      <TableCell>
        <Link
          variant="body1"
          href={sponsor.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {sponsor.url}
        </Link>
      </TableCell>
      <TableCell>
        <Box display="flex" flexDirection="column">
          <Link
            variant="body1"
            component={RouterLink}
            to={{
              pathname: `/organizer/tournament/${sponsor.tournament_id}/sponsors/edit`,
              state: { sponsor },
            }}
          >
            Edit
          </Link>
          <Box>
            <ConfirmationPopper
              id={`remove-sponsor=${sponsor.id}`}
              onConfirm={onDelete}
              disabled={isRemoving}
            >
              {(props) => (
                <Link
                  variant="body1"
                  component="button"
                  style={{ textAlign: "left" }}
                  {...props}
                >
                  Delete
                </Link>
              )}
            </ConfirmationPopper>
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
}
