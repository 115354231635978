import React from "react";
import { Typography, Box, CircularProgress } from "@material-ui/core";

import { TournamentRegistration } from "api/golfer/tournament-registrations";

interface PaymentPendingProps {
  registration: TournamentRegistration;
}

export default function PaymentPending({ registration }: PaymentPendingProps) {
  return (
    <Box my={3}>
      <Typography variant="h5" component="p">
        Processing Your Payment
      </Typography>
      <Box p={3} display="flex" flexDirection="row" justifyContent="center">
        <CircularProgress size="3rem" color="primary" />
      </Box>
    </Box>
  );
}
