import { CancelToken } from "axios";

import { remapError } from "utils/network/validations";

import { GolferApiClientAuto } from "../..//ApiClient";
import { GolfCourse } from "../../types";

export interface SubmitScoreFormModel {
  course: GolfCourse | undefined;
  tee_id: number | undefined;
  score: number | undefined;
}

export type SubmitScoreFormErrors = Partial<
  Record<keyof SubmitScoreFormModel, string>
>;

export async function submitTournamentRegistrationScore(
  registrationId: number,
  formModel: SubmitScoreFormModel,
  cancelToken?: CancelToken
): Promise<void> {
  await GolferApiClientAuto.request({
    method: "PATCH",
    url: `/golfer/tournament_registrations/${registrationId}`,
    data: {
      course_id: formModel.course?.id,
      tee_id: formModel.tee_id,
      score: formModel.score,
    },
    cancelToken: cancelToken,
  });
}

export function parseTournamentRegistrationScoreErrors(
  error: Error
): SubmitScoreFormErrors {
  const errors: SubmitScoreFormErrors = {};
  const apiErrors = (error as any).response?.data?.error?.validations ?? {};
  remapError(errors, "course", apiErrors.course_id);
  remapError(errors, "course", apiErrors.course);
  remapError(errors, "tee_id", apiErrors.tee_id);
  remapError(errors, "tee_id", apiErrors.tee);
  remapError(errors, "score", apiErrors.score);
  return errors;
}
