import React from "react";
import { Button, Collapse, Typography } from "@material-ui/core";

import {
  FormRow,
  FormRowInputLabel,
  FormRowOutlinedInput,
  FormRowImageInput,
  FormRowOutlinedURLInput,
} from "components/core/FormRow";
import { Alert } from "components/core/Alert";

interface AddSponsorFormProps {
  handleSubmit: (...args: any[]) => void;
  submitting: boolean;
  submitError: string | undefined;
  disabled: boolean;
}

export default function AddSponsorForm({
  handleSubmit,
  submitting,
  submitError,
  disabled,
}: AddSponsorFormProps) {
  return (
    <form
      onSubmit={handleSubmit}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      }}
    >
      <FormRow
        withLabel={
          <FormRowInputLabel htmlFor="sponsor-name">
            Sponsor Name
          </FormRowInputLabel>
        }
      >
        <FormRowOutlinedInput
          id="sponsor-name"
          name="name"
          placeholder="Company Name"
          disabled={disabled}
        />
      </FormRow>
      <FormRow
        withLabel={
          <FormRowInputLabel htmlFor="sponsor-url">
            Sponsor URL
          </FormRowInputLabel>
        }
      >
        <FormRowOutlinedURLInput
          id="sponsor-url"
          name="url"
          placeholder="https://example.com"
          disabled={disabled}
        />
      </FormRow>
      <FormRow
        withLabel={
          <FormRowInputLabel htmlFor="sponsor-logo">Logo</FormRowInputLabel>
        }
      >
        <FormRowImageInput
          id="sponsor-logo"
          name="logo"
          aspectRatio={1}
          helperText="For the best experience image should be squared, e.g. 200x200 or larger"
          disabled={disabled}
        />
      </FormRow>
      <Collapse in={Boolean(submitError)}>
        <FormRow>
          <Alert fullWidth>
            <Typography>{submitError}</Typography>
          </Alert>
        </FormRow>
      </Collapse>
      <FormRow>
        <Button type="submit" disabled={disabled || submitting}>
          Save
        </Button>
      </FormRow>
    </form>
  );
}
