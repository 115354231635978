import { CancelToken } from "axios";

import { OrganizerApiClientAuto } from "../../ApiClient";
import { TournamentStatus } from "api/types";

export async function publishTournament(
  tournamentId: number,
  cancelToken?: CancelToken
): Promise<void> {
  await OrganizerApiClientAuto.request<void>({
    method: "PATCH",
    url: `/organizer/organization/tournaments/${tournamentId}`,
    data: {
      tournament: {
        status: TournamentStatus.Published,
      },
    },
    cancelToken: cancelToken,
  });
}
