export function formatNetToPar(
  net_to_par: number | undefined,
  placeholder: string = ""
): string {
  if (net_to_par == null) {
    return placeholder;
  } else if (net_to_par >= 0) {
    return `+${net_to_par}`;
  } else {
    return String(net_to_par);
  }
}
