import { CancelToken } from "axios";

import { makeOrganizerApiClient } from "../../ApiClient";

export interface Organizer {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  phone?: string;
}

export async function getOrganizerByToken(
  token: string,
  cancelToken?: CancelToken
): Promise<{ user: Organizer; token: string }> {
  const response = await makeOrganizerApiClient(token).request<{
    organizer: Organizer;
  }>({
    method: "GET",
    url: "/organizer/user",
    cancelToken: cancelToken,
  });

  return { user: response.data.organizer, token };
}
