import { useState, useEffect } from "react";
import axios, { AxiosError, CancelToken } from "axios";

import { useOrganizerSession } from "utils/organizer-session";
import { getOrganization } from "./getOrganization";
import { Organization } from "./types";

async function getOrganizationEnhanced(
  token: string | undefined,
  cancelToken: CancelToken
): Promise<Organization | undefined> {
  if (!token) {
    return undefined;
  } else {
    return await getOrganization(token, cancelToken);
  }
}

export function useOrganization(): [Organization | undefined, boolean] {
  const { token } = useOrganizerSession();
  const [organization, setOrganization] = useState<Organization | undefined>(
    undefined
  );
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const cancelSource = axios.CancelToken.source();
    getOrganizationEnhanced(token, cancelSource.token)
      .then((org) => {
        setLoading(false);
        setOrganization(org);
      })
      .catch((error: AxiosError) => {
        if (!axios.isCancel(error)) {
          setLoading(false);
          setOrganization(undefined);
        }
      });
    return () => cancelSource.cancel("request cancelled");
  }, [token]);

  return [organization, isLoading];
}
