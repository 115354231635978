import { createMuiTheme } from "@material-ui/core/styles";

import { FlexGolfTheme } from "./FlexGolfTheme.type";
import { Palette } from "./Palette";
import { applyTypographyPatch } from "./applyTypographyPatch";

export function createAppTheme(): FlexGolfTheme {
  const theme: FlexGolfTheme = {
    ...createMuiTheme({
      palette: {
        ...Palette,
      },
      typography: {
        fontFamily: [
          "Montserrat",
          "Roboto",
          "Helvetica",
          "Arial",
          "sans-serif",
        ].join(","),
        fontSize: 16,
      },
      overrides: {
        MuiCssBaseline: {
          "@global": {
            body: {
              color: "#11243F",
            },
          },
        },
        MuiLink: {
          root: {
            display: "inline-block",
          },
          button: {
            verticalAlign: "unset",
          },
        },
        MuiButton: {
          contained: {
            fontSize: "1rem",
            fontWeight: 500,
            borderRadius: 6,
          },
          text: {
            fontSize: "1rem",
            fontWeight: 700,
            textTransform: "none",
          },
          outlined: {
            textTransform: "none",
            fontSize: "1rem",
            fontWeight: 700,
            borderWidth: 2,
            "&.Mui-disabled": {
              borderWidth: 2,
            },
          },
          outlinedPrimary: {
            color: Palette.primary.main,
            border: `2px solid ${Palette.primary.light}`,
            background: "transparent",
            "&:hover": {
              border: `2px solid ${Palette.primary.light}`,
            },
          },
          outlinedSecondary: {
            color: Palette.secondary.main,
            border: `2px solid ${Palette.secondary.light}`,
            background: "transparent",
            "&:hover": {
              border: `2px solid ${Palette.secondary.light}`,
            },
          },
          sizeSmall: {
            fontSize: "0.7rem",
            padding: "5px 10px",
          },
        },
        MuiLinearProgress: {
          root: {
            height: "14px",
            borderRadius: "4px",
          },
          colorPrimary: {
            backgroundColor: "#d8dee3",
          },
          barColorPrimary: {
            backgroundColor: "#28ad9f",
          },
        },
        MuiOutlinedInput: {
          input: {
            padding: "10px 12px",
          },
        },
        MuiFormLabel: {
          root: {
            color: "inherit",
            fontWeight: 500,
          },
        },
        MuiFormHelperText: {
          root: {
            marginTop: 0,
            lineHeight: 1.15,
          },
        },
        MuiTooltip: {
          tooltip: {
            fontSize: "0.85rem",
            fontWeight: "normal",
          },
        },
        MuiTableCell: {
          sizeSmall: {
            padding: "6px 10px 6px 10px",
          },
        },
      },
      props: {
        MuiButton: {
          variant: "contained",
          color: "secondary",
          disableElevation: true,
        },
      },
    }),
    darkBlock: {
      background: "#0b1f3d",
      text: "#EBF3FF",
    },
  };

  applyTypographyPatch(theme);

  return theme;
}
