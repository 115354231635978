import React, { useCallback } from "react";
import { NativeSelect, NativeSelectProps } from "@material-ui/core";

import { TournamentTeam } from "api/types";

interface TeamSelectProps extends Omit<NativeSelectProps, "onChange"> {
  value: TournamentTeam | undefined;
  onChange: (nextValue: TournamentTeam | undefined) => void;
  teams: TournamentTeam[];
}

export default function TeamSelect({
  value,
  onChange,
  teams,
  ...restProps
}: TeamSelectProps) {
  const handleChange = useCallback(
    (evt: React.ChangeEvent<HTMLSelectElement>) => {
      if (evt.target.value) {
        const team_id = parseInt(evt.target.value);
        onChange(teams.find((t) => t.id === team_id));
      } else {
        onChange(undefined);
      }
    },
    [onChange, teams]
  );

  return (
    <NativeSelect
      value={value ? value.id : ""}
      onChange={handleChange}
      {...restProps}
    >
      <option value="" disabled></option>
      {teams.map((team) => (
        <option key={team.id} value={team.id}>
          {team.name}
        </option>
      ))}
    </NativeSelect>
  );
}
