import React, { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";

import { EnsureGolferLoggedIn } from "utils/golfer-session";

import ScoreSubmissionSection from "./ScoreSubmissionSection";
import { useTournamentRegistrationById } from "./useTournamentRegistrationById";
import { ScoreSubmissionStage } from "./ScoreSubmissionSection.utils";

const ScoreSubmissionSectionContainer = () => {
  const { registrationId } = useParams<{ registrationId: string }>();
  const history = useHistory();

  const registration = useTournamentRegistrationById(parseInt(registrationId));

  const goToStep = useCallback(
    (step: ScoreSubmissionStage) => {
      history.push(`/golfer/registration/${registrationId}/${step}`);
    },
    [history, registrationId]
  );

  return (
    <ScoreSubmissionSection
      registrationId={parseInt(registrationId)}
      registration={registration}
      goToStep={goToStep}
    />
  );
};

export default EnsureGolferLoggedIn(ScoreSubmissionSectionContainer);
