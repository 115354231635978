import React, { useCallback } from "react";
import { Form } from "react-final-form";
import { useSnackbar } from "notistack";
import { FORM_ERROR } from "final-form";

import {
  addMissingCourse,
  parseMissingCourseErrors,
  MissingCourseModel,
} from "api/golfer/courses";

import RequestToAddCourseDialog from "./RequestToAddCourseDialog";
import { RequestToAddCourseDialogProps } from "./RequestToAddCourseDialogProps";
import { getApiErrorMessage } from "utils/network";

export default function RequestToAddCourseDialogContainer({
  isOpen,
  onClose,
  onExited,
}: RequestToAddCourseDialogProps) {
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback(
    async (formModel: MissingCourseModel) => {
      try {
        await addMissingCourse(formModel);
        enqueueSnackbar(
          "Your request was succesfully recorded and will be reviewed soon!",
          { variant: "success" }
        );
        onClose();
      } catch (err) {
        const errors = parseMissingCourseErrors(err);
        if (Object.keys(errors).length > 0) {
          return errors;
        } else {
          return { [FORM_ERROR]: getApiErrorMessage(err) };
        }
      }
    },
    [enqueueSnackbar, onClose]
  );

  return (
    <Form<MissingCourseModel>
      onSubmit={onSubmit}
      initialValues={{ name: "", tee_name: "", details: "" }}
    >
      {({ handleSubmit, submitting, submitError }) => (
        <RequestToAddCourseDialog
          isOpen={isOpen}
          onClose={onClose}
          onExited={onExited}
          handleSubmit={handleSubmit}
          submitting={submitting}
          submitError={submitError}
        />
      )}
    </Form>
  );
}
