import React from "react";
import { TableRow, TableCell, Box } from "@material-ui/core";

import { TextSkeleton } from "components/core/Skeleton";

import { TeamCellSkeleton } from "../TeamCell";
import { CourseCellSkeleton } from "../CourseCell";

export default function TableRowSkeleton() {
  return (
    <TableRow>
      <TableCell align="right">
        <Centered>
          <TextSkeleton variant="body1" width={20} />
        </Centered>
      </TableCell>
      <TableCell>
        <TeamCellSkeleton />
      </TableCell>
      <TableCell align="right">
        <Centered>
          <TextSkeleton variant="body1" width={20} />
        </Centered>
      </TableCell>
      <TableCell align="right">
        <Centered>
          <TextSkeleton variant="body1" width={20} />
        </Centered>
      </TableCell>
      <TableCell align="right">
        <Centered>
          <TextSkeleton variant="body1" width={20} />
        </Centered>
      </TableCell>
      <TableCell>
        <CourseCellSkeleton />
      </TableCell>
    </TableRow>
  );
}

function Centered({ children }: { children: React.ReactNode }) {
  return (
    <Box display="flex" flexDirection="row" justifyContent="center">
      {children}
    </Box>
  );
}
