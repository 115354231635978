import React from "react";
import { Field } from "react-final-form";
import { OutlinedInput, OutlinedInputProps } from "@material-ui/core";

import { FormRowWithHelperText } from "./FormRowWithHelperText";

interface FormRowInputProps extends OutlinedInputProps {
  name: string;
  helperText?: string;
}

function identity<T>(val: T): T {
  return val;
}

export function FormRowOutlinedInput({
  name,
  helperText,
  ...restProps
}: FormRowInputProps) {
  return (
    <Field name={name} parse={identity}>
      {({ input, meta }) => (
        <FormRowWithHelperText
          helperText={
            meta.touched
              ? meta.error || meta.submitError || helperText
              : helperText
          }
          isErrored={Boolean(meta.submitError || (meta.touched && meta.error))}
        >
          <OutlinedInput
            fullWidth={true}
            error={Boolean(meta.submitError || (meta.touched && meta.error))}
            {...restProps}
            {...input}
          />
        </FormRowWithHelperText>
      )}
    </Field>
  );
}
