import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Fade, Grid, Typography, Box, Button } from "@material-ui/core";

import { OrganizerPage } from "components/layout";
import { Organizer } from "api/organizer/account";
import { OrganizerTournament } from "api/organizer/tournaments";
import { InfoBox } from "components/core/InfoBox";
import { formatOrganizerHandle } from "utils/organizer";

import {
  TournamentRow,
  TournamentRowSkeleton,
} from "./components/TournamentRow";

interface OrganizerDashboardPageProps {
  organizer: Organizer | undefined;
  tournaments: OrganizerTournament[] | undefined;
  onTournamentUpdated(t: OrganizerTournament): void;
}

export default function OrganizerDashboardPage({
  organizer,
  tournaments,
  onTournamentUpdated,
}: OrganizerDashboardPageProps) {
  return (
    <OrganizerPage>
      <Grid container spacing={4} alignItems="flex-end">
        <Grid item xs={12}>
          <Typography variant="h3" component="h1">
            Welcome back {formatOrganizerHandle(organizer)}!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" component="h3">
            Your Fundraisers{" "}
            <Fade in={tournaments && tournaments.length > 0}>
              <Button
                variant="contained"
                component={RouterLink}
                to="/organizer/start-fundraiser"
                size="small"
              >
                Start a new Tournament
              </Button>
            </Fade>
          </Typography>
        </Grid>
        {!tournaments &&
          [1, 2, 3].map((key) => (
            <Grid item xs={12} key={key}>
              <TournamentRowSkeleton />
            </Grid>
          ))}
        {tournaments &&
          tournaments.map((tournament) => (
            <Grid item xs={12} key={tournament.id}>
              <TournamentRow
                tournament={tournament}
                onTournamentUpdated={onTournamentUpdated}
              />
            </Grid>
          ))}
        {tournaments && tournaments.length === 0 && (
          <Grid item xs={12}>
            <InfoBox>
              <Box
                py={5}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Typography variant="h5" align="center" color="textSecondary">
                  You'll see your tournaments here
                </Typography>
                <Box mt={1}>
                  <Button
                    size="small"
                    component={RouterLink}
                    to="/organizer/start-fundraiser"
                  >
                    Start a new Tournament
                  </Button>
                </Box>
              </Box>
            </InfoBox>
          </Grid>
        )}
      </Grid>
    </OrganizerPage>
  );
}
