import React, { useCallback } from "react";
import { FORM_ERROR } from "final-form";
import { Form } from "react-final-form";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import { EnsureGolferLoggedIn, useGolferSession } from "utils/golfer-session";
import {
  updateGolferAccount,
  getGolferAccount,
  UpdateGolferAccountModel,
  parseUpdateGolferAccountErrors,
} from "api/golfer/account";
import { getApiErrorMessage } from "utils/network";

import UpdateGolferAccountPage from "./UpdateGolferAccountPage";
import { makeInitialFormModel } from "./UpdateGolferAccountPage.utils";

function UpdateGolferAccountPageContainer() {
  const { user, updateUser } = useGolferSession();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback(
    async (formModel: UpdateGolferAccountModel): Promise<any> => {
      try {
        await updateGolferAccount(formModel);
        const updatedUser = await getGolferAccount();
        updateUser(updatedUser);
        enqueueSnackbar("Your account details were succesfully updated", {
          variant: "success",
        });
        history.push("/golfer/dashboard");
      } catch (err) {
        const errors = parseUpdateGolferAccountErrors(err);
        if (Object.keys(errors).length > 0) {
          return errors;
        } else {
          return { [FORM_ERROR]: getApiErrorMessage(err) };
        }
      }
    },
    [updateUser, history, enqueueSnackbar]
  );

  return (
    <Form<UpdateGolferAccountModel>
      onSubmit={onSubmit}
      initialValues={makeInitialFormModel(user)}
    >
      {({ handleSubmit, submitting, submitError }) => (
        <UpdateGolferAccountPage
          user={user}
          handleSubmit={handleSubmit}
          submitting={submitting}
          submitError={submitError}
        />
      )}
    </Form>
  );
}

export default EnsureGolferLoggedIn(UpdateGolferAccountPageContainer);
