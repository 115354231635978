import { CancelToken } from "axios";

import { BaseApiClient } from "../../ApiClient";
import { GuestTournament } from "./getTournamentsBySlug";

export async function getFeaturedTournaments(
  cancelToken?: CancelToken
): Promise<GuestTournament[]> {
  const response = await BaseApiClient.request<{
    tournaments: GuestTournament[];
  }>({
    method: "GET",
    url: "/guest/tournaments",
    cancelToken: cancelToken,
  });

  return response.data.tournaments;
}
