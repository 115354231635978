import { CancelToken } from "axios";

import { GolferApiClientAuto } from "../../ApiClient";

export async function updateTournamentRegistrationPaymentMethod(
  registrationId: number,
  stripe_token: string,
  cancelToken?: CancelToken
): Promise<void> {
  await GolferApiClientAuto.request({
    method: "PATCH",
    url: `/golfer/tournament_registrations/${registrationId}`,
    data: {
      tournament_registration: {
        stripe_token,
      },
    },
    cancelToken: cancelToken,
  });
}
