import { CancelToken } from "axios";

import { OrganizerApiClientAuto } from "../../ApiClient";
import {
  SponsorFormModel,
  convertCreateSponsorModelToFormData,
} from "./SponsorFormModel";

export async function createTournamentSponsor(
  tournamentId: number,
  formModel: SponsorFormModel,
  cancelToken?: CancelToken
): Promise<void> {
  await OrganizerApiClientAuto.request({
    method: "POST",
    url: `/organizer/organization/tournaments/${tournamentId}/sponsors`,
    data: convertCreateSponsorModelToFormData(formModel),
    cancelToken: cancelToken,
  });
}
