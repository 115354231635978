import { remapError, remapNestedErrors } from "utils/network/validations";
import { isLocalImageValue } from "components/core/ImageInput";

import { appendField } from "../../utils";
import { AddressAttributesErrors } from "../../types";
import { OrganizationFormModel, OrganizationErrors } from "./types";

export function convertOrganizationFormModelToFormData(
  formModel: OrganizationFormModel
): FormData {
  const fd = new FormData();
  appendField(fd, "organization[name]", formModel.name);
  appendField(fd, "organization[email]", formModel.email);
  appendField(fd, "organization[url]", formModel.url);
  appendField(
    fd,
    "organization[tin_ssn_ciphertext]",
    formModel.tin_ssn_ciphertext
  );
  if (formModel.logo && isLocalImageValue(formModel.logo)) {
    appendField(fd, "organization[logo]", formModel.logo.file);
  } else if (!formModel.logo) {
    appendField(fd, "organization[logo]", "");
  }
  appendField(fd, "organization[facebook_url]", formModel.facebook_url);
  appendField(fd, "organization[twitter_url]", formModel.twitter_url);
  appendField(fd, "organization[instagram_url]", formModel.instagram_url);
  appendField(
    fd,
    "organization[address_attributes][id]",
    formModel.address_attributes.id
  );
  appendField(
    fd,
    "organization[address_attributes][country_id]",
    formModel.address_attributes.country_id
  );
  appendField(
    fd,
    "organization[address_attributes][state_id]",
    formModel.address_attributes.state_id
  );
  appendField(
    fd,
    "organization[address_attributes][street_1]",
    formModel.address_attributes.street_1
  );
  appendField(
    fd,
    "organization[address_attributes][street_2]",
    formModel.address_attributes.street_2
  );
  appendField(
    fd,
    "organization[address_attributes][city]",
    formModel.address_attributes.city
  );
  appendField(
    fd,
    "organization[address_attributes][zip]",
    formModel.address_attributes.zip
  );
  return fd;
}

export function parseOrganizationErrors(error: Error): OrganizationErrors {
  const errors: OrganizationErrors = {};
  const apiErrors = (error as any).response?.data?.error?.validations ?? {};
  remapError(errors, "name", apiErrors.name);
  remapError(errors, "email", apiErrors.email);
  remapError(errors, "url", apiErrors.url);
  remapError(errors, "tin_ssn_ciphertext", apiErrors.tin_ssn_ciphertext);
  remapError(errors, "logo", apiErrors.logo);
  remapNestedErrors(
    errors,
    "address_attributes",
    (address_attributes_errors: AddressAttributesErrors) => {
      remapError(
        address_attributes_errors,
        "state_id",
        apiErrors.address?.state_id
      );
      remapError(
        address_attributes_errors,
        "state_id",
        apiErrors.address?.state
      );
      remapError(
        address_attributes_errors,
        "street_1",
        apiErrors.address?.street_1
      );
      remapError(
        address_attributes_errors,
        "street_2",
        apiErrors.address?.street_2
      );
      remapError(address_attributes_errors, "city", apiErrors.address?.city);
      remapError(address_attributes_errors, "zip", apiErrors.address?.zip);
    }
  );
  return errors;
}
