import { CancelToken } from "axios";

import { OrganizerApiClientAuto } from "../../ApiClient";

export async function deleteTournamentSponsor(
  tournamentId: number,
  sponsorId: number,
  cancelToken?: CancelToken
): Promise<void> {
  await OrganizerApiClientAuto.request({
    method: "DELETE",
    url: `/organizer/organization/tournaments/${tournamentId}/sponsors/${sponsorId}`,
    cancelToken: cancelToken,
  });
}
