import { CancelToken } from "axios";

import { GolferApiClientAuto } from "api/ApiClient";
import { GolfCourseTee } from "api/types";

export async function getCourseTees(
  courseId: number,
  cancelToken?: CancelToken
): Promise<GolfCourseTee[]> {
  const response = await GolferApiClientAuto.request<{
    tees: GolfCourseTee[];
  }>({
    method: "GET",
    url: `/golfer/courses/${courseId}/tees`,
    cancelToken: cancelToken,
  });

  return response.data.tees;
}
