import memoizeOne from "memoize-one";
import { getUnixTime, parseISO } from "date-fns";

import { Tournament, TournamentStatus } from "api/types";
import { isTournamentStarted, isTournamentInProgress } from "utils/tournament";

function getTournamentStatusWeight(tournament: Tournament): number {
  if (!isTournamentStarted(tournament)) {
    return 2;
  } else if (isTournamentInProgress(tournament)) {
    return 1;
  } else if (tournament.status === TournamentStatus.Draft) {
    return 3;
  } else {
    return 4;
  }
}

export const sortTournaments = memoizeOne(function sortTournaments<
  T extends Tournament
>(tournaments: T[]): T[] {
  return [...tournaments].sort((t1, t2) => {
    const w1 = getTournamentStatusWeight(t1);
    const w2 = getTournamentStatusWeight(t2);
    if (w1 === w2) {
      return (
        getUnixTime(parseISO(t1.start_date)) -
        getUnixTime(parseISO(t2.start_date))
      );
    } else {
      return w1 - w2;
    }
  });
});
