import React from "react";
import { Redirect } from "react-router-dom";

import { SessionServiceStatus } from "utils/session";
import { getComponentWrappedName } from "utils/getComponentWrappedName";
import { GolferPostLoginLocationContainer } from "config/globals";

import { useGolferSession } from "./GolferSessionProvider";

export function EnsureGolferNotLoggedIn<P>(
  Component: React.ComponentType<P>,
  redirectTo: string = "/golfer/dashboard"
) {
  function EnsureGolferNotLoggedInWrapper(props: P) {
    const { status } = useGolferSession();

    if (status === SessionServiceStatus.Authenticated) {
      return (
        <Redirect
          to={GolferPostLoginLocationContainer.currentValue || redirectTo}
        />
      );
    } else {
      return <Component {...props} />;
    }
  }

  EnsureGolferNotLoggedInWrapper.displayName = getComponentWrappedName(
    "EnsureGolferNotLoggedIn",
    Component
  );

  return EnsureGolferNotLoggedInWrapper;
}
