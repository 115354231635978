import React, { useEffect } from "react";
import { useHistory, useParams, Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Box,
  Button,
  makeStyles,
  Link,
  Table,
  TableHead,
  TableBody,
  Typography,
} from "@material-ui/core";

import { SmartPage } from "components/layout";
import {
  useTournamentBySlug,
  useGuestTournamentRegistrations,
} from "api/guest/tournaments/hooks";
import { TextSkeleton } from "components/core/Skeleton";
import {
  isTournamentStarted,
  isTournamentRegistrationAvailable,
} from "utils/tournament";
import {
  isIndividualFinisher,
  isTeamFinisher,
  groupTournamentFinishers,
  sortTournamentFinishers,
} from "utils/tournament/leaderboard";
import { PageTitle, PageDescription } from "components/core/Helmets";

import {
  TableRowHead,
  TableRowTeam,
  TableRowIndividual,
  TableRowSkeleton,
  TableRowNoResults,
} from "./components/LeaderboardTable";

export default function TournamentLeaderboardPage() {
  const classes = useStyles();

  const history = useHistory();
  const { slug } = useParams<{ slug: string }>();
  const { tournament } = useTournamentBySlug(slug);

  useEffect(() => {
    if (tournament && !isTournamentStarted(tournament)) {
      history.replace(`/tournament/${tournament.slug}/entry-list`);
    }
  }, [history, tournament]);

  const { registrations, isInitialized } = useGuestTournamentRegistrations(
    slug
  );

  const finishers = sortTournamentFinishers(
    groupTournamentFinishers(registrations)
  );

  return (
    <SmartPage>
      {tournament && (
        <>
          <PageTitle title={`${tournament.name} - Leaderboard`} />
          <PageDescription description={tournament.details.slice(0, 160)} />
          <Helmet>
            <meta property="og:image" content={tournament.image?.large} />
          </Helmet>
        </>
      )}
      <Box className={classes.tournamentName}>
        {tournament ? (
          <Typography variant="h2" component="h1">
            <Link
              color="inherit"
              underline="none"
              component={RouterLink}
              to={`/tournament/${tournament.slug}`}
            >
              {tournament.name}
            </Link>{" "}
            &mdash; Leaderboard
          </Typography>
        ) : (
          <TextSkeleton variant="h2" width="65%" />
        )}
      </Box>
      <Box my={3}>
        <Table size="small" aria-label="leaderboard">
          <TableHead>
            <TableRowHead />
          </TableHead>
          <TableBody>
            {finishers.map((finisher, index) => {
              if (isTeamFinisher(finisher)) {
                return (
                  <TableRowTeam
                    key={finisher.id}
                    place={index + 1}
                    finisher={finisher}
                  />
                );
              } else if (isIndividualFinisher(finisher)) {
                return (
                  <TableRowIndividual
                    key={finisher.id}
                    place={index + 1}
                    finisher={finisher}
                  />
                );
              } else {
                // NOTE: this should never happen, added for type-safety
                return null;
              }
            })}
            {!isInitialized &&
              finishers.length === 0 &&
              [1, 2, 3, 4, 5].map((i) => <TableRowSkeleton key={i} />)}
            {isInitialized && finishers.length === 0 && <TableRowNoResults />}
          </TableBody>
        </Table>
      </Box>
      {isTournamentRegistrationAvailable(tournament) && (
        <Box my={4}>
          <Button
            component={RouterLink}
            to={`/tournament/${tournament?.slug}/register`}
          >
            Register
          </Button>
        </Box>
      )}
    </SmartPage>
  );
}

const useStyles = makeStyles((theme) => ({
  tournamentName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
      textAlign: "left",
    },
  },
}));
