import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Switch, Route, Redirect } from "react-router-dom";

import { GolferPage } from "components/layout";
import { TournamentRegistration } from "api/golfer/tournament-registrations";
import { TextSkeleton } from "components/core/Skeleton";

import { SubmitScore } from "./stages/SubmitScore";
import { UploadPicture } from "./stages/UploadPicture";
import { ThankYou } from "./stages/ThankYou";
import { ScoreSubmissionStage } from "./ScoreSubmissionSection.utils";

interface ScoreSubmissionSectionProps {
  registrationId: number;
  registration: TournamentRegistration | undefined;
  goToStep: (step: ScoreSubmissionStage) => void;
}

export default function ScoreSubmissionSection({
  registrationId,
  registration,
  goToStep,
}: ScoreSubmissionSectionProps) {
  return (
    <GolferPage>
      <Box my={4}>
        {registration ? (
          <Typography variant="h3" component="h1">
            {registration.tournament.name}
          </Typography>
        ) : (
          <TextSkeleton variant="h3" width="50%" />
        )}
      </Box>
      {registration && (
        <Switch>
          <Route
            exact
            path={`/golfer/registration/:registrationId/${ScoreSubmissionStage.SubmitScore}`}
            render={() => (
              <SubmitScore
                registration={registration}
                onNextStep={() => goToStep(ScoreSubmissionStage.UploadPicture)}
              />
            )}
          />
          <Route
            exact
            path={`/golfer/registration/:registrationId/${ScoreSubmissionStage.UploadPicture}`}
            render={() => (
              <UploadPicture
                registration={registration}
                onNextStep={() => goToStep(ScoreSubmissionStage.ThankYou)}
              />
            )}
          />
          <Route
            exact
            path={`/golfer/registration/:registrationId/${ScoreSubmissionStage.ThankYou}`}
            component={ThankYou}
          />
          <Route
            render={() => (
              <Redirect
                to={`/golfer/registration/${registrationId}/${ScoreSubmissionStage.SubmitScore}`}
              />
            )}
          />
        </Switch>
      )}
    </GolferPage>
  );
}
