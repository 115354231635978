import React, { useState, useCallback } from "react";
import { IconButton, IconButtonProps } from "@material-ui/core";
import CopyToClipboard from "react-copy-to-clipboard";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";

interface CopyToClipboardIconProps extends IconButtonProps {
  textToCopy: string;
  copyIcon?: React.ReactElement;
  justCopiedIcon?: React.ReactElement;
}

export default function CopyToClipboardIcon({
  textToCopy,
  copyIcon = <AssignmentIcon />,
  justCopiedIcon = <AssignmentTurnedInIcon style={{ color: "green" }} />,
  ...restProps
}: CopyToClipboardIconProps) {
  const [justCopied, setJustCopied] = useState(false);

  const onCopy = useCallback(() => {
    setJustCopied(true);
    setTimeout(() => setJustCopied(false), 700);
  }, []);

  return (
    <CopyToClipboard text={textToCopy} onCopy={onCopy}>
      <IconButton disabled={justCopied} {...restProps}>
        {justCopied
          ? React.cloneElement(justCopiedIcon, {
              style: {
                ...(justCopiedIcon.props.style ?? {}),
                fontSize: "inherit",
              },
            })
          : React.cloneElement(copyIcon, {
              style: { ...(copyIcon.props.style ?? {}), fontSize: "inherit" },
            })}
      </IconButton>
    </CopyToClipboard>
  );
}
