import React from "react";
import {
  Box,
  Button,
  InputLabel,
  Typography,
  Grid,
  Fade,
  OutlinedInput,
  Link,
} from "@material-ui/core";
import { Field, useField } from "react-final-form";
import { CancelToken } from "axios";

import { AppConfig } from "config/AppConfig";
import { Alert } from "components/core/Alert";
import { CourseSelectLab } from "components/core/CourseSelectLab";
import { GolfCourse } from "api/types";
import { TeeIdSelect } from "components/core/TeeIdSelect";
import { getCourseTees } from "api/golfer/courses";
import { FormRowWithHelperText } from "components/core/FormRow";

interface SubmitScoreProps {
  submitting: boolean;
  submitError: string | undefined;
  handleSubmit(...args: any[]): void;
  searchCourses(
    inputValue: string,
    cancelToken: CancelToken
  ): Promise<GolfCourse[]>;
  onAddOwnCourse(): void;
}

const TEE_HELPER_TEXT =
  "Choose the tee played on that has the lowest course rating (that your team played on)";

export default function SubmitScore({
  handleSubmit,
  submitting,
  submitError,
  searchCourses,
  onAddOwnCourse,
}: SubmitScoreProps) {
  const {
    input: { value: course },
  } = useField<GolfCourse | undefined>("course");

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h5">
            Congratulations on completing your round!
          </Typography>
          <Typography variant="h5">
            Grab your scorecard and lets enter some details!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Field name="tee_id">
            {({ input: { onChange: changeTeeId } }) => (
              <Field format={(val) => val} name="course">
                {({ input: { checked, type, multiple, ...input }, meta }) => (
                  <FormRowWithHelperText
                    helperText={
                      meta.touched ? meta.error || meta.submitError : null
                    }
                    isErrored={Boolean(
                      meta.touched && (meta.error || meta.submitError)
                    )}
                  >
                    <InputLabel htmlFor="registration-course">
                      Where did you play your round?{" "}
                      <Link
                        variant="body2"
                        component="button"
                        type="button"
                        onClick={onAddOwnCourse}
                      >
                        (Can't find your course?)
                      </Link>
                    </InputLabel>
                    <Box mt={1} maxWidth={350}>
                      <CourseSelectLab
                        id="registration-course"
                        searchCourses={searchCourses}
                        placeholder="Select Golf Course"
                        {...input}
                        onChange={(evt: any) => {
                          input.onChange(evt);
                          changeTeeId(undefined);
                        }}
                      />
                    </Box>
                  </FormRowWithHelperText>
                )}
              </Field>
            )}
          </Field>
        </Grid>
        <Grid item xs={12}>
          <Field<number | undefined> name="tee_id">
            {({ input, meta }) => (
              <FormRowWithHelperText
                helperText={
                  meta.touched
                    ? meta.error || meta.submitError || TEE_HELPER_TEXT
                    : TEE_HELPER_TEXT
                }
                isErrored={Boolean(
                  meta.touched && (meta.error || meta.submitError)
                )}
              >
                <InputLabel htmlFor="registration-tee-id">
                  Select a Tee
                </InputLabel>
                <Box mt={1} maxWidth={350}>
                  <TeeIdSelect
                    fullWidth
                    input={<OutlinedInput />}
                    id="registration-tee-id"
                    courseId={course?.id}
                    getCourseTees={getCourseTees}
                    {...input}
                  />
                </Box>
              </FormRowWithHelperText>
            )}
          </Field>
        </Grid>
        <Grid item xs={12}>
          <Field name="score">
            {({ input, meta }) => (
              <FormRowWithHelperText
                helperText={
                  meta.touched ? meta.error || meta.submitError : null
                }
                isErrored={Boolean(
                  meta.touched && (meta.error || meta.submitError)
                )}
              >
                <InputLabel htmlFor="registration-score">
                  What was your score for the round? (in total strokes)
                </InputLabel>
                <Box mt={1} maxWidth={500} position="relative">
                  <OutlinedInput
                    id="registration-score"
                    type="text"
                    placeholder="72"
                    style={{ width: 200 }}
                    {...input}
                  />
                </Box>
              </FormRowWithHelperText>
            )}
          </Field>
        </Grid>
        <Grid item xs={12}>
          <Fade in={Boolean(submitError)}>
            <Alert>
              <Typography variant="body1">{submitError}</Typography>
            </Alert>
          </Fade>
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" disabled={submitting}>
            Next
          </Button>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Typography variant="body2" style={{ fontStyle: "italic" }}>
            Remember: <br />
            {AppConfig.Application.Name} are for charity and fun!
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
}
