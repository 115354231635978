import React from "react";

import { useGolferSession } from "utils/golfer-session";

import { Page } from "../Page";
import { PageContent, PageContentProps } from "../PageContent";
import { PageFooter } from "../PageFooter";
import GolferPageHeader from "./GolferPageHeader";

interface GolferPageProps {
  children: React.ReactNode;
  contentProps?: Omit<PageContentProps, "children">;
}

export default function GolferPage({
  children,
  contentProps = {},
}: GolferPageProps) {
  const { status, user, logOut } = useGolferSession();

  return (
    <Page>
      <GolferPageHeader status={status} user={user} onLogout={logOut} />
      <PageContent my={6} alignSelf="center" flexGrow={1} {...contentProps}>
        {children}
      </PageContent>
      <PageFooter />
    </Page>
  );
}
