import memoizeOne from "memoize-one";

import { GuestTournament } from "api/guest/tournaments";
import { TournamentStatus } from "api/types";

import { isTournamentEnded } from "./isTournamentEnded";

export const isTournamentRegistrationAvailable = memoizeOne(
  function isTournamentRegistrationAvailable(
    tournament: GuestTournament | undefined
  ): boolean {
    return Boolean(
      tournament &&
        tournament.status === TournamentStatus.Published &&
        !isTournamentEnded(tournament)
    );
  }
);
