import React, { useEffect, useMemo } from "react";
import {
  Box,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Collapse,
  Typography,
  Checkbox,
  OutlinedInput,
  FormHelperText,
  NativeSelect,
  InputAdornment,
  Tooltip,
  InputLabel,
} from "@material-ui/core";
import { Field, useField } from "react-final-form";
import currency from "currency.js";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

import { GolferPage } from "components/layout";
import {
  BasicFormRow,
  FormRow,
  FormRowInputLabel,
  FormRowOutlinedInput,
  FormRowWithHelperText,
} from "components/core/FormRow";
import { GuestTournament } from "api/guest/tournaments";
import { Alert } from "components/core/Alert";
import { TournamentRegistrationMode } from "api/golfer/tournament-registrations";
import { TournamentTeam, TournamentFormat, TShirtSize } from "api/types";
import { TeamSelect } from "components/core/TeamIdSelect";
import { StripeFieldWrapper } from "components/core/StripeFieldWrapper";
import { InfoBox } from "components/core/InfoBox";
import { formatPercents } from "utils/formatPercentage";
import { TShirtSizeNames } from "utils/TShirtSizeNames";

import { JoinTournamentHeader } from "../../components/JoinTournamentHeader";
import {
  computeRegistrationCost,
  RegistrationCostBox,
} from "./components/RegistrationCostBox";
import { GolferTermsOfService } from "./components/GolferTermsOfService";
import { usePromoCodeInfo } from "./usePromoCodeInfo";
import {
  getNumberOfAdditionalSlots,
  POSSIBLE_NUMBER_OF_PRE_PAID_SLOTS,
} from "./JoinTournamentPage.utils";

interface JoinTournamentPageProps {
  tournament: GuestTournament | undefined;
  teams: TournamentTeam[];
  handleSubmit: (...args: any[]) => void;
  submitting: boolean;
  submitError: string | undefined;
}

const StripeElementOptions = { style: { base: { fontSize: "16px" } } };

export default function JoinTournamentPage({
  tournament,
  teams,
  handleSubmit,
  submitting,
  submitError,
}: JoinTournamentPageProps) {
  const mode = useField<TournamentRegistrationMode>("mode").input.value;
  const {
    input: { value: withMulligan, onChange: change_with_mulligan },
  } = useField<boolean>("mulligan");
  const promoCode = useField<string>("promo_code").input.value;
  const with_pre_paid_slots = useField<boolean>("new_team.with_pre_paid_slots")
    .input.value;
  const number_of_pre_paid_slots = useField("new_team.number_of_pre_paid_slots")
    .input.value;
  const {
    input: {
      value: require_stripe_token,
      onChange: change_require_stripe_token,
    },
  } = useField("require_stripe_token");
  const team = useField<TournamentTeam | undefined>("join_team.team").input
    .value;
  const {
    promoCodeInfo,
    error: promoCodeError,
    isLoading: isLoadingPromoCodeInfo,
  } = usePromoCodeInfo(promoCode);

  const registration_cost = computeRegistrationCost(
    tournament,
    withMulligan,
    promoCodeInfo,
    getNumberOfAdditionalSlots(
      mode,
      with_pre_paid_slots,
      number_of_pre_paid_slots
    )
  );

  const pre_paid_slot_info:
    | { with_mulligan: boolean }
    | undefined = useMemo(() => {
    if (
      mode === TournamentRegistrationMode.JoinTeam &&
      team &&
      team.paid_slots_left > 0
    ) {
      return {
        with_mulligan: team.paid_slots_with_mulligan,
      };
    }
  }, [mode, team]);

  useEffect(() => {
    if (pre_paid_slot_info) {
      change_with_mulligan(pre_paid_slot_info.with_mulligan);
      change_require_stripe_token(false);
    } else if (registration_cost.total.intValue === 0) {
      change_require_stripe_token(false);
    } else {
      change_require_stripe_token(true);
    }
  }, [
    registration_cost,
    pre_paid_slot_info,
    change_require_stripe_token,
    change_with_mulligan,
  ]);

  return (
    <GolferPage>
      <JoinTournamentHeader tournament={tournament} progress={75} />
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="stretch"
          mb={3}
          style={{
            display:
              tournament && tournament.form === TournamentFormat.Individual
                ? "none"
                : "block",
          }}
        >
          <Box py={2}>
            <Typography variant="h4" component="h2">
              Step 2: Create / Join a Team
            </Typography>
          </Box>
          <BasicFormRow>
            <Field<TournamentRegistrationMode> name="mode">
              {({ input }) => (
                <RadioGroup aria-label="join variant" {...input}>
                  <FormControlLabel
                    value={TournamentRegistrationMode.CreateTeam}
                    control={<Radio />}
                    label="Create a New Team"
                  />
                  <FormControlLabel
                    value={TournamentRegistrationMode.JoinTeam}
                    control={<Radio />}
                    label="Join an Existing Team"
                  />
                  <FormControlLabel
                    value={TournamentRegistrationMode.Individual}
                    control={<Radio />}
                    label="Play as an Individual"
                  />
                </RadioGroup>
              )}
            </Field>
          </BasicFormRow>
          <Collapse in={mode === TournamentRegistrationMode.CreateTeam}>
            <FormRow
              withLabel={
                <FormRowInputLabel htmlFor="new-team-name">
                  Name of Team
                </FormRowInputLabel>
              }
            >
              <FormRowOutlinedInput
                id="new-team-name"
                name="new_team.name"
                placeholder="Tom Loves Golf"
              />
            </FormRow>
            <FormRow
              withLabel={
                <FormRowInputLabel htmlFor="new-team-code">
                  Team Code
                </FormRowInputLabel>
              }
            >
              <FormRowOutlinedInput
                id="new-team-code"
                name="new_team.code"
                type="text"
              />
            </FormRow>
            <BasicFormRow>
              <Typography style={{ fontStyle: "italic" }}>
                Teams can have a maximum of up to four golfers
              </Typography>
            </BasicFormRow>
            <FormRow>
              <Box display="flex" flexDirection="column">
                <Field<boolean>
                  type="checkbox"
                  name="new_team.with_pre_paid_slots"
                >
                  {({ input }) => (
                    <FormControlLabel
                      control={<Checkbox {...input} color="primary" />}
                      label="Would you like to buy some additional slots for your teammates?"
                      style={{ marginRight: 50 }}
                    />
                  )}
                </Field>
                <FormHelperText>
                  By default, every member of the team has to pay for
                  registration on their own. You can purchase up to three
                  additional pre-paid slots to fill your team.
                  <br />
                  <br />
                  When your teammates register with their team code, they will
                  not be charged, and added to your team automatically.
                </FormHelperText>
              </Box>
            </FormRow>
            <Collapse in={with_pre_paid_slots}>
              <FormRow
                withLabel={
                  <FormRowInputLabel htmlFor="new-team-pre-paid-slots">
                    Additional Pre-Paid Slots
                  </FormRowInputLabel>
                }
              >
                <Field<string>
                  type="radio"
                  name="new_team.number_of_pre_paid_slots"
                >
                  {({ input }) => (
                    <RadioGroup row aria-label="join variant" {...input}>
                      {POSSIBLE_NUMBER_OF_PRE_PAID_SLOTS.map((n) => (
                        <FormControlLabel
                          value={n}
                          control={<Radio />}
                          label={n}
                        />
                      ))}
                    </RadioGroup>
                  )}
                </Field>
              </FormRow>
            </Collapse>
          </Collapse>
          <Collapse in={mode === TournamentRegistrationMode.JoinTeam}>
            <FormRow
              withLabel={
                <FormRowInputLabel htmlFor="join-team-team">
                  Team
                </FormRowInputLabel>
              }
            >
              <Field<TournamentTeam | undefined> name="join_team.team">
                {({ input, meta }) => (
                  <FormRowWithHelperText
                    helperText={
                      meta.touched ? meta.error || meta.submitError : null
                    }
                    isErrored={Boolean(
                      meta.touched && (meta.error || meta.submitError)
                    )}
                  >
                    <TeamSelect
                      fullWidth
                      input={
                        <OutlinedInput
                          error={Boolean(
                            meta.submitError || (meta.touched && meta.error)
                          )}
                        />
                      }
                      id="join-team-team"
                      teams={teams}
                      {...input}
                    />
                  </FormRowWithHelperText>
                )}
              </Field>
            </FormRow>
            <FormRow
              withLabel={
                <FormRowInputLabel htmlFor="join-team-code">
                  Team Code
                </FormRowInputLabel>
              }
            >
              <FormRowOutlinedInput
                id="join-team-code"
                name="join_team.code"
                type="text"
              />
            </FormRow>
          </Collapse>
        </Box>
        <Box py={2}>
          <Typography variant="h4" component="h2">
            Step 3: Registration{pre_paid_slot_info ? "" : " & Payment Details"}
          </Typography>
        </Box>
        <Collapse in={require_stripe_token}>
          <FormRow
            withLabel={<FormRowInputLabel>Credit Card #</FormRowInputLabel>}
          >
            <StripeFieldWrapper>
              <CardNumberElement options={StripeElementOptions} />
            </StripeFieldWrapper>
          </FormRow>
          <FormRow
            withLabel={<FormRowInputLabel>Expiration</FormRowInputLabel>}
          >
            <StripeFieldWrapper width={200}>
              <CardExpiryElement options={StripeElementOptions} />
            </StripeFieldWrapper>
          </FormRow>
          <FormRow withLabel={<FormRowInputLabel>CVV / CVC</FormRowInputLabel>}>
            <StripeFieldWrapper width={200}>
              <CardCvcElement options={StripeElementOptions} />
            </StripeFieldWrapper>
          </FormRow>
          <Field name="stripe_token">
            {({ meta }) => (
              <Collapse in={Boolean(meta.submitError)}>
                <BasicFormRow>
                  <Alert fullWidth>
                    <Typography variant="body1">{meta.submitError}</Typography>
                  </Alert>
                </BasicFormRow>
              </Collapse>
            )}
          </Field>
        </Collapse>
        <Collapse in={!Boolean(pre_paid_slot_info)}>
          <FormRow
            withLabel={<FormRowInputLabel>Promo Code</FormRowInputLabel>}
          >
            <Field name="promo_code" parse={(code) => code}>
              {({ input, meta }) => (
                <FormRowWithHelperText
                  helperText={
                    meta.touched
                      ? meta.error ||
                        meta.submitError ||
                        promoCodeError?.message
                      : promoCodeError?.message
                  }
                  isErrored={Boolean(
                    meta.submitError ||
                      (meta.touched && meta.error) ||
                      promoCodeError
                  )}
                >
                  <OutlinedInput
                    fullWidth={true}
                    error={Boolean(
                      meta.submitError ||
                        (meta.touched && meta.error) ||
                        promoCodeError
                    )}
                    id="promo-code"
                    style={{ width: 200 }}
                    endAdornment={
                      <InputAdornment position="end">
                        {(() => {
                          switch (true) {
                            case Boolean(promoCodeInfo):
                              return (
                                <CheckCircleIcon
                                  style={{ fontSize: "1.2rem", color: "green" }}
                                />
                              );
                            case Boolean(promoCodeError):
                              return (
                                <Tooltip title={promoCodeError?.message ?? ""}>
                                  <CancelIcon
                                    style={{
                                      fontSize: "1.2rem",
                                      color: "#f44336",
                                    }}
                                  />
                                </Tooltip>
                              );
                            default:
                              return null; // <RemoveCircleIcon />;
                          }
                        })()}
                      </InputAdornment>
                    }
                    {...input}
                  />
                </FormRowWithHelperText>
              )}
            </Field>
          </FormRow>
        </Collapse>
        <Collapse in={tournament && tournament.offering_tshirt}>
          <FormRow
            withLabel={
              <FormRowInputLabel>Choose Your T-Shirt Size</FormRowInputLabel>
            }
          >
            <Field<TShirtSize> name="tshirt_size">
              {({ input, meta }) => (
                <FormRowWithHelperText
                  helperText={
                    meta.touched ? meta.error || meta.submitError : null
                  }
                  isErrored={Boolean(
                    meta.touched && (meta.error || meta.submitError)
                  )}
                >
                  <NativeSelect
                    fullWidth
                    style={{ width: 200 }}
                    input={
                      <OutlinedInput
                        error={Boolean(
                          meta.submitError || (meta.touched && meta.error)
                        )}
                      />
                    }
                    {...input}
                  >
                    <option disabled></option>
                    {Object.entries(TShirtSizeNames).map(([value, label]) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </NativeSelect>
                </FormRowWithHelperText>
              )}
            </Field>
          </FormRow>
        </Collapse>
        <Collapse
          in={tournament && tournament.mulligan && !Boolean(pre_paid_slot_info)}
        >
          <BasicFormRow>
            <Field<boolean> type="checkbox" name="mulligan">
              {({ input }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...input}
                      color="primary"
                      disabled={Boolean(pre_paid_slot_info)}
                    />
                  }
                  label={`Would you like to support ${
                    tournament?.name
                  } by purchasing a mulligan for ${currency(
                    tournament?.mulligan_price ?? ""
                  ).format()}?`}
                  style={{ marginRight: 50 }}
                />
              )}
            </Field>
            <FormHelperText>
              You get 1 mulligan on the Front 9, and 1 on the Back 9. If each
              member of your team buys Mulligans you'll have a better chance of
              posting a great score AND the Organization you're supporting gets{" "}
              <b>
                {tournament?.organization
                  ? formatPercents(
                      100 - tournament.organization.mulligan_commission
                    )
                  : "-"}
              </b>{" "}
              of the mulligan proceeds!
            </FormHelperText>
          </BasicFormRow>
        </Collapse>
        <Collapse in={!Boolean(pre_paid_slot_info)}>
          <BasicFormRow>
            <RegistrationCostBox cost={registration_cost} maxWidth={600} />
          </BasicFormRow>
        </Collapse>
        <BasicFormRow
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <Field<boolean> type="checkbox" name="tos_accepted">
            {({ input }) => (
              <FormControlLabel
                control={<Checkbox {...input} color="primary" />}
                label="By signing up for the tournament I agree to the terms and conditions"
                style={{ marginRight: 50 }}
              />
            )}
          </Field>
          <InfoBox maxWidth={600} maxHeight={300}>
            <GolferTermsOfService tournament={tournament} />
          </InfoBox>
        </BasicFormRow>
        <BasicFormRow
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <InputLabel htmlFor="join-tournament-form-notes">Notes</InputLabel>
          <Field<string> name="notes">
            {({ input, meta }) => (
              <FormRowWithHelperText
                helperText={
                  meta.touched ? meta.error || meta.submitError : null
                }
                isErrored={Boolean(
                  meta.touched && (meta.error || meta.submitError)
                )}
              >
                <OutlinedInput
                  fullWidth
                  id="join-tournament-form-notes"
                  placeholder="Registration Notes"
                  error={Boolean(
                    meta.submitError || (meta.touched && meta.error)
                  )}
                  multiline
                  style={{ maxWidth: 600 }}
                  inputProps={{ style: { height: 70, overflow: "auto" } }}
                  {...input}
                />
              </FormRowWithHelperText>
            )}
          </Field>
        </BasicFormRow>
        <Collapse in={Boolean(submitError)}>
          <BasicFormRow>
            <Alert fullWidth>
              <Typography variant="body1">{submitError}</Typography>
            </Alert>
          </BasicFormRow>
        </Collapse>
        <BasicFormRow>
          <Field<boolean> name="tos_accepted">
            {({ input: { value: tos_accepted } }) => (
              <Button
                disabled={Boolean(
                  !tos_accepted ||
                    submitting ||
                    isLoadingPromoCodeInfo ||
                    promoCodeError
                )}
                type="submit"
              >
                Submit Payment & Register
              </Button>
            )}
          </Field>
        </BasicFormRow>
      </form>
    </GolferPage>
  );
}
