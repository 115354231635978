import React from "react";
import raw from "raw.macro";

import { HTMLContentPage } from "./HTMLContentPage";

export const TermsContent: string = raw("./terms.html");
export const PolicyContent: string = raw("./policy.html");

export const TermsOfServicePage = () => (
  <HTMLContentPage content={TermsContent} />
);
export const PrivacyPolicyPage = () => (
  <HTMLContentPage content={PolicyContent} />
);
