import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, BoxProps, makeStyles } from "@material-ui/core";
import clsx from "clsx";

import { AppConfig } from "config/AppConfig";

import { PageConfig } from "../PageConfig";
import logo from "./logo.png";
import { FlexGolfTheme } from "config/theme";

interface PageHeaderBaseProps extends Omit<BoxProps, "children"> {
  transparent?: boolean;
  to?: string;
  navigation?: React.ReactNode;
}

export default function PageHeaderBase({
  transparent = false,
  to = "/",
  navigation,
  className,
  ...restProps
}: PageHeaderBaseProps) {
  const classes = useStyles({ transparent });

  return (
    <Box className={clsx(classes.root, className)} {...restProps}>
      <Box className={classes.inner}>
        <Box py={0.5}>
          <RouterLink to={to}>
            <img
              src={logo}
              alt={`${AppConfig.Application.Name} Logo`}
              width={608 / 5}
              height={453 / 5}
            />
          </RouterLink>
        </Box>
        <Box component="nav" className={classes.nav}>
          {navigation}
        </Box>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme: FlexGolfTheme) => ({
  root: {
    backgroundColor: ({ transparent }: { transparent: boolean }) =>
      transparent ? "transparent" : theme.darkBlock.background,
    color: ({ transparent }: { transparent: boolean }) =>
      transparent ? "inherit" : theme.darkBlock.text,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  inner: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: PageConfig.ContentMaxWidthPx,
    width: `calc(100% - ${theme.spacing(PageConfig.MobileGuttersUnits * 2)}px)`,
    margin: theme.spacing(0, PageConfig.MobileGuttersUnits),
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${theme.spacing(
        PageConfig.TabletGuttersUnits * 2
      )}px)`,
      margin: theme.spacing(0, PageConfig.TabletGuttersUnits),
    },
  },
  nav: {
    textAlign: "right",
    "&& > * + *": {
      margin: theme.spacing(0.5, 0, 0.5, 2),
      [theme.breakpoints.up("sm")]: {
        margin: theme.spacing(0.5, 0, 0.5, 4),
      },
    },
  },
}));
