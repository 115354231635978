import memoizeOne from "memoize-one";

import {
  TournamentFinisher,
  isTeamFinisher,
  isIndividualFinisher,
} from "./TournamentEntrant.types";

function getFinisherNetToPar(finisher: TournamentFinisher): number {
  if (isTeamFinisher(finisher)) {
    return finisher.score_registration.net_to_par ?? Infinity;
  } else if (isIndividualFinisher(finisher)) {
    return finisher.registration.net_to_par ?? Infinity;
  } else {
    return Infinity;
  }
}

export const sortTournamentFinishers = memoizeOne(
  function sortTournamentFinishers(
    finishers: TournamentFinisher[]
  ): TournamentFinisher[] {
    return [...finishers].sort((participantOne, participantTwo) => {
      return (
        getFinisherNetToPar(participantOne) -
        getFinisherNetToPar(participantTwo)
      );
    });
  }
);
