import memoizeOne from "memoize-one";
import * as yup from "yup";

import {
  OrganizationFormModel,
  Organization,
  OrganizationErrors,
} from "api/organizer/organization";
import { Country } from "api/common";
import { createRemoteImageValue } from "components/core/ImageInput";
import { makeValidator, AddressAttributesSchema } from "utils/forms";
import { ImageInputValue } from "components/core/ImageInput/ImageInput.utils";

export const makeInitialFormModel = memoizeOne(function makeInitialFormModel(
  organization: Organization | undefined,
  country: Country | undefined
): OrganizationFormModel {
  return {
    name: organization?.name ?? "",
    email: organization?.email ?? "",
    url: organization?.url ?? "",
    tin_ssn_ciphertext: organization?.tin_ssn_ciphertext ?? "",
    logo: organization?.logo
      ? createRemoteImageValue(organization.logo.thumb)
      : undefined,
    facebook_url: organization?.facebook_url ?? "",
    twitter_url: organization?.twitter_url ?? "",
    instagram_url: organization?.instagram_url ?? "",
    address_attributes: {
      id: organization?.address?.id,
      country_id:
        organization?.address?.country_id ?? (country ? country.id : undefined),
      state_id: organization?.address?.state_id,
      street_1: organization?.address?.street_1 ?? "",
      street_2: organization?.address?.street_2 ?? "",
      city: organization?.address?.city ?? "",
      zip: organization?.address?.zip ?? "",
    },
  };
});

const OrganizationSchema: yup.ObjectSchema<Partial<OrganizationFormModel>> = yup
  .object({
    tin_ssn_ciphertext: yup
      .string()
      .matches(/^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$/, "must be exactly 9 digits")
      .required(),
    logo: yup.object<ImageInputValue>().required("logo is required"),
    address_attributes: AddressAttributesSchema,
  })
  .defined();

export const validateOrganizationFormModel = makeValidator<
  OrganizationFormModel,
  OrganizationErrors
>(OrganizationSchema);
