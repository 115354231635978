import React from "react";
import { Typography, Box, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

import { TournamentRegistration } from "api/golfer/tournament-registrations";
import { Alert } from "components/core/Alert";

interface PaymentFailedProps {
  registration: TournamentRegistration;
}

export default function PaymentFailed({ registration }: PaymentFailedProps) {
  return (
    <Box
      my={3}
      maxWidth={600}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography variant="h5" component="p" align="center">
        Payment Error
      </Typography>
      <Box my={3}>
        <Alert fullWidth>
          <Typography variant="body1">
            {registration.payment_status_message || "Unknown error"}
          </Typography>
        </Alert>
      </Box>
      <Link
        to={{
          pathname: "/golfer/update-payment-method",
          state: { registration },
        }}
        component={RouterLink}
        variant="body1"
        align="center"
        style={{ fontWeight: 500 }}
      >
        Change Payment Method
      </Link>
    </Box>
  );
}
