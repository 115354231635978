import { GolfCourse } from "./course";

export enum TournamentFormat {
  Scramble = "scramble",
  BestBall = "best_ball",
  Individual = "individual",
}

export interface TournamentImage {
  thumb: string;
  medium: string;
  large: string;
}

export interface TournamentSponsor {
  id: number;
  tournament_id: number;
  name: string;
  url: string;
  logo?: { thumb: string; medium: string };
}

export enum TournamentStatus {
  Draft = "draft",
  Published = "published",
}

export interface Tournament {
  id: number;
  name: string;
  slug: string;
  course?: GolfCourse;
  form: TournamentFormat;
  start_date: string;
  end_date: string;
  details: string;
  prizes: string;
  mulligan: boolean;
  mulligan_price: string | undefined;
  registration_fee: string;
  image: TournamentImage | undefined;
  sponsors: TournamentSponsor[];
  offering_tshirt: boolean;
  status: TournamentStatus;
}

export interface TournamentTeam {
  id: number;
  name: string;
  paid_slots: number;
  paid_slots_left: number;
  paid_slots_with_mulligan: boolean;
}

export enum TShirtSize {
  MenS = "men_s",
  MenM = "men_m",
  MenL = "men_l",
  MenXL = "men_xl",
  MenXXL = "men_xxl",
  MenXXXL = "men_xxxl",
  WomenS = "women_s",
  WomenM = "women_m",
  WomenL = "women_l",
  WomenXL = "women_xl",
  WomenXXL = "women_xxl",
}
