import useSWR from "swr";
import axios from "axios";

import { GuestTournament, getTournamentBySlug } from "../getTournamentsBySlug";

export function useTournamentBySlug(
  slug: string | undefined
): { tournament: GuestTournament | undefined; error: Error | undefined } {
  const { data: tournament, error } = useSWR(
    slug ? ["guest-tournament", slug] : null,
    (_: any, slug: string): Promise<GuestTournament> => {
      const cancelSource = axios.CancelToken.source();
      const promise = getTournamentBySlug(slug, cancelSource.token);
      (promise as any).cancel = () =>
        cancelSource.cancel("request was cancelled");
      return promise;
    }
  );

  return { tournament, error };
}
