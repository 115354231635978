import memoizeOne from "memoize-one";
import { getUnixTime, parseISO } from "date-fns";

import { OrganizerTournamentRegistration } from "api/organizer/tournament-registrations";

export const sortTournamentRegistrations = memoizeOne(
  function sortTournamentRegistrations(
    registrations: OrganizerTournamentRegistration[]
  ): OrganizerTournamentRegistration[] {
    return [...registrations].sort((r1, r2) => {
      return (
        getUnixTime(parseISO(r2.created_at)) -
        getUnixTime(parseISO(r1.created_at))
      );
    });
  }
);
