import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { LoginGolferPage } from "./pages/LoginGolferPage";
import { GolferForgotPasswordPage } from "./pages/GolferForgotPasswordPage";
import { GolferResetPasswordPage } from "./pages/GolferResetPasswordPage";
import { CreateGolferAccountPage } from "./pages/CreateGolferAccountPage";
import { GolferDashboardPage } from "./pages/GolferDashboardPage";
import { UpdateRegistrationPaymentMethodPage } from "./pages/UpdateRegistrationPaymentMethodPage";
import { ScoreSubmissionSection } from "./pages/ScoreSubmissionSection";
import { UpdateGolferAccountPage } from "./pages/UpdateGolferAccountPage";

const GolferSection = () => (
  <Switch>
    <Route exact path="/golfer/login" component={LoginGolferPage} />
    <Route
      exact
      path="/golfer/forgot-password"
      component={GolferForgotPasswordPage}
    />
    <Route
      exact
      path="/golfer/reset-password/:token"
      component={GolferResetPasswordPage}
    />
    <Route
      exact
      path="/golfer/create-account"
      component={CreateGolferAccountPage}
    />
    <Route exact path="/golfer/dashboard" component={GolferDashboardPage} />
    <Route
      exact
      path="/golfer/update-payment-method"
      component={UpdateRegistrationPaymentMethodPage}
    />
    <Route
      path="/golfer/registration/:registrationId"
      component={ScoreSubmissionSection}
    />
    <Route path="/golfer/update-account" component={UpdateGolferAccountPage} />
    <Route render={() => <Redirect to="/golfer/dashboard" />} />
  </Switch>
);

export default GolferSection;
