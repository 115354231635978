import React, { useCallback, useMemo } from "react";
import {
  OutlinedInput,
  InputAdornment,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";

import { TeamIdSelect } from "components/core/TeamIdSelect";
import {
  OrganizerTournamentRegistration,
  reassignTournamentRegistrationTeam,
} from "api/organizer/tournament-registrations";
import { TournamentTeam } from "api/types";

import { TournamentParticipant } from "../../TournamentParticipantsPage.utils";

interface TeamCellProps {
  participant: TournamentParticipant;
  teams: TournamentTeam[];
  onRegistrationUpdated: (
    updatedRegistration: OrganizerTournamentRegistration
  ) => void;
}

export default function TeamCell({
  participant,
  teams,
  onRegistrationUpdated,
}: TeamCellProps) {
  const classes = useStyles();

  const handleTeamChange = useCallback(
    async (newTeamId: number | undefined) => {
      if (newTeamId) {
        await reassignTournamentRegistrationTeam(
          participant.registration.tournament_id,
          participant.registration.id,
          newTeamId
        );
        onRegistrationUpdated({
          ...participant.registration,
          team_id: newTeamId,
        });
      }
    },
    [onRegistrationUpdated, participant]
  );

  const whyDisabled = useMemo(() => {
    if (
      participant.kind === "team" &&
      typeof participant.registration.score === "number"
    ) {
      return "You can not reassign this registration to another team because it holds this team's score.";
    } else if (
      participant.kind === "individual" &&
      typeof participant.registration.score === "number"
    ) {
      return "You can not reassign this registration to a team because this individual has already submitted their score.";
    } else if (
      participant.registration.golfer_id === participant.team?.golfer_id
    ) {
      return "You can not reassign this registration to another team because it's the owner of this team.";
    } else {
      return null;
    }
  }, [participant]);

  return (
    <TeamIdSelect
      classes={classes}
      teams={teams}
      value={participant.team?.id}
      onChange={handleTeamChange}
      fullWidth
      input={
        <OutlinedInput
          endAdornment={
            whyDisabled && (
              <InputAdornment position="end">
                <Tooltip title={whyDisabled}>
                  <LockIcon style={{ cursor: "help" }} />
                </Tooltip>
              </InputAdornment>
            )
          }
        />
      }
      disabled={Boolean(whyDisabled)}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    "&.Mui-disabled": {
      display: "none",
    },
  },
}));
