import { useEffect, useState } from "react";
import axios, { CancelToken } from "axios";

import { PromoCodeInfo, getPromoCode } from "api/golfer/promo-codes";

interface UsePromoCodeInfoResult {
  isLoading: boolean;
  promoCodeInfo: PromoCodeInfo | undefined;
  error: Error | undefined;
}

async function getPromoCodeEnhanced(
  promoCode: string,
  cancelToken: CancelToken
): Promise<PromoCodeInfo | undefined> {
  if (promoCode) {
    return await getPromoCode(promoCode, cancelToken);
  } else {
    return undefined;
  }
}

export function usePromoCodeInfo(promoCode: string): UsePromoCodeInfoResult {
  const [promoCodeInfo, setPromoCodeInfo] = useState<
    PromoCodeInfo | undefined
  >();
  const [err, setErr] = useState<Error | undefined>();

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    setPromoCodeInfo(undefined);
    setErr(undefined);
    getPromoCodeEnhanced(promoCode, cancelSource.token)
      .then((promoCodeInfo) => {
        if (promoCodeInfo?.status === "inactive") {
          setErr(Error("promo code is inactive"));
          setPromoCodeInfo(undefined);
        } else {
          setPromoCodeInfo(promoCodeInfo);
          setErr(undefined);
        }
      })
      .catch((err) => {
        setErr(Error("promo code does not exist"));
        setPromoCodeInfo(undefined);
      });
    return () => cancelSource.cancel("request was cancelled");
  }, [promoCode]);

  return { promoCodeInfo, error: err, isLoading: false };
}
