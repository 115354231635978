import React from "react";
import { TableRow, TableCell, Typography } from "@material-ui/core";

import {
  TournamentFinisherIndividual,
  formatGolferName,
  formatNetToPar,
} from "utils/tournament/leaderboard";

import { TeamCell } from "../TeamCell";
import { CourseCell } from "../CourseCell";

interface TableRowIndividualProps {
  place: number;
  finisher: TournamentFinisherIndividual;
}

export default function TableRowIndividual({
  place,
  finisher,
}: TableRowIndividualProps) {
  return (
    <TableRow>
      <TableCell align="center">
        <Typography>{place}</Typography>
      </TableCell>
      <TableCell>
        <TeamCell
          photo={{
            src: finisher.registration.photo?.medium,
            largeSrc: finisher.registration.photo?.original,
            alt: formatGolferName(finisher.registration.golfer),
          }}
        >
          <Typography>
            {formatGolferName(finisher.registration.golfer)}
          </Typography>
        </TeamCell>
      </TableCell>
      <TableCell align="center">
        <Typography>{finisher.registration.score}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography>
          {formatNetToPar(finisher.registration.net_to_par)}
        </Typography>
      </TableCell>
      <TableCell align="center">
        {finisher.registration.tee && (
          <Typography>{finisher.registration.tee.target_score}</Typography>
        )}
      </TableCell>
      <TableCell>
        <CourseCell registration={finisher.registration} />
      </TableCell>
    </TableRow>
  );
}
