import * as yup from "yup";

import { AddressAttributes } from "api/types";

export const AddressAttributesSchema = yup
  .object<AddressAttributes>({
    country_id: yup.number(),
    state_id: yup.number().required("required"),
    street_1: yup.string().required("required"),
    street_2: yup.string().defined(),
    city: yup.string().required("required"),
    zip: yup
      .string()
      .matches(/^[0-9]{5}$/, "must be exactly 5 digits")
      .required("required"),
  })
  .defined();
