import React from "react";
import { Redirect, useLocation } from "react-router-dom";

import { SessionServiceStatus } from "utils/session";
import { getComponentWrappedName } from "utils/getComponentWrappedName";
import { GolferPostLoginLocationContainer } from "config/globals";

import { useGolferSession } from "./GolferSessionProvider";

export function EnsureGolferLoggedIn<P>(
  Component: React.ComponentType<P>,
  redirectTo: string = "/golfer/login"
) {
  function EnsureGolferLoggedInWrapper(props: P) {
    const { status } = useGolferSession();
    const location = useLocation();

    if (status === SessionServiceStatus.Unauthenticated) {
      GolferPostLoginLocationContainer.setValue({
        pathname: location.pathname,
        search: location.search,
      });
      return <Redirect to={redirectTo} />;
    } else {
      return <Component {...props} />;
    }
  }

  EnsureGolferLoggedInWrapper.displayName = getComponentWrappedName(
    "EnsureGolferLoggedIn",
    Component
  );

  return EnsureGolferLoggedInWrapper;
}
