import React, { useState, useCallback } from "react";
import { useSnackbar } from "notistack";

import { getApiErrorMessage } from "utils/network";
import {
  publishTournament,
  OrganizerTournament,
  getTournamentById,
} from "api/organizer/tournaments";
import { useOrganizerSession } from "utils/organizer-session";

interface PublishTournamentButtonRenderProps {
  onClick: () => void;
  disabled: boolean;
}

interface PublishTournamentButtonProps {
  tournament: OrganizerTournament;
  onPublished?: (tournament: OrganizerTournament) => void;
  children: (props: PublishTournamentButtonRenderProps) => React.ReactElement;
}

export default function PublishTournamentButton({
  tournament,
  onPublished,
  children,
}: PublishTournamentButtonProps) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const { token } = useOrganizerSession();

  const { enqueueSnackbar } = useSnackbar();

  const onClick = useCallback(async () => {
    try {
      setLoading(true);
      await publishTournament(tournament.id);
      const updatedTournament = await getTournamentById(token!, tournament.id);
      setLoading(false);
      enqueueSnackbar("Tournament was succesfully published", {
        variant: "success",
      });
      if (onPublished) {
        onPublished(updatedTournament);
      }
    } catch (err) {
      setLoading(false);
      enqueueSnackbar(getApiErrorMessage(err), {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, token, tournament, onPublished]);

  return children({
    onClick: onClick,
    disabled: isLoading,
  });
}
