import React, { useCallback, useMemo, useEffect, useState } from "react";
import { Select, SelectProps, MenuItem, ListItemText } from "@material-ui/core";
import axios, { CancelToken } from "axios";

import { GolfCourseTee } from "api/types";

import FormatTeeDetails from "./FormatTeeDetails";

interface TeeIdSelectProps extends Omit<SelectProps, "onChange"> {
  courseId: number | undefined;
  value: number | undefined;
  onChange: (nextValue: number | undefined) => void;
  getCourseTees: (
    courseId: number,
    cancelToken: CancelToken
  ) => Promise<GolfCourseTee[]>;
}

export default function TeeIdSelect({
  courseId,
  value,
  onChange,
  getCourseTees,
  ...restProps
}: TeeIdSelectProps) {
  const [tees, setTees] = useState<GolfCourseTee[]>([]);

  const getCourseTeesEnhanced = useCallback(
    async (
      courseId: number | undefined,
      cancelToken: CancelToken
    ): Promise<GolfCourseTee[]> => {
      if (courseId) {
        return await getCourseTees(courseId, cancelToken);
      } else {
        return [];
      }
    },
    [getCourseTees]
  );

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    getCourseTeesEnhanced(courseId, cancelSource.token)
      .then(setTees)
      .catch(() => setTees([]));

    return () => cancelSource.cancel("request was cancelled");
  }, [courseId, getCourseTeesEnhanced]);

  const handleChange = useCallback(
    (evt: any) => {
      if (evt.target.value) {
        onChange(parseInt(evt.target.value));
      } else {
        onChange(undefined);
      }
    },
    [onChange]
  );

  const actualValue = useMemo(() => {
    if (value && tees.find((tee) => tee.id === value)) {
      return value;
    } else {
      return "";
    }
  }, [value, tees]);

  return (
    <Select value={actualValue} onChange={handleChange} {...restProps}>
      {tees.map((tee) => (
        <MenuItem key={tee.id} value={tee.id}>
          <ListItemText
            primary={tee.name}
            secondary={<FormatTeeDetails tee={tee} />}
          />
        </MenuItem>
      ))}
    </Select>
  );
}
