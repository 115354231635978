import React, { useState, useCallback } from "react";
import {
  Button,
  ButtonProps,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import { saveAs } from "file-saver";
import { useSnackbar } from "notistack";
import clsx from "clsx";

import { getApiErrorMessage } from "utils/network";

interface DownloadFileButtonProps extends ButtonProps {
  download: () => Promise<Blob>;
  getFileName: () => string;
}

export default function DownloadFileButton({
  download,
  getFileName,
  children,
  className,
  disabled,
  ...restProps
}: DownloadFileButtonProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [isFetching, setFetching] = useState(false);

  const doDownload = useCallback(async () => {
    setFetching(true);
    try {
      const file = await download();
      setFetching(false);
      saveAs(file, getFileName());
    } catch (err) {
      setFetching(false);
      enqueueSnackbar(getApiErrorMessage(err), { variant: "error" });
    }
  }, [enqueueSnackbar, download, getFileName]);

  return (
    <Button
      className={clsx(classes.root, className)}
      disabled={isFetching || disabled}
      onClick={doDownload}
      {...restProps}
    >
      {children}
      {isFetching && (
        <CircularProgress
          className={classes.spinner}
          size={16}
          color="primary"
        />
      )}
    </Button>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  spinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));
