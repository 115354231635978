import React from "react";
import { TableCell, TableRow, Typography } from "@material-ui/core";
import { format, parseISO } from "date-fns";
import currency from "currency.js";

import { formatGolferName } from "utils/tournament/leaderboard";
import { OrganizerTournamentRegistration } from "api/organizer/tournament-registrations";

interface TransactionsTableRowProps {
  registration: OrganizerTournamentRegistration;
}

export default function TransactionsTableRow({
  registration,
}: TransactionsTableRowProps) {
  return (
    <TableRow>
      <TableCell>
        <Typography>
          {format(parseISO(registration.created_at), "MM/dd/yyyy")}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>{formatGolferName(registration.golfer)}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{registration.golfer.email}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{currency(registration.total_price).format()}</Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {currency(registration.amount_raised_for_charity).format()}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
