import { useEffect, useState } from "react";
import axios, { CancelToken } from "axios";
import { useHistory } from "react-router-dom";

import { useOrganizerSession } from "utils/organizer-session";
import { Organization, getOrganization } from "api/organizer/organization";

async function getOrganizationEnhanced(
  token: string | undefined,
  cancelToken: CancelToken
): Promise<Organization | undefined> {
  if (!token) {
    return undefined;
  } else {
    return await getOrganization(token, cancelToken);
  }
}

export function useOrganizationLock(): Organization | undefined {
  const [organization, setOrganization] = useState<Organization | undefined>(
    undefined
  );

  const history = useHistory();

  const { token } = useOrganizerSession();

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    getOrganizationEnhanced(token, cancelSource.token)
      .then((org) => {
        if (org) {
          setOrganization(org);
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          history.replace("/organizer/update-organization");
        }
      });

    return () => cancelSource.cancel("request cancelled");
  }, [token, history]);

  return organization;
}
