import React, { useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Link,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import { formatISO9075 } from "date-fns";

import { OrganizerPage } from "components/layout";
import { Tournament } from "api/types";
import { TextSkeleton } from "components/core/Skeleton";
import { TournamentTeam } from "api/organizer/tournament-teams";
import {
  OrganizerTournamentRegistration,
  TournamentRegistrationsExportType,
  exportTournamentRegistrations,
} from "api/organizer/tournament-registrations";
import { DownloadFileButton } from "components/core/DownloadFileButton";
import { InfoBox } from "components/core/InfoBox";

import { TournamentParticipant } from "./TournamentParticipantsPage.utils";
import { ParticipantsTableRow } from "./components/ParticipantsTableRow";

interface TournamentParticipantsPageProps {
  tournament: Tournament | undefined;
  participants: TournamentParticipant[];
  teams: TournamentTeam[];
  isInitialized: boolean;
  onRegistrationUpdated: (r: OrganizerTournamentRegistration) => void;
}

export default function TournamentParticipantsPage({
  tournament,
  participants,
  teams,
  isInitialized,
  onRegistrationUpdated,
}: TournamentParticipantsPageProps) {
  const download = useCallback(
    () =>
      exportTournamentRegistrations(
        tournament?.id!,
        TournamentRegistrationsExportType.Registrations
      ),
    [tournament]
  );

  const getFileName = useCallback(
    () =>
      `${tournament?.name} - Registrations - ${formatISO9075(new Date())}.csv`,
    [tournament]
  );

  const withTShirtSizeColumn = tournament?.offering_tshirt ?? false;

  return (
    <OrganizerPage>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {tournament ? (
            <Typography variant="h2" component="h1">
              <Link
                color="inherit"
                underline="none"
                component={RouterLink}
                to={`/tournament/${tournament.slug}`}
              >
                {tournament.name}
              </Link>{" "}
              - Registration List
            </Typography>
          ) : (
            <TextSkeleton variant="h2" width="50%" />
          )}
        </Grid>
        <Grid item xs={12}>
          <Button
            component={RouterLink}
            to="/organizer/dashboard"
            style={{ marginRight: 8 }}
            size="small"
          >
            Back to Dashboard
          </Button>
          {participants.length > 0 && (
            <DownloadFileButton
              download={download}
              getFileName={getFileName}
              size="small"
            >
              Export to CSV
            </DownloadFileButton>
          )}
        </Grid>
        {participants.length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                {[
                  "Date Created",
                  "Name",
                  "Email",
                  "City",
                  "State",
                  "ZIP",
                  "Team",
                  "Score",
                  withTShirtSizeColumn && "T-Shirt Size",
                ]
                  .filter(Boolean)
                  .map((col, index) => (
                    <TableCell key={index}>
                      <Typography variant="body2">{col}</Typography>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {participants.map((participant) => (
                <ParticipantsTableRow
                  key={participant.id}
                  participant={participant}
                  teams={teams}
                  onRegistrationUpdated={onRegistrationUpdated}
                  withTShirtSizeColumn={withTShirtSizeColumn}
                />
              ))}
            </TableBody>
          </Table>
        )}
        {isInitialized && participants.length === 0 && (
          <Grid item xs={12}>
            <InfoBox>
              <Box
                py={5}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Typography variant="h5" align="center" color="textSecondary">
                  Nobody has joined this tournament yet
                </Typography>
              </Box>
            </InfoBox>
          </Grid>
        )}
      </Grid>
    </OrganizerPage>
  );
}
