import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import TagManager from "react-gtm-module";

import "./index.css";

import { AppRoot } from "./components/system/AppRoot";

import * as serviceWorker from "./serviceWorker";
import { createOrganizerSessionService } from "utils/organizer-session";
import { createGolferSessionService } from "utils/golfer-session";
import { createAppTheme } from "config/theme";
import { initializeGolferApiClientAuto } from "api/ApiClient/GolferApiClient";
import { initializeOrganizerApiClientAuto } from "api/ApiClient/OrganizerApiClient";
import { AppConfig } from "config/AppConfig";

const history = createBrowserHistory();
const sessionServiceOrganizer = createOrganizerSessionService();
const sessionServiceGolfer = createGolferSessionService();
const muiTheme = createAppTheme();

(window as any).FlexGolf = {
  history,
  sessionServiceOrganizer,
  sessionServiceGolfer,
  muiTheme,
  AppConfig,
};

sessionServiceOrganizer.initialize();
sessionServiceGolfer.initialize();

initializeGolferApiClientAuto(sessionServiceGolfer);
initializeOrganizerApiClientAuto(sessionServiceOrganizer);

TagManager.initialize({ gtmId: AppConfig.Integrations.GoogleTagManager.GtmID });

ReactDOM.render(
  <React.StrictMode>
    <AppRoot
      history={history}
      sessionServiceOrganizer={sessionServiceOrganizer}
      sessionServiceGolfer={sessionServiceGolfer}
      muiTheme={muiTheme}
    />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
