import React, { useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";

import { OrganizerPage } from "components/layout";
import { useTournamentById } from "api/organizer/tournaments";
import { TextSkeleton } from "components/core/Skeleton";
import { EnsureOrganizerLoggedIn } from "utils/organizer-session";
import {
  SponsorFormModel,
  createTournamentSponsor,
} from "api/organizer/tournament-sponsors";

import { SponsorForm } from "../../components/SponsorForm";

function AddTournamentSponsorPage() {
  const { tournamentId } = useParams<{ tournamentId: string }>();
  const history = useHistory();

  const { tournament, revalidate: revalidateTournament } = useTournamentById(
    parseInt(tournamentId)
  );

  const saveFormModel = useCallback(
    async (formModel: SponsorFormModel) => {
      await createTournamentSponsor(parseInt(tournamentId), formModel);
      revalidateTournament();
      history.push(`/organizer/tournament/${tournamentId}/sponsors`);
    },
    [history, revalidateTournament, tournamentId]
  );

  return (
    <OrganizerPage>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {tournament ? (
            <Typography variant="h2" display="inline">
              {tournament.name} - Add New Sponsor
            </Typography>
          ) : (
            <TextSkeleton variant="h2" width="50%" />
          )}
        </Grid>
        <Grid item xs={12}>
          <SponsorForm saveFormModel={saveFormModel} />
        </Grid>
      </Grid>
    </OrganizerPage>
  );
}

export default EnsureOrganizerLoggedIn(AddTournamentSponsorPage);
