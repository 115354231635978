import EventEmitter from "eventemitter3";
import { AuthTokenStorage } from "./AuthTokenStorage";

export enum SessionServiceStatus {
  Initial,
  Authenticated,
  Unauthenticated,
}

export interface SessionServiceState<TUser> {
  status: SessionServiceStatus;
  token: string | undefined;
  user: TUser | undefined;
}

type SessionServiceChangeListener<TUser> = (
  prevState: SessionServiceState<TUser>,
  nextState: SessionServiceState<TUser>
) => void;

export interface SessionService<TUser> {
  getState: () => SessionServiceState<TUser>;
  logIn: (token: string, user: TUser) => void;
  logOut: () => void;
  updateUser: (updatedUser: TUser) => void;
  initialize: () => void;
  addChangeListener: (f: SessionServiceChangeListener<TUser>) => void;
  removeChangeListener: (f: SessionServiceChangeListener<TUser>) => void;
}

export function createSessionService<TUser extends { id: number }>(
  tokenStorage: AuthTokenStorage,
  fetchUserByToken: (token: string) => Promise<{ user: TUser; token: string }>
): SessionService<TUser> {
  const initialState: SessionServiceState<TUser> = {
    status: SessionServiceStatus.Initial,
    token: undefined,
    user: undefined,
  };

  const emitter = new EventEmitter();
  let state: SessionServiceState<TUser> = { ...initialState };

  function setState(nextState: SessionServiceState<TUser>) {
    const prevState = state;
    state = nextState;
    emitter.emit("change", nextState, prevState);
  }

  function initializeWithToken(initialToken: string | null) {
    setState({
      ...initialState,
      status: SessionServiceStatus.Initial,
    });
    if (!initialToken) {
      setState({
        ...initialState,
        status: SessionServiceStatus.Unauthenticated,
      });
      return;
    }
    fetchUserByToken(initialToken)
      .then(({ user, token }) => {
        setState({
          status: SessionServiceStatus.Authenticated,
          token,
          user,
        });
        tokenStorage.setAuthToken(token);
      })
      .catch((error) => {
        setState({
          ...initialState,
          status: SessionServiceStatus.Unauthenticated,
        });
        tokenStorage.clearAuthToken();
      });
  }

  return {
    getState() {
      return state;
    },
    logIn(token: string, user: TUser) {
      setState({
        status: SessionServiceStatus.Authenticated,
        token,
        user,
      });
      tokenStorage.setAuthToken(token);
    },
    logOut() {
      setState({
        ...initialState,
        status: SessionServiceStatus.Unauthenticated,
      });
      tokenStorage.clearAuthToken();
    },
    updateUser(updatedUser: TUser) {
      if (state.status !== SessionServiceStatus.Authenticated) {
        console.log(
          [
            "Trying to update current user while not authenticated",
            `updatedUser.id=${updatedUser.id}`,
          ].join(" ")
        );
        return;
      }
      if (state.user?.id !== updatedUser.id) {
        console.log(
          [
            "Trying to replace currently authenticated user",
            `with other one: currentUser.id=${state.user?.id}`,
            `updatedUser.id=${updatedUser.id}`,
          ].join(" ")
        );
        return;
      }
      setState({ ...state, user: updatedUser });
    },
    initialize() {
      tokenStorage.addChangeListener((updatedToken) => {
        if (updatedToken === state.token) {
          // NOTE: Skip update when token is the same
          return;
        }
        initializeWithToken(updatedToken);
      });

      initializeWithToken(tokenStorage.getAuthToken());
    },
    addChangeListener(listener: SessionServiceChangeListener<TUser>) {
      emitter.on("change", listener);
    },
    removeChangeListener(listener: SessionServiceChangeListener<TUser>) {
      emitter.off("change", listener);
    },
  };
}
