import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Link, makeStyles, Typography } from "@material-ui/core";

import { FlexGolfTheme } from "config/theme";
import { AppConfig } from "config/AppConfig";

import { PageConfig } from "../PageConfig";

export default function PageFooter() {
  const classes = useStyles();

  return (
    <Box className={classes.root} py={1}>
      <Box className={classes.inner}>
        <Box p={2} pl={0}>
          <Link
            variant="h5"
            color="inherit"
            underline="none"
            component={RouterLink}
            to="/"
          >
            {AppConfig.Application.Name}
          </Link>
          <Box py={3}>
            <Typography>{AppConfig.Contacts.AddressLine1}</Typography>
            <Typography>{AppConfig.Contacts.AddressLine2}</Typography>
            <Link
              variant="body1"
              color="inherit"
              underline="hover"
              href={`tel:${AppConfig.Contacts.Phone}`}
            >
              {AppConfig.Contacts.Phone}
            </Link>
          </Box>
          <Link
            variant="body1"
            color="inherit"
            underline="hover"
            href={`mailto:${AppConfig.Contacts.Email}`}
          >
            {AppConfig.Contacts.Email}
          </Link>
        </Box>
        <Box py={2}>
          <Box pb={2}>
            <Link
              variant="body1"
              color="inherit"
              underline="always"
              style={{ display: "block" }}
              component={RouterLink}
              to="/terms-of-service"
            >
              Terms Of Service
            </Link>
            <Link
              variant="body1"
              color="inherit"
              underline="always"
              style={{ display: "block" }}
              component={RouterLink}
              to="/privacy-policy"
            >
              Privacy Policy
            </Link>
          </Box>
          <Typography variant="h6" component="p">
            Follow
          </Typography>
          <Box pt={2}>
            <Link
              variant="body1"
              color="inherit"
              underline="always"
              style={{ display: "block" }}
              href={AppConfig.Contacts.SocialNetworks.Instagram}
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </Link>
            <Link
              variant="body1"
              color="inherit"
              underline="always"
              style={{ display: "block" }}
              href={AppConfig.Contacts.SocialNetworks.Facebook}
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </Link>
            <Link
              variant="body1"
              color="inherit"
              underline="always"
              style={{ display: "block" }}
              href={AppConfig.Contacts.SocialNetworks.Linkedin}
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme: FlexGolfTheme) => ({
  root: {
    backgroundColor: theme.darkBlock.background,
    color: theme.darkBlock.text,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  inner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    maxWidth: PageConfig.ContentMaxWidthPx,
    width: `calc(100% - ${theme.spacing(PageConfig.MobileGuttersUnits * 2)}px)`,
    margin: theme.spacing(0, PageConfig.MobileGuttersUnits),
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-between",
      width: `calc(100% - ${theme.spacing(
        PageConfig.TabletGuttersUnits * 2
      )}px)`,
      margin: theme.spacing(0, PageConfig.TabletGuttersUnits),
    },
  },
}));
