import { useState, useMemo, useCallback } from "react";

import { RequestToAddCourseDialogProps } from "./RequestToAddCourseDialogProps";

export function useRequestToAddCourseDialogState(): [
  RequestToAddCourseDialogProps,
  () => void
] {
  const [isOpen, setOpen] = useState<boolean>(false);

  const dialogProps: RequestToAddCourseDialogProps = useMemo(
    () => ({
      isOpen,
      onClose: () => setOpen(false),
      onExited: () => undefined,
    }),
    [isOpen]
  );

  const openDialog = useCallback(() => {
    setOpen(true);
  }, []);

  return [dialogProps, openDialog];
}
