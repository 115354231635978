import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { EnsureOrganizerLoggedIn } from "utils/organizer-session";

import { TournamentParticipantsPage } from "./pages/TournamentParticipantsPage";
import { TournamentTransactionsPage } from "./pages/TournamentTransactionsPage";
import { TournamentScoresPage } from "./pages/TournamentScoresPage";
import { TournamentSponsorsPage } from "./pages/TournamentSponsorsPage";
import { AddTournamentSponsorPage } from "./pages/AddTournamentSponsorPage";
import { EditTournamentSponsorPage } from "./pages/EditTournamentSponsorPage";

const TournamentToolsSection = () => (
  <Switch>
    <Route
      exact
      path="/organizer/tournament/:tournamentId/participants"
      component={TournamentParticipantsPage}
    />
    <Route
      exact
      path="/organizer/tournament/:tournamentId/transactions"
      component={TournamentTransactionsPage}
    />
    <Route
      exact
      path="/organizer/tournament/:tournamentId/scores"
      component={TournamentScoresPage}
    />
    <Route
      exact
      path="/organizer/tournament/:tournamentId/sponsors"
      component={TournamentSponsorsPage}
    />
    <Route
      exact
      path="/organizer/tournament/:tournamentId/sponsors/add"
      component={AddTournamentSponsorPage}
    />
    <Route
      exact
      path="/organizer/tournament/:tournamentId/sponsors/edit"
      component={EditTournamentSponsorPage}
    />
    <Route render={() => <Redirect to="/organizer/dashboard" />} />
  </Switch>
);

export default EnsureOrganizerLoggedIn(TournamentToolsSection);
