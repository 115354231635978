import { CancelToken } from "axios";
import { OrganizerApiClientAuto } from "api/ApiClient";

export async function reassignTournamentRegistrationTeam(
  tournamentId: number,
  registrationId: number,
  teamId: number,
  cancelToken?: CancelToken
): Promise<void> {
  await OrganizerApiClientAuto.request({
    method: "PATCH",
    url: `/organizer/organization/tournaments/${tournamentId}/registrations/${registrationId}`,
    data: { team_id: teamId },
    cancelToken: cancelToken,
  });
}
