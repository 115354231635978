import React from "react";
import { Helmet } from "react-helmet";

interface PageDescriptionProps {
  description: string;
}

export function PageDescription({ description }: PageDescriptionProps) {
  return (
    <Helmet>
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
    </Helmet>
  );
}
