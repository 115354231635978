export function formatGolferName(golfer: {
  first_name: string;
  last_name: string;
}): string {
  const name = [golfer.first_name, golfer.last_name].filter(Boolean).join(" ");
  if (name) {
    return name;
  } else {
    return "<unknown golfer>";
  }
}
