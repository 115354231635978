import React from "react";
import { Paper, Box, Button, Typography } from "@material-ui/core";

interface ConfirmationPopperBodyProps {
  onConfirmClick: () => void;
  onCancelClick: () => void;
}

const ConfirmationPopperBody = React.forwardRef(function ConfirmationPopperBody(
  { onConfirmClick, onCancelClick }: ConfirmationPopperBodyProps,
  ref
) {
  return (
    <Paper ref={ref} elevation={3}>
      <Box p={3}>
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          A confirmation required
        </Typography>
        <Box pt={0.5} pb={2}>
          <Typography variant="body2">Are you sure?</Typography>
        </Box>
        <Box display="flex" flexDirection="row">
          <Button onClick={onConfirmClick}>Confirm</Button>
          <Button
            variant="text"
            onClick={onCancelClick}
            style={{ marginLeft: 15 }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Paper>
  );
});

export default ConfirmationPopperBody;
