import { AxiosResponse, CancelToken } from "axios";

import { Organization, OrganizationFormModel } from "./types";
import { convertOrganizationFormModelToFormData } from "./utils";
import { makeOrganizerApiClient } from "../../ApiClient";

export async function createOrganization(
  token: string | undefined,
  formModel: OrganizationFormModel,
  cancelToken?: CancelToken
): Promise<Organization> {
  const response = await makeOrganizerApiClient(token).request<
    void,
    AxiosResponse<{ organization: Organization }>
  >({
    method: "POST",
    url: "/organizer/organization",
    data: convertOrganizationFormModelToFormData(formModel),
    cancelToken: cancelToken,
  });
  return response.data.organization;
}
