import React, { useCallback } from "react";
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { CancelToken } from "axios";

import { EnsureGolferLoggedIn, useGolferSession } from "utils/golfer-session";
import {
  TournamentRegistration,
  SubmitScoreFormModel,
  submitTournamentRegistrationScore,
  parseTournamentRegistrationScoreErrors,
} from "api/golfer/tournament-registrations";
import { searchCourses } from "api/golfer/courses";
import { GolfCourse } from "api/types";
import { getApiErrorMessage } from "utils/network";

import SubmitScore from "./SubmitScore";
import {
  makeInitialFormModel,
  validateSubmitScoreModel,
} from "./SubmitScore.utils";
import {
  RequestToAddCourseDialog,
  useRequestToAddCourseDialogState,
} from "../../dialogs/RequestToAddCourseDialog";

interface SubmitScoreContainerProps {
  registration: TournamentRegistration;
  onNextStep: () => void;
}

function SubmitScoreContainer({
  registration,
  onNextStep,
}: SubmitScoreContainerProps) {
  const { token } = useGolferSession();

  const onSubmit = useCallback(
    async (formModel: SubmitScoreFormModel): Promise<any> => {
      try {
        await submitTournamentRegistrationScore(registration?.id!, formModel);
        onNextStep();
      } catch (error) {
        const errors = parseTournamentRegistrationScoreErrors(error);
        if (Object.keys(errors).length > 0) {
          return errors;
        } else {
          return { [FORM_ERROR]: getApiErrorMessage(error) };
        }
      }
    },
    [registration, onNextStep]
  );

  const searchCoursesWithToken = useCallback(
    (inputValue: string, cancelToken: CancelToken): Promise<GolfCourse[]> => {
      return searchCourses(token!, inputValue, cancelToken);
    },
    [token]
  );

  const [dialogProps, openDialog] = useRequestToAddCourseDialogState();

  return (
    <>
      <Form<SubmitScoreFormModel>
        onSubmit={onSubmit}
        initialValues={makeInitialFormModel(registration)}
        validate={validateSubmitScoreModel}
        validateOnBlur
      >
        {({ handleSubmit, submitting, submitError }) => (
          <SubmitScore
            handleSubmit={handleSubmit}
            submitting={submitting}
            submitError={submitError}
            searchCourses={searchCoursesWithToken}
            onAddOwnCourse={openDialog}
          />
        )}
      </Form>
      <RequestToAddCourseDialog {...dialogProps} />
    </>
  );
}

export default EnsureGolferLoggedIn(SubmitScoreContainer);
