import React from "react";
import { useParams } from "react-router-dom";

import { useTournamentById } from "api/organizer/tournaments";
import { useOrganizerTournamentRegistrations } from "api/organizer/tournament-registrations";
import { EnsureOrganizerLoggedIn } from "utils/organizer-session";

import { sortTournamentRegistrations } from "./TournamentTransactionsPage.utils";
import TournamentTransactionsPage from "./TournamentTransactionsPage";

function TournamentTransactionsPageContainer() {
  const { tournamentId } = useParams<{ tournamentId: string }>();

  const { tournament } = useTournamentById(parseInt(tournamentId));
  const { registrations, isInitialized } = useOrganizerTournamentRegistrations(
    parseInt(tournamentId)
  );

  return (
    <TournamentTransactionsPage
      tournament={tournament}
      isInitialized={isInitialized}
      registrations={sortTournamentRegistrations(registrations)}
    />
  );
}

export default EnsureOrganizerLoggedIn(TournamentTransactionsPageContainer);
