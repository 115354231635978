import React, { useMemo } from "react";
import { Box, Grid } from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";

import { TextSkeleton } from "components/core/Skeleton";

export default function TeamEntrantRowSkeleton() {
  const teamSize = useMemo<number>(() => Math.floor(Math.random() * 2) + 2, []);

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={6} sm={4} md={3}>
        <Box display="flex" alignItems="center">
          <GroupIcon style={{ cursor: "help" }} color="primary" />
          <Box ml={1} flexGrow={1}>
            <TextSkeleton variant="h6" width="60%" />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        {Array(teamSize)
          .fill(0)
          .map((x, i) => (
            <TextSkeleton key={i} variant="body1" width="60%" />
          ))}
      </Grid>
    </Grid>
  );
}
