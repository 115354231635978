import { makeStyles, lighten } from "@material-ui/core";

export const useRegistrationRowStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    border: "2px solid #c3cfd9",
    padding: theme.spacing(1.5),
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(3),
    },
  },
  errored: {
    borderColor: theme.palette.error.main,
  },
  inner: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
      marginRight: theme.spacing(3),
    },
  },
  avatarWrapper: {
    flexShrink: 0,
    marginRight: theme.spacing(1.5),
    width: 82,
    height: 82,
    boxShadow: "0px 0px 8px 3px rgba(200,200,200,0.3)",
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(3),
    },
  },
  avatar: {
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
    color: theme.palette.grey[300],
    fontWeight: 500,
    fontSize: "2rem",
  },
  paymentErrorButton: {
    backgroundColor: "rgb(253, 226, 224)",
    color: "rgb(97, 26, 21)",
    "&:hover": {
      backgroundColor: lighten("rgb(253, 226, 224)", 0.1),
    },
  },
}));
