import { CancelToken } from "axios";

import { makeOrganizerApiClient } from "../../ApiClient";
import { OrganizerTournamentRegistration } from "./OrganizerTournamentRegistration.types";

export async function getOrganizerTournamentRegistrations(
  token: string,
  tournamentId: number,
  cancelToken?: CancelToken
): Promise<OrganizerTournamentRegistration[]> {
  const response = await makeOrganizerApiClient(token).request<{
    tournament_registrations: OrganizerTournamentRegistration[];
  }>({
    method: "GET",
    url: `/organizer/organization/tournaments/${tournamentId}/registrations`,
    params: {
      "page[number]": 1,
      "page[size]": 100,
    },
    cancelToken: cancelToken,
  });
  return response.data.tournament_registrations;
}
