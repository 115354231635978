import * as yup from "yup";
import { setIn } from "final-form";

export function makeValidator<
  M extends object,
  E extends object,
  S extends yup.ObjectSchema = yup.ObjectSchema<Partial<M>>
>(schema: S) {
  return async function validate(model: M): Promise<E | undefined> {
    try {
      await schema.validate(model, {
        abortEarly: false,
      });
    } catch (err) {
      const errors: E = err.inner.reduce((formError: any, innerError: any) => {
        return setIn(formError, innerError.path, innerError.message);
      }, {});

      return errors;
    }
  };
}
