import React from "react";
import { Typography, Fade, Collapse } from "@material-ui/core";

import { InfoBox, InfoBoxProps } from "components/core/InfoBox";

import {
  RegistrationCost,
  formatPromoCodeInfo,
} from "./RegistrationCostBox.utils";

interface RegistrationCostBoxProps extends InfoBoxProps {
  cost: RegistrationCost;
}

export default function RegistrationCostBox({
  cost: {
    base_fee,
    mulligan_fee,
    promo_code_info,
    total,
    number_of_additional_slots,
    single_slot_total,
  },
  ...restProps
}: RegistrationCostBoxProps) {
  return (
    <InfoBox {...restProps}>
      <Typography variant="body1">
        Registration Fee: {base_fee.format()}
        <Fade in={Boolean(mulligan_fee)}>
          <span>
            {" "}
            + Mulligan: {mulligan_fee ? mulligan_fee.format() : undefined}{" "}
            (thank you)
          </span>
        </Fade>
      </Typography>
      <br />
      <Collapse in={number_of_additional_slots !== 0}>
        <Typography variant="body1">
          Your Registration Cost: {single_slot_total.format()}
        </Typography>
        <br />
        <Typography variant="body1">
          Additional pre-paid Slots: {single_slot_total.format()}{" "}
          <b>x{number_of_additional_slots}</b>
        </Typography>
        <br />
      </Collapse>
      <Collapse in={Boolean(promo_code_info)}>
        <Typography variant="body1">
          Promo Code <b>{promo_code_info?.code}</b>:{" "}
          {promo_code_info ? formatPromoCodeInfo(promo_code_info) : undefined}
        </Typography>
        <br />
      </Collapse>
      <Typography variant="body1">
        Total Registraton Cost: {total.format()}
      </Typography>
    </InfoBox>
  );
}
