import currency from "currency.js";
import { setIn } from "final-form";
import * as yup from "yup";
import { addDays, parseISO } from "date-fns";
import memoizeOne from "memoize-one";

import {
  TournamentErrors,
  TournamentFormModel,
} from "api/organizer/tournaments";
import { TournamentFormat, Tournament } from "api/types";
import { createRemoteImageValue } from "components/core/ImageInput";
import { Organization } from "api/organizer/organization";
import { ImageInputValue } from "components/core/ImageInput/ImageInput.utils";

export function formatRegistrationFeeDeducted(
  fee: string | undefined,
  organization: Organization | undefined
): string {
  if (!fee || !organization) {
    return "-";
  }
  return currency(fee)
    .divide(100)
    .multiply(100 - organization.registration_commission)
    .format();
}

export const makeInitialFormModel = memoizeOne(function makeInitialFormModel(
  tournament: Tournament | undefined
): TournamentFormModel {
  return {
    name: tournament?.name ?? "",
    course: tournament?.course ?? undefined,
    form: tournament?.form ?? TournamentFormat.Scramble,
    start_date: tournament
      ? parseISO(tournament.start_date)
      : addDays(new Date(), 1),
    end_date: tournament
      ? parseISO(tournament.end_date)
      : addDays(new Date(), 31),
    details: tournament?.details ?? "",
    prizes: tournament?.prizes ?? "",
    mulligan: tournament?.mulligan ?? false,
    mulligan_price: tournament?.mulligan_price,
    registration_fee: tournament?.registration_fee,
    image: tournament?.image
      ? createRemoteImageValue(tournament.image.medium)
      : undefined,
    offering_tshirt: tournament?.offering_tshirt ?? false,
  };
});

const CreateTournamentSchema: yup.ObjectSchema<Partial<
  TournamentFormModel
>> = yup
  .object({
    mulligan_price: yup.string().when("mulligan", {
      is: true,
      then: yup.string().when("registration_fee", {
        is: (fee: string) => currency(fee).intValue >= currency(100).intValue,
        then: yup
          .string()
          .test(
            "large-mulligan-price",
            `must be ${currency(50).format()} or less`,
            (mulligan_price) =>
              currency(mulligan_price ?? 0).intValue <= currency(50).intValue
          )
          .required(),
        otherwise: yup
          .string()
          .test(
            "small-mulligan-price",
            `must be ${currency(20).format()} or less`,
            (mulligan_price) =>
              currency(mulligan_price ?? 0).intValue <= currency(20).intValue
          )
          .required(),
      }),
    }),
    image: yup.object<ImageInputValue>().required("image is required"),
  })
  .defined();

export async function validateTournamentFormModel(
  formModel: TournamentFormModel
): Promise<TournamentErrors | undefined> {
  try {
    await CreateTournamentSchema.validate(formModel, {
      abortEarly: false,
    });
  } catch (err) {
    const errors: TournamentErrors = err.inner.reduce(
      (formError: any, innerError: any) => {
        return setIn(formError, innerError.path, innerError.message);
      },
      {}
    );
    return errors;
  }
}

export function getTournamentFormatHelperText(
  format: TournamentFormat
): string | undefined {
  if (format === TournamentFormat.Scramble) {
    return "Most popular fundraiser format appeals to novice and experienced golfers and will help you expand your reach as participants are incentivized to invite players outside your current network to join their team. Teams of 1-4 players play together and each player hits from the spot of the best outcome of the Team’s previous shots taken until one player hits the ball in the hole.";
  } else if (format === TournamentFormat.BestBall) {
    return "Teams of 2-4 players play together and each player plays their own ball the entire round and the best score achieved by the Team on each hole is recorded as the Team’s score on each hole (i.e. on hole #1 Player A scores a 5, Player B scores a 4 and Player C scores a 6, the Team would record a score of 4 on hole #1).";
  }

  return undefined;
}
