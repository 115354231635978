import React from "react";
import { Box, BoxProps } from "@material-ui/core";
import clsx from "clsx";
import { useFormRowStyles } from "./useFormRowStyles";

export interface BasicFormRowProps extends BoxProps {}

export function BasicFormRow({
  className,
  children,
  ...restProps
}: BasicFormRowProps) {
  const classes = useFormRowStyles();

  return (
    <Box className={clsx(classes.basicFormRow, className)} {...restProps}>
      {children}
    </Box>
  );
}
