import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import {
  SessionService,
  SessionServiceState,
  SessionServiceStatus,
  createAuthTokenStorage,
  createSessionService,
} from "utils/session";
import { Organizer, getOrganizerByToken } from "api/organizer/account";

export interface OrganizerSessionService extends SessionService<Organizer> {}
export interface OrganizerSessionServiceState
  extends SessionServiceState<Organizer> {}

interface OrganizerSessionServiceHookProxy
  extends OrganizerSessionServiceState,
    Pick<OrganizerSessionService, "logIn" | "logOut" | "updateUser"> {}

const SessionContext = createContext<OrganizerSessionServiceHookProxy>({
  status: SessionServiceStatus.Initial,
  token: undefined,
  user: undefined,
  logIn: () => undefined,
  logOut: () => undefined,
  updateUser: () => undefined,
});

interface OrganizerSessionProviderProps {
  sessionService: OrganizerSessionService;
  children: React.ReactNode;
}

export function OrganizerSessionProvider({
  sessionService,
  children,
}: OrganizerSessionProviderProps) {
  const [sessionState, setSessionState] = useState<
    OrganizerSessionServiceState
  >(sessionService.getState());

  const sessionProxy: OrganizerSessionServiceHookProxy = useMemo(
    () => ({
      status: sessionState.status,
      token: sessionState.token,
      user: sessionState.user,
      logIn: sessionService.logIn,
      logOut: sessionService.logOut,
      updateUser: sessionService.updateUser,
    }),
    [sessionState, sessionService]
  );

  const handleSessionStorageStateChange = useCallback((newSessionState) => {
    setSessionState(newSessionState);
  }, []);

  useEffect(() => {
    setSessionState(sessionService.getState());
    sessionService.addChangeListener(handleSessionStorageStateChange);
    return () =>
      sessionService.removeChangeListener(handleSessionStorageStateChange);
  }, [sessionService, handleSessionStorageStateChange]);

  return (
    <SessionContext.Provider value={sessionProxy}>
      {children}
    </SessionContext.Provider>
  );
}

export function useOrganizerSession() {
  return useContext(SessionContext);
}

export function createOrganizerSessionService(): OrganizerSessionService {
  return createSessionService<Organizer>(
    createAuthTokenStorage("flex-golf-fundraisers-organizer-token"),
    getOrganizerByToken
  );
}
