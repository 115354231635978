import React from "react";
import {
  Box,
  Button,
  InputLabel,
  Typography,
  Grid,
  Collapse,
  Link,
} from "@material-ui/core";

import { AppConfig } from "config/AppConfig";
import { Alert } from "components/core/Alert";
import { FormRowImageInput } from "components/core/FormRow";

interface UploadPictureProps {
  handleSubmit: (...args: any[]) => void;
  submitting: boolean;
  submitError: string | undefined;
  onSkip: () => void;
}

export default function UploadPicture({
  handleSubmit,
  submitting,
  submitError,
  onSkip,
}: UploadPictureProps) {
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h5">
            Congratulations on completing your round!
          </Typography>
          <Typography variant="h5">
            Send us a photo of you on the Golf Course!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <InputLabel htmlFor="registration-photo">
            (This will be displayed on the leaderboard, and also show that
            you've played the round!)
          </InputLabel>
          <Box pt={0.5}>
            <FormRowImageInput
              id="registration-photo"
              name="photo"
              aspectRatio={1}
              disabled={submitting}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={Boolean(submitError)}>
            <Alert>
              <Typography variant="body1">{submitError}</Typography>
            </Alert>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Button type="submit" disabled={submitting}>
              Next
            </Button>
            <Link
              variant="body1"
              underline="always"
              style={{ marginLeft: 16 }}
              component="button"
              disabled={submitting}
              onClick={onSkip}
            >
              Skip
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Typography variant="body2" style={{ fontStyle: "italic" }}>
            Remember: <br />
            {AppConfig.Application.Name} are for charity and fun!
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
}
