import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Typography,
  Box,
  Button,
  Divider,
  Collapse,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Field, useField } from "react-final-form";

import { OrganizerPage } from "components/layout";
import { Alert } from "components/core/Alert";
import {
  BasicFormRow,
  FormRow,
  FormRowInputLabel,
  FormRowOutlinedInput,
} from "components/core/FormRow";
import { getLocationWithNext } from "utils/useNextLocation";
import { InfoBox } from "components/core/InfoBox";
import { TermsContent } from "components/content-pages";

import {
  StartFundraiserHeader,
  StartFundraiserWizardSteps,
} from "../StartFundraiserWizard";

interface CreateOrganizerAccountPageProps {
  handleSubmit: (...args: any[]) => void;
  submitting: boolean;
  submitError: string | undefined;
}

export default function CreateOrganizerAccountPage({
  handleSubmit,
  submitting,
  submitError,
}: CreateOrganizerAccountPageProps) {
  const {
    input: { value: tos_accepted },
  } = useField<boolean>("tos_accepted");

  return (
    <OrganizerPage>
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <StartFundraiserHeader
          {...StartFundraiserWizardSteps.CreateAccount}
          action={
            <Button
              size="small"
              component={RouterLink}
              to={getLocationWithNext(
                "/organizer/login",
                "/organizer/update-organization"
              )}
            >
              I already have an organizer account
            </Button>
          }
        />
        <FormRow withLabel={<FormRowInputLabel>Name</FormRowInputLabel>}>
          <FormRowOutlinedInput
            name="first_name"
            type="text"
            placeholder="John"
          />
          <FormRowOutlinedInput
            name="last_name"
            type="text"
            placeholder="Doe"
          />
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="organizer-email">
              Email
            </FormRowInputLabel>
          }
        >
          <FormRowOutlinedInput
            id="organizer-email"
            name="email"
            type="email"
            placeholder="john.doe@example.com"
          />
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="organizer-phone">
              Phone
            </FormRowInputLabel>
          }
        >
          <FormRowOutlinedInput
            id="organizer-phone"
            name="phone"
            type="tel"
            placeholder="555-555-5555"
          />
        </FormRow>
        <BasicFormRow>
          <Divider />
        </BasicFormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="organizer-phone">
              Password
            </FormRowInputLabel>
          }
        >
          <FormRowOutlinedInput
            id="organizer-password"
            name="password"
            type="password"
            autoComplete="new-password"
          />
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="organizer-confirm-password">
              Confirm Password
            </FormRowInputLabel>
          }
        >
          <FormRowOutlinedInput
            id="organizer-confirm-password"
            name="password_confirmation"
            type="password"
          />
        </FormRow>
        <FormRow>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
              width: "100%",
            }}
          >
            <Field<boolean> type="checkbox" name="tos_accepted">
              {({ input }) => (
                <FormControlLabel
                  control={<Checkbox {...input} color="primary" />}
                  label="By signing up I agree to the terms and conditions"
                  style={{ marginRight: 50 }}
                />
              )}
            </Field>
            <InfoBox maxHeight={300}>
              <Typography
                variant="body1"
                component={Box}
                dangerouslySetInnerHTML={{ __html: TermsContent }}
              />
            </InfoBox>
          </Box>
        </FormRow>
        <Collapse in={Boolean(submitError)}>
          <FormRow>
            <Alert fullWidth>
              <Typography variant="body1">{submitError}</Typography>
            </Alert>
          </FormRow>
        </Collapse>
        <FormRow>
          <Button type="submit" disabled={submitting || !tos_accepted}>
            Create Account
          </Button>
        </FormRow>
      </form>
    </OrganizerPage>
  );
}
