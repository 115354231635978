import React, { useCallback } from "react";
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";

import { EnsureGolferLoggedIn } from "utils/golfer-session";
import {
  TournamentRegistration,
  uploadTournamentRegistrationPhoto,
} from "api/golfer/tournament-registrations";
import { getApiErrorMessage } from "utils/network";
import { isLocalImageValue } from "components/core/ImageInput";

import UploadPicture from "./UploadPicture";
import {
  UploadPictureFormModel,
  validateUploadPictureFormModel,
} from "./UploadPicture.utils";

interface UploadPictureContainerProps {
  registration: TournamentRegistration;
  onNextStep: () => void;
}

function UploadPictureContainer({
  registration,
  onNextStep,
}: UploadPictureContainerProps) {
  const onSubmit = useCallback(
    async (formModel: UploadPictureFormModel): Promise<any> => {
      if (!formModel.photo) {
        return {
          [FORM_ERROR]:
            "You have to choose a course you have played your round at",
        };
      }
      try {
        if (formModel.photo && isLocalImageValue(formModel.photo)) {
          await uploadTournamentRegistrationPhoto(
            registration.id,
            formModel.photo.file
          );
        }
        onNextStep();
      } catch (error) {
        return { [FORM_ERROR]: getApiErrorMessage(error) };
      }
    },
    [registration, onNextStep]
  );

  return (
    <Form<UploadPictureFormModel>
      onSubmit={onSubmit}
      initialValues={{ photo: undefined }}
      validate={validateUploadPictureFormModel}
      validateOnBlur={false}
    >
      {({ handleSubmit, submitting, submitError }) => (
        <UploadPicture
          handleSubmit={handleSubmit}
          submitting={submitting}
          submitError={submitError}
          onSkip={onNextStep}
        />
      )}
    </Form>
  );
}

export default EnsureGolferLoggedIn(UploadPictureContainer);
