import React, { useCallback, useState } from "react";
import memoizeOne from "memoize-one";
import { Form } from "react-final-form";
import { useSnackbar } from "notistack";

import { RegistrationScoreEntry } from "../../TournamentScoresPage.utils";
import RegistrationEntryTableRow from "./RegistrationEntryTableRow";
import {
  OrganizerTournamentRegistration,
  UpdateScoreFormModel,
  updateTournamentRegistrationScore,
  getOrganizerTournamentRegistrationById,
} from "api/organizer/tournament-registrations";
import { getApiErrorMessage } from "utils/network";

interface RegistrationEntryTableRowProps {
  entry: RegistrationScoreEntry;
  onRegistrationUpdated: (
    updatedRegistration: OrganizerTournamentRegistration
  ) => void;
}

const makeInitialFormModel = memoizeOne(function makeInitialFormModel(
  registration?: OrganizerTournamentRegistration
): UpdateScoreFormModel {
  return {
    score: registration?.score ? String(registration.score) : undefined,
    course: registration?.course,
    tee_id: registration?.tee_id ?? undefined,
  };
});

export default function RegistrationEntryTableRowContainer({
  entry,
  onRegistrationUpdated,
}: RegistrationEntryTableRowProps) {
  const [isEditing, setEditing] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback(
    async (formModel: UpdateScoreFormModel) => {
      try {
        const registration =
          entry.score_registration ?? entry.main_registration;
        await updateTournamentRegistrationScore(
          registration.tournament_id,
          registration.id,
          formModel
        );
        const updatedRegistration = await getOrganizerTournamentRegistrationById(
          registration.tournament_id,
          registration.id
        );
        setEditing(false);
        onRegistrationUpdated(updatedRegistration);
      } catch (err) {
        enqueueSnackbar(getApiErrorMessage(err), { variant: "error" });
      }
    },
    [onRegistrationUpdated, enqueueSnackbar, entry]
  );

  return (
    <Form<UpdateScoreFormModel>
      initialValues={makeInitialFormModel(entry.score_registration)}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, submitting, form: { reset } }) => (
        <RegistrationEntryTableRow
          handleSubmit={handleSubmit}
          submitting={submitting}
          entry={entry}
          isEditing={isEditing}
          onEdit={() => setEditing(true)}
          onCancel={() => {
            setEditing(false);
            reset();
          }}
        />
      )}
    </Form>
  );
}
