import { AxiosResponse, CancelToken } from "axios";
import { BaseApiClient } from "../ApiClient";

export interface Country {
  id: number;
  name: string;
  alpha2: string;
  alpha3: string;
  number: number;
}

export async function getCountries(
  cancelToken?: CancelToken
): Promise<Country[]> {
  const response = await BaseApiClient.request<
    void,
    AxiosResponse<{ countries: Country[] }>
  >({
    method: "GET",
    url: "/guest/countries",
    cancelToken: cancelToken,
  });

  return response.data.countries;
}

export async function getUnitedStates(
  cancelToken: CancelToken
): Promise<Country> {
  const countries = await getCountries(cancelToken);
  const us = countries.find((c) => c.alpha2 === "US");
  if (!us) {
    throw Error("can't get United States");
  }
  return us;
}
