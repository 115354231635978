import { makeStyles } from "@material-ui/core";

export const useTournamentRowStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    border: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1.5),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
    },
  },
  topPanel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  inner: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.up("md")]: {
      flexShrink: 1,
      minWidth: 0,
      marginBottom: 0,
      marginRight: theme.spacing(3),
    },
  },
  avatarWrapper: {
    flexShrink: 0,
    marginRight: theme.spacing(1.5),
    width: 82,
    height: 82,
    boxShadow: "0px 0px 8px 3px rgba(200,200,200,0.3)",
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(3),
    },
  },
  avatar: {
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
    color: theme.palette.grey[300],
    fontWeight: 500,
    fontSize: "2rem",
  },
  details: {
    flexShrink: 1,
    minWidth: 0,
  },
  tag: {
    marginLeft: theme.spacing(1),
  },
  draftTag: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.info.contrastText,
  },
  actions: {
    alignSelf: "center",
    flexShrink: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  metrics: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "stretch",
      justifyContent: "space-around",
      "& > * + *": {
        marginTop: 0,
        marginLeft: theme.spacing(2),
      },
    },
  },
  metric: {
    width: "100%",
    padding: theme.spacing(3, 1),
    textAlign: "center",
    border: `2px solid ${theme.palette.divider}`,
  },
}));
