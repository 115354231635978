import React, { useCallback, useRef } from "react";
import { Box, Button, Avatar, makeStyles, Typography } from "@material-ui/core";

import {
  ImageInputValue,
  LocalImage,
  isLocalImageValue,
  isRemoteImageValue,
  createLocalImageValue,
} from "./ImageInput.utils";
import {
  CropperDialog,
  useCropperDialogState,
} from "./components/CropperDialog";
import { CreateObjectURL } from "./components/CreateObjectURL";

interface ImageInputProps {
  id: string;
  value: ImageInputValue | undefined;
  onChange: (nextValue: LocalImage | undefined) => void;
  disabled?: boolean;
  aspectRatio: number;
}

export default function ImageInput({
  id,
  value,
  disabled = false,
  onChange,
  aspectRatio,
}: ImageInputProps) {
  const classes = useStyles({ aspectRatio });

  const [dialogProps, openDialog] = useCropperDialogState();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      if (
        evt &&
        evt.target &&
        evt.target.files &&
        evt.target.files.length > 0
      ) {
        const file = evt.target.files[0];
        const reader = new FileReader();
        reader.addEventListener("load", () =>
          openDialog({
            asDataUrl: reader.result as string,
            fileName: file.name,
          })
        );
        reader.readAsDataURL(file);
      }
    },
    [openDialog]
  );

  return (
    <Box>
      <input
        id={id}
        ref={inputRef}
        type="file"
        accept="image/jpeg,image/jpg,image/png"
        onChange={handleChange}
        disabled={disabled}
        style={{
          width: 0,
          height: 0,
          visibility: "hidden",
        }}
      />
      <Button
        component="label"
        htmlFor={id}
        size="small"
        className={classes.button}
        disabled={disabled}
      >
        Upload
      </Button>
      {value && isRemoteImageValue(value) && (
        <Avatar
          className={classes.preview}
          variant="square"
          src={value.src}
          alt="Preview"
        />
      )}
      {value && isLocalImageValue(value) && (
        <CreateObjectURL file={value.file}>
          {(url) => (
            <>
              <Avatar
                className={classes.preview}
                variant="square"
                src={url}
                alt="Preview"
              />
              <Typography variant="caption">{value.file.name}</Typography>
            </>
          )}
        </CreateObjectURL>
      )}
      <CropperDialog
        {...dialogProps}
        aspectRatio={aspectRatio}
        onCrop={(img) => onChange(createLocalImageValue(img))}
      />
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  button: {
    display: "inline-block",
    verticalAlign: "text-bottom",
    height: 30,
  },
  preview: {
    display: "inline-block",
    verticalAlign: "text-bottom",
    width: ({ aspectRatio }: { aspectRatio: number }) => 30 * aspectRatio,
    height: 30,
    margin: theme.spacing(0, 1),
    boxShadow: "0px 0px 8px 3px rgba(200,200,200,0.3)",
  },
}));
