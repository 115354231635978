import useSWR from "swr";
import axios from "axios";

import { GuestTournament } from "../getTournamentsBySlug";
import { getFeaturedTournaments } from "../getFeaturedTournaments";

interface UseFeaturedTournamentsResult {
  tournaments: GuestTournament[] | undefined;
  error: Error | undefined;
}

export function useFeaturedTournaments(): UseFeaturedTournamentsResult {
  const { data: tournaments, error } = useSWR(
    "featured-tournaments",
    (): Promise<GuestTournament[]> => {
      const cancelSource = axios.CancelToken.source();
      const promise = getFeaturedTournaments(cancelSource.token);
      (promise as any).cancel = () =>
        cancelSource.cancel("request was cancelled");
      return promise;
    }
  );

  return { tournaments, error };
}
