import React from "react";
import { Box, BoxProps } from "@material-ui/core";

interface PageProps extends BoxProps {
  children: React.ReactNode;
}

export function Page({ children, ...restProps }: PageProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      minHeight="100vh"
      width="100%"
      {...restProps}
    >
      {children}
    </Box>
  );
}
