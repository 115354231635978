import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { LoginOrganizerPage } from "./pages/LoginOrganizerPage";
import { OrganizerForgotPasswordPage } from "./pages/OrganizerForgotPasswordPage";
import { OrganizerResetPasswordPage } from "./pages/OrganizerResetPasswordPage";
import { CreateOrganizerAccountPage } from "./pages/CreateOrganizerAccountPage";
import { UpsertOrganizationPage } from "./pages/UpsertOrganizationPage";
import { StartFundraiserWizard } from "./pages/StartFundraiserWizard";
import { OrganizerDashboardPage } from "./pages/OrganizerDashboardPage";
import { TournamentToolsSection } from "./pages/TournamentToolsSection";
import { EditPublishedTournamentPage } from "./pages/EditPublishedTournamentPage";

const OrganizerSection = () => (
  <Switch>
    <Route exact path="/organizer/login" component={LoginOrganizerPage} />
    <Route
      exact
      path="/organizer/forgot-password"
      component={OrganizerForgotPasswordPage}
    />
    <Route
      exact
      path="/organizer/reset-password/:token"
      component={OrganizerResetPasswordPage}
    />
    <Route
      exact
      path="/organizer/create-account"
      component={CreateOrganizerAccountPage}
    />
    <Route
      exact
      path="/organizer/update-organization"
      component={UpsertOrganizationPage}
    />
    <Route
      path="/organizer/start-fundraiser"
      component={StartFundraiserWizard}
    />
    <Route
      exact
      path="/organizer/edit-tournament/:tournamentId"
      component={EditPublishedTournamentPage}
    />
    <Route
      exact
      path="/organizer/dashboard"
      component={OrganizerDashboardPage}
    />
    <Route
      path="/organizer/tournament/:tournamentId"
      component={TournamentToolsSection}
    />
    <Route render={() => <Redirect to="/organizer/dashboard" />} />
  </Switch>
);

export default OrganizerSection;
