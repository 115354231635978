import { AxiosResponse, CancelToken } from "axios";
import { BaseApiClient } from "../ApiClient";

export interface CountryState {
  id: number;
  country_id: number;
  name: string;
  code: string;
}

export async function getCountryStates(
  countryId: number,
  cancelToken?: CancelToken
): Promise<CountryState[]> {
  const response = await BaseApiClient.request<
    void,
    AxiosResponse<{ states: CountryState[] }>
  >({
    method: "GET",
    url: `/guest/countries/${countryId}/states`,
    cancelToken: cancelToken,
  });

  return response.data.states;
}
