import FlexGolfOG from "./FlexGolfOG.png";

export const AppConfig = {
  Application: {
    Id: "flex-golf-fundrisers",
    Name: "Flex Golf Fundraisers",
    ShortName: "Flex Golf",
    // NOTE: 160 characters max
    Description:
      "Raise Money NOW with innovative golf tournaments that require NO GATHERING",
    // NOTE: 1200x627
    Image: FlexGolfOG,
  },
  Api: {
    BaseUrl: process.env.REACT_APP_API_BASE_URL ?? "",
  },
  Contacts: {
    AddressLine1: "999 Corporate Dr. Suite 100",
    AddressLine2: "Ladera Ranch, CA 92694",
    Phone: "949-329-3809",
    Email: "fundraisers@flexgolftour.com",
    SocialNetworks: {
      Instagram: "https://www.instagram.com/flexgolffundraisers",
      Facebook: "https://www.facebook.com/FlexGolfFundraisers",
      Linkedin: "https://www.linkedin.com/company/flexgolffundraisers",
    },
  },
  Integrations: {
    GoogleTagManager: {
      GtmID: "GTM-53NZMSQ",
    },
    Stripe: {
      Token: process.env.REACT_APP_STRIPE_TOKEN ?? "",
    },
  },
};
