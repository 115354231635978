import { CancelToken } from "axios";
import { OrganizerApiClientAuto } from "api/ApiClient";

export enum TournamentRegistrationsExportType {
  Registrations = "registrations",
  Transactions = "transactions",
  Scores = "scores",
}

export async function exportTournamentRegistrations(
  tournamentId: number,
  kind: TournamentRegistrationsExportType,
  cancelToken?: CancelToken
): Promise<Blob> {
  const response = await OrganizerApiClientAuto.request<Blob>({
    method: "GET",
    url: `/organizer/organization/tournaments/${tournamentId}/registrations.csv`,
    params: { kind },
    cancelToken: cancelToken,
    responseType: "blob",
  });
  return response.data;
}
