import React from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Box,
  Button,
  makeStyles,
  Link,
  Grid,
  Typography,
} from "@material-ui/core";

import { SmartPage } from "components/layout";
import {
  useTournamentBySlug,
  useGuestTournamentRegistrations,
} from "api/guest/tournaments/hooks";
import { TextSkeleton } from "components/core/Skeleton";
import {
  groupTournamentEntrants,
  isTeamEntrant,
  isIndividualEntrant,
} from "utils/tournament/leaderboard";
import { isTournamentRegistrationAvailable } from "utils/tournament";
import { PageTitle, PageDescription } from "components/core/Helmets";

import {
  TeamEntrantRow,
  TeamEntrantRowSkeleton,
} from "./components/TeamEntrantRow";
import {
  IndividualEntrantRow,
  IndividualEntrantRowSkeleton,
} from "./components/IndividualEntrantRow";

export default function TournamentEntryListPage() {
  const classes = useStyles();

  const { slug } = useParams<{ slug: string }>();
  const { tournament } = useTournamentBySlug(slug);

  const { registrations, isInitialized } = useGuestTournamentRegistrations(
    slug
  );

  const entrants = groupTournamentEntrants(registrations);

  return (
    <SmartPage>
      {tournament && (
        <>
          <PageTitle title={`${tournament.name} - Entry List`} />
          <PageDescription description={tournament.details.slice(0, 160)} />
          <Helmet>
            <meta property="og:image" content={tournament.image?.large} />
          </Helmet>
        </>
      )}
      <Grid container spacing={8} alignItems="center">
        <Grid item xs={12}>
          <Box className={classes.tournamentName}>
            {tournament ? (
              <Typography variant="h2" component="h1">
                <Link
                  color="inherit"
                  underline="none"
                  component={RouterLink}
                  to={`/tournament/${tournament.slug}`}
                >
                  {tournament.name}
                </Link>{" "}
                &mdash; Entry List
              </Typography>
            ) : (
              <TextSkeleton variant="h2" width="65%" />
            )}
          </Box>
        </Grid>
        {entrants.map((entrant) => {
          if (isTeamEntrant(entrant)) {
            return (
              <Grid item xs={12} key={entrant.id}>
                <TeamEntrantRow entrant={entrant} />
              </Grid>
            );
          } else if (isIndividualEntrant(entrant)) {
            return (
              <Grid item xs={12} key={entrant.id}>
                <IndividualEntrantRow entrant={entrant} />
              </Grid>
            );
          } else {
            return null;
          }
        })}
        {!isInitialized &&
          entrants.length === 0 &&
          [
            TeamEntrantRowSkeleton,
            IndividualEntrantRowSkeleton,
            TeamEntrantRowSkeleton,
            TeamEntrantRowSkeleton,
            IndividualEntrantRowSkeleton,
          ].map((Component, i) => (
            <Grid item xs={12} key={i}>
              <Component />
            </Grid>
          ))}
        {isInitialized && entrants.length === 0 && (
          <Grid item xs={12}>
            <Box py={4}>
              <Typography color="textSecondary" align="center">
                Nobody has joined <b>{tournament?.name}</b> yet
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
      {isTournamentRegistrationAvailable(tournament) && (
        <Box my={4}>
          <Button
            component={RouterLink}
            to={`/tournament/${tournament?.slug}/register`}
          >
            Register
          </Button>
        </Box>
      )}
    </SmartPage>
  );
}

const useStyles = makeStyles((theme) => ({
  tournamentName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
      textAlign: "left",
    },
  },
}));
