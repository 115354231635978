import memoizeOne from "memoize-one";

import { GuestTournamentRegistration } from "api/guest/tournaments";

import { TournamentFinisher } from "./TournamentEntrant.types";

export const groupTournamentFinishers = memoizeOne(
  function groupTournamentFinishers(
    registrations: GuestTournamentRegistration[]
  ): TournamentFinisher[] {
    const teamRegistrations: Record<number, GuestTournamentRegistration[]> = {};
    const individualRegistrations: GuestTournamentRegistration[] = [];
    registrations.forEach((registration) => {
      if (registration.team_id) {
        if (!teamRegistrations[registration.team_id]) {
          teamRegistrations[registration.team_id] = [];
        }
        teamRegistrations[registration.team_id].push(registration);
      } else {
        individualRegistrations.push(registration);
      }
    });

    const finishers: TournamentFinisher[] = [];

    Object.entries(teamRegistrations).forEach(([team_id, registrations]) => {
      // TODO: Correctly detect leader registration
      // const leader_registration = registrations.find((reg) => reg.golfer_id === reg.team.golfer_id);
      const leader_registration = registrations[0];
      const score_registration = registrations.find((reg) => reg.score != null);
      if (leader_registration && score_registration) {
        finishers.push({
          kind: "team",
          id: `team-${team_id}-${leader_registration.id}`,
          leader_registration,
          score_registration,
          registrations,
        });
      }
    });

    individualRegistrations.forEach((registration) => {
      if (registration.score != null) {
        finishers.push({
          kind: "individual",
          id: `golfer-${registration.golfer_id}-${registration.id}`,
          registration,
        });
      }
    });

    return finishers;
  }
);
