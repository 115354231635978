import { CancelToken } from "axios";

import { makeGolferApiClient, GolferApiClientAuto } from "../../ApiClient";
import { Address } from "../../types";

export interface Golfer {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  address: Address;
}

export async function getGolferByToken(
  token: string,
  cancelToken?: CancelToken
): Promise<{ user: Golfer; token: string }> {
  const response = await makeGolferApiClient(token).request<{ golfer: Golfer }>(
    {
      method: "GET",
      url: "/golfer/user",
      cancelToken: cancelToken,
    }
  );

  return { user: response.data.golfer, token };
}

export async function getGolferAccount(cancelToken?: CancelToken) {
  const response = await GolferApiClientAuto.request<{ golfer: Golfer }>({
    method: "GET",
    url: "/golfer/user",
    cancelToken: cancelToken,
  });

  return response.data.golfer;
}
