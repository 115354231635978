import React from "react";
import { Box, Typography, LinearProgress } from "@material-ui/core";

import { AppConfig } from "config/AppConfig";

export interface StartFundraiserHeaderProps {
  progress: number;
  step: string;
  stepDetail: string;
  action?: React.ReactElement;
}

export default function StartFundraiserHeader({
  action,
  progress,
  step,
  stepDetail,
}: StartFundraiserHeaderProps) {
  return (
    <Box>
      <Box py={2}>
        <Typography variant="h3" component="h1" display="inline">
          Create Your {AppConfig.Application.ShortName} Fundraiser&nbsp;
        </Typography>
        {action &&
          React.cloneElement(action, {
            style: { verticalAlign: "text-bottom" },
          })}
      </Box>
      <Box py={2}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      <Box py={2}>
        <Typography variant="h4" component="h2">
          {step}
        </Typography>
        <Typography variant="body2">{stepDetail}</Typography>
      </Box>
    </Box>
  );
}
