import { AxiosResponse, CancelToken } from "axios";

import { Organization, OrganizationFormModel } from "./types";
import { convertOrganizationFormModelToFormData } from "./utils";
import { makeOrganizerApiClient } from "../../ApiClient";

export async function updateOrganization(
  token: string | undefined,
  formModel: OrganizationFormModel,
  cancelToken?: CancelToken
): Promise<void> {
  await makeOrganizerApiClient(token).request<
    void,
    AxiosResponse<{ organization: Organization }>
  >({
    method: "PATCH",
    url: "/organizer/organization",
    data: convertOrganizationFormModelToFormData(formModel),
    cancelToken: cancelToken,
  });
}
