import React from "react";
import { TableRow, TableCell, Typography } from "@material-ui/core";

import {
  TournamentFinisherTeam,
  formatGolferName,
  formatNetToPar,
} from "utils/tournament/leaderboard";

import { TeamCell } from "../TeamCell";
import { CourseCell } from "../CourseCell";

interface TableRowTeamProps {
  place: number;
  finisher: TournamentFinisherTeam;
}

export default function TableRowTeam({ place, finisher }: TableRowTeamProps) {
  return (
    <TableRow>
      <TableCell align="center">
        <Typography>{place}</Typography>
      </TableCell>
      <TableCell>
        <TeamCell
          photo={{
            src: finisher.score_registration.photo?.medium,
            largeSrc: finisher.score_registration.photo?.original,
            alt: finisher.leader_registration.team?.name ?? "",
          }}
        >
          <Typography>{finisher.leader_registration.team?.name}</Typography>
          {finisher.registrations.map((reg) => (
            <Typography variant="body2" key={reg.id}>
              {formatGolferName(reg.golfer)}
            </Typography>
          ))}
        </TeamCell>
      </TableCell>
      <TableCell align="center">
        <Typography>{finisher.score_registration.score}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography>
          {formatNetToPar(finisher.score_registration.net_to_par)}
        </Typography>
      </TableCell>
      <TableCell align="center">
        {finisher.score_registration.tee && (
          <Typography>
            {finisher.score_registration.tee.target_score}
          </Typography>
        )}
      </TableCell>
      <TableCell>
        <CourseCell registration={finisher.score_registration} />
      </TableCell>
    </TableRow>
  );
}
