import React from "react";
import { Box, Avatar } from "@material-ui/core";

import { TextSkeleton } from "components/core/Skeleton";

import { useRegistrationRowStyles } from "./RegistrationRow.styles";

export default function RegistrationRowSkeleton() {
  const classes = useRegistrationRowStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.inner}>
        <Box className={classes.avatarWrapper}>
          <Avatar variant="square" className={classes.avatar}>
            T
          </Avatar>
        </Box>
        <Box>
          <TextSkeleton variant="h6" width={200} />
          <TextSkeleton variant="body1" width={250} />
          <TextSkeleton variant="body1" width={250} />
          <TextSkeleton variant="body2" width={180} />
        </Box>
      </Box>
      <Box alignSelf="center" flexShrink={0}>
        <TextSkeleton variant="body1" width={180} />
      </Box>
    </Box>
  );
}
