import { CancelToken } from "axios";

import { OrganizerApiClientAuto } from "../../ApiClient";

export interface CalculateNetToParParams {
  tee_id: number | undefined;
  score: string | undefined;
}

export async function calculateNetToPar(
  { tee_id, score }: CalculateNetToParParams,
  cancelToken?: CancelToken
): Promise<number> {
  const response = await OrganizerApiClientAuto.request<{ net_to_par: number }>(
    {
      method: "GET",
      url: "/organizer/courses/calculate_net_to_par",
      params: { tee_id, score },
      cancelToken: cancelToken,
    }
  );
  return response.data.net_to_par;
}
