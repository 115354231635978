import React, { useState, useEffect, useCallback } from "react";
import axios, { CancelToken, AxiosError } from "axios";
import { NativeSelect, NativeSelectProps } from "@material-ui/core";

import { CountryState, getCountryStates } from "api/common";

async function getCountryStatesEnhanced(
  countryId: number | undefined,
  cancelToken: CancelToken
): Promise<CountryState[]> {
  if (!countryId) {
    return [];
  } else {
    return await getCountryStates(countryId, cancelToken);
  }
}

function useCountryStates(countryId: number | undefined): CountryState[] {
  const [states, setStates] = useState<CountryState[]>([]);

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    getCountryStatesEnhanced(countryId, cancelSource.token)
      .then(setStates)
      .catch((error: AxiosError) => {
        if (!axios.isCancel(error)) {
          setStates([]);
        }
      });
    return () => cancelSource.cancel("request cancelled");
  }, [countryId]);

  return states;
}

interface StateIdSelectProps extends Omit<NativeSelectProps, "onChange"> {
  countryId: number | undefined;
  value: number | undefined;
  onChange: (nextValue: number | undefined) => void;
}

export default function StateIdSelect({
  countryId,
  value,
  onChange,
  ...restProps
}: StateIdSelectProps) {
  const states = useCountryStates(countryId);

  const handleChange = useCallback(
    (evt: React.ChangeEvent<HTMLSelectElement>) => {
      if (evt.target.value) {
        onChange(parseInt(evt.target.value));
      } else {
        onChange(undefined);
      }
    },
    [onChange]
  );

  return (
    <NativeSelect value={value} onChange={handleChange} {...restProps}>
      <option disabled></option>
      {states.map((state) => (
        <option key={state.id} value={state.id}>
          {state.name}
        </option>
      ))}
    </NativeSelect>
  );
}
