import { CancelToken } from "axios";

import { OrganizerApiClientAuto } from "../../ApiClient";
import { OrganizerTournamentRegistration } from "./OrganizerTournamentRegistration.types";

export async function getOrganizerTournamentRegistrationById(
  tournamentId: number,
  registrationId: number,
  cancelToken?: CancelToken
): Promise<OrganizerTournamentRegistration> {
  const response = await OrganizerApiClientAuto.request<{
    tournament_registration: OrganizerTournamentRegistration;
  }>({
    method: "GET",
    url: `/organizer/organization/tournaments/${tournamentId}/registrations/${registrationId}`,
    cancelToken: cancelToken,
  });
  return response.data.tournament_registration;
}
