import axios from "axios";
import useSWR from "swr";

import {
  TournamentRegistration,
  getGolferTournamentRegistrationById,
} from "api/golfer/tournament-registrations";
import { useGolferSession } from "utils/golfer-session";

export function useTournamentRegistrationById(
  registrationId: number
): TournamentRegistration | undefined {
  const { token } = useGolferSession();

  const { data } = useSWR(
    token ? [token, "tournament-registrations", registrationId] : null,
    (
      token: string,
      _: string,
      registrationId: number
    ): Promise<TournamentRegistration> => {
      const cancelSource = axios.CancelToken.source();
      const promise = getGolferTournamentRegistrationById(
        token,
        registrationId,
        cancelSource.token
      );
      (promise as any).cancel = () =>
        cancelSource.cancel("request was cancelled");
      return promise;
    }
  );

  return data;
}
