import { makeStyles } from "@material-ui/core";

export const useFormRowStyles = makeStyles((theme) => ({
  basicFormRow: {
    padding: theme.spacing(2.5),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1.5),
    },
  },
  formRowInputlabel: {
    height: "1.1876em",
    boxSizing: "content-box",
    [theme.breakpoints.up("sm")]: {
      padding: "10px 12px 10px 0",
    },
  },
  formRowInputlabelFlex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  formRowInputs: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    "& > * + *": {
      marginLeft: theme.spacing(2.5),
    },
  },
  formRowHelp: {
    fontStyle: "italic",
    padding: theme.spacing(1, 0, 0),
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 1),
    },
  },
}));
