import { useState, useEffect } from "react";
import axios, { AxiosError } from "axios";

import { Country, getUnitedStates } from "../getCountries";

export function useUnitedStates(): Country | undefined {
  const [country, setCountry] = useState<Country | undefined>(undefined);

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    getUnitedStates(cancelSource.token)
      .then(setCountry)
      .catch((error: AxiosError) => {
        if (!axios.isCancel(error)) {
          setCountry(undefined);
        }
      });
    return () => cancelSource.cancel("request cancelled");
  }, []);

  return country;
}
