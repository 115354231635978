import React from "react";
import { Box, BoxProps, makeStyles } from "@material-ui/core";
import clsx from "clsx";

export interface InfoBoxProps extends BoxProps {}

export default function InfoBox({
  className,
  children,
  ...restProps
}: InfoBoxProps) {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)} {...restProps}>
      {children}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    border: `2px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    width: "100%",
    overflowY: "auto",
  },
}));
