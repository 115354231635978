import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { EnsureOrganizerLoggedIn } from "utils/organizer-session";

import { CreateTournamentPage } from "./pages/CreateTournamentPage";
import { EditTournamentPage } from "./pages/EditTournamentPage";
import { TournamentPreviewPage } from "./pages/TournamentPreviewPage";
import { TournamentCongratsPage } from "./pages/TournamentCongratsPage";

const StartFundraiserWizard = () => (
  <Switch>
    <Route
      exact
      path="/organizer/start-fundraiser/create-tournament"
      component={CreateTournamentPage}
    />
    <Route
      exact
      path="/organizer/start-fundraiser/edit-tournament/:tournamentId"
      component={EditTournamentPage}
    />
    <Route
      exact
      path="/organizer/start-fundraiser/preview-tournament/:tournamentId"
      component={TournamentPreviewPage}
    />
    <Route
      exact
      path="/organizer/start-fundraiser/congrats-tournament/:tournamentId"
      component={TournamentCongratsPage}
    />
    <Route
      render={() => (
        <Redirect to="/organizer/start-fundraiser/create-tournament" />
      )}
    />
  </Switch>
);

export default EnsureOrganizerLoggedIn(
  StartFundraiserWizard,
  "/organizer/create-account"
);
