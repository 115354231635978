import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import {
  TournamentFormModel,
  createTournament,
} from "api/organizer/tournaments";

import CreateTournamentPage from "./CreateTournamentPage";
import { useOrganizationLock } from "./useOrganizationLock";

export default function CreateTournamentPageContainer() {
  const history = useHistory();

  const organization = useOrganizationLock();

  const saveTournament = useCallback(
    async (formModel: TournamentFormModel): Promise<void> => {
      const tournament = await createTournament(formModel);
      history.replace(
        `/organizer/start-fundraiser/preview-tournament/${tournament.id}`
      );
    },
    [history]
  );

  return (
    <CreateTournamentPage
      saveTournament={saveTournament}
      organization={organization}
    />
  );
}
