export function remapError<K extends string>(
  errors: { [k in K]?: string },
  key: K,
  apiErrors: string[] | undefined
) {
  if (apiErrors && apiErrors.length > 0) {
    errors[key] = apiErrors[0];
  }
}

export function remapNestedErrors<K extends string>(
  errors: { [k in K]?: object },
  key: K,
  parseNestedErrors: (nestedErrors: object) => void
) {
  const nestedErrors = {};
  parseNestedErrors(nestedErrors);
  if (Object.keys(nestedErrors).length > 0) {
    errors[key] = nestedErrors;
  }
}
