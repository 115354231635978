import React from "react";
import { Redirect } from "react-router-dom";

import { SessionServiceStatus } from "utils/session";
import { getComponentWrappedName } from "utils/getComponentWrappedName";

import { useOrganizerSession } from "./OrganizerSessionProvider";

export function EnsureOrganizerNotLoggedIn<P>(
  Component: React.ComponentType<P>,
  redirectTo: string = "/organizer/dashboard"
) {
  function EnsureOrganizerNotLoggedInWrapper(props: P) {
    const { status } = useOrganizerSession();

    if (status === SessionServiceStatus.Authenticated) {
      return <Redirect to={redirectTo} />;
    } else {
      return <Component {...props} />;
    }
  }

  EnsureOrganizerNotLoggedInWrapper.displayName = getComponentWrappedName(
    "EnsureOrganizerNotLoggedIn",
    Component
  );

  return EnsureOrganizerNotLoggedInWrapper;
}
