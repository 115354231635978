import { CancelToken } from "axios";
import { FORM_ERROR } from "final-form";

import { remapError, remapNestedErrors } from "utils/network/validations";

import { GolferApiClientAuto } from "../../ApiClient";
import {
  JoinTournamentFormModel,
  JoinTournamentFormModelErrors,
  TournamentRegistration,
  TournamentRegistrationMode,
} from "./TournamentRegistration.types";
import { TShirtSize } from "api/types";

export interface CreateTournamentRegistrationFormData {
  kind: "team" | "individual";
  team_id?: number | undefined;
  team_code?: string | undefined;
  mulligan: boolean;
  stripe_token: string | undefined;
  promo_code?: string;
  notes: string;
  tshirt_size: TShirtSize | undefined;
  tos_accepted: boolean;
  team_attributes?: {
    name: string | undefined;
    code: string | undefined;
    paid_slots?: string;
  };
}

function convertCreateTournamentRegistrationFormModelToFormData(
  formModel: JoinTournamentFormModel
): CreateTournamentRegistrationFormData {
  const basicData: CreateTournamentRegistrationFormData = {
    kind:
      formModel.mode === TournamentRegistrationMode.Individual
        ? "individual"
        : "team",
    mulligan: formModel.mulligan,
    tos_accepted: formModel.tos_accepted,
    stripe_token: formModel.stripe_token,
    tshirt_size: formModel.tshirt_size,
    notes: formModel.notes,
  };

  if (formModel.promo_code) {
    basicData.promo_code = formModel.promo_code;
  }

  if (formModel.mode === TournamentRegistrationMode.CreateTeam) {
    basicData.team_attributes = {
      name: formModel.new_team.name,
      code: formModel.new_team.code,
    };
    if (formModel.new_team.with_pre_paid_slots) {
      basicData.team_attributes.paid_slots =
        formModel.new_team.number_of_pre_paid_slots;
    }
  }

  if (formModel.mode === TournamentRegistrationMode.JoinTeam) {
    basicData.team_id = formModel.join_team.team?.id;
    basicData.team_code = formModel.join_team.code;
  }

  return basicData;
}

export async function createTournamentRegistration(
  tournamentId: number,
  formModel: JoinTournamentFormModel,
  cancelToken?: CancelToken
): Promise<TournamentRegistration> {
  const response = await GolferApiClientAuto.request<{
    tournament_registration: TournamentRegistration;
  }>({
    method: "POST",
    url: `/golfer/tournaments/${tournamentId}/registrations`,
    data: convertCreateTournamentRegistrationFormModelToFormData(formModel),
    cancelToken: cancelToken,
  });

  return response.data.tournament_registration;
}

export function parseTournamentRegistrationErrors(
  error: Error
): JoinTournamentFormModelErrors {
  const errors: JoinTournamentFormModelErrors = {};
  const apiErrors = (error as any).response?.data?.error?.validations ?? {};
  remapError(errors, "stripe_token", apiErrors.stripe_token);
  remapError(errors, "promo_code", apiErrors.promo_code);
  remapError(errors, "notes", apiErrors.notes);
  remapError(errors, "tshirt_size", apiErrors.tshirt_size);
  remapError(errors, "tos_accepted", apiErrors.tos_accepted);
  remapNestedErrors(
    errors,
    "new_team",
    (
      new_team_errors: NonNullable<JoinTournamentFormModelErrors["new_team"]>
    ) => {
      remapError(new_team_errors, "name", apiErrors.team?.name);
      remapError(new_team_errors, "code", apiErrors.team?.code);
      remapError(
        new_team_errors,
        "number_of_pre_paid_slots",
        apiErrors.team?.number_of_pre_paid_slots
      );
    }
  );
  remapNestedErrors(
    errors,
    "join_team",
    (
      join_team_errors: NonNullable<JoinTournamentFormModelErrors["join_team"]>
    ) => {
      remapError(join_team_errors, "team", apiErrors.team_id);
      remapError(join_team_errors, "team", apiErrors.team);
      remapError(join_team_errors, "code", apiErrors.team_code);
    }
  );
  if (
    apiErrors.tournament &&
    apiErrors.tournament.length > 0 &&
    apiErrors.tournament[0].match(/has already been taken/)
  ) {
    errors[FORM_ERROR] = "You've already joined this tournament previously";
  }
  return errors;
}
