import React from "react";
import { Typography, Box, Button, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

import { TournamentRegistration } from "api/golfer/tournament-registrations";
import { CopyToClipboardIcon } from "components/core/CopyToClipboardIcon";

interface PaymentSucceedProps {
  registration: TournamentRegistration;
}

export default function PaymentSucceed({ registration }: PaymentSucceedProps) {
  const joinTeamLink =
    registration.golfer_id === registration.team?.golfer_id
      ? `${window.location.origin}/tournament/${registration.tournament.slug}/register?team_id=${registration.team.id}`
      : null;

  return (
    <Box
      maxWidth="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box my={2}>
        <Typography variant="caption" component="p" align="center">
          A receipt has been sent to your email.
        </Typography>
      </Box>
      {joinTeamLink && (
        <>
          <Box my={2} style={{ maxWidth: 800 }}>
            <Typography variant="body1" align="center">
              Share the link with your teammates to get them register:
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            width={800}
            maxWidth="100%"
            mb={2}
          >
            <Link
              href={joinTeamLink}
              variant="body1"
              align="center"
              style={{
                fontWeight: 500,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {joinTeamLink}
            </Link>
            <span>&nbsp;</span>
            <CopyToClipboardIcon
              textToCopy={joinTeamLink}
              size="small"
              style={{ fontSize: "1.15rem" }}
            />
          </Box>
        </>
      )}
      <Box mt={6} mb={2} style={{ maxWidth: 800 }}>
        <Typography variant="body1" align="center">
          Once you complete your round of golf, be sure to take a picture of
          yourself on the golf course then come back and enter your score, so
          you can be included on{" "}
          {registration
            ? `the ${registration.tournament.name}`
            : "a tournament"}{" "}
          Leaderboard!
        </Typography>
      </Box>
      <Box my={2} style={{ maxWidth: 800 }}>
        <Typography variant="body1" align="center">
          We'll be in touch with updates throughout the tournament!
        </Typography>
      </Box>
      <Box my={2} display="flex" flexDirection="row" justifyContent="center">
        <Button
          component={RouterLink}
          to="/golfer/dashboard"
          style={{ width: 250 }}
        >
          Go to Dashboard
        </Button>
      </Box>
    </Box>
  );
}
