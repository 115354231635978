import { CancelToken } from "axios";

import { remapError } from "utils/network/validations";

import { BaseApiClient } from "../../ApiClient";
import { Organizer, getOrganizerByToken } from "./getOrganizerByToken";

export interface OganizerRegistrationModel {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  password: string;
  password_confirmation: string;
  tos_accepted: boolean;
}

export type OganizerRegistrationErrors = {
  [k in keyof OganizerRegistrationModel]?: string;
};

export async function register(
  newGolfer: OganizerRegistrationModel,
  cancelToken?: CancelToken
): Promise<string> {
  const response = await BaseApiClient.request<{
    user_access_token: { key: string };
  }>({
    method: "POST",
    url: "/organizer/registration",
    data: newGolfer,
    cancelToken: cancelToken,
  });
  return response.data.user_access_token.key;
}

export async function registerAndGetOrganizer(
  newOrganizer: OganizerRegistrationModel,
  cancelToken?: CancelToken
): Promise<{ user: Organizer; token: string }> {
  const t = await register(newOrganizer, cancelToken);
  return await getOrganizerByToken(t, cancelToken);
}

export function parseOganizerRegistrationErrors(
  error: Error
): OganizerRegistrationErrors {
  const errors: OganizerRegistrationErrors = {};
  const apiErrors = (error as any).response?.data?.error?.validations ?? {};
  remapError(errors, "first_name", apiErrors.first_name);
  remapError(errors, "last_name", apiErrors.last_name);
  remapError(errors, "email", apiErrors.email);
  remapError(errors, "phone", apiErrors.phone);
  remapError(errors, "password", apiErrors.password);
  remapError(errors, "password_confirmation", apiErrors.password_confirmation);
  return errors;
}
