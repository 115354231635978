import React from "react";
import { Box, Grid, Typography, Tooltip } from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";

import {
  TournamentEntrantTeam,
  formatGolferName,
} from "utils/tournament/leaderboard";

export default function TeamEntrantRow({
  entrant,
}: {
  entrant: TournamentEntrantTeam;
}) {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={6} sm={4} md={3}>
        <Box display="flex" alignItems="center">
          <Tooltip title="Team">
            <GroupIcon style={{ cursor: "help" }} color="primary" />
          </Tooltip>
          <Box ml={1}>
            <Typography variant="h6" component="p">
              {entrant.leader_registration.team?.name}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        {entrant.registrations.map((reg) => (
          <Typography variant="body1" key={reg.id}>
            {formatGolferName(reg.golfer)}
          </Typography>
        ))}
      </Grid>
    </Grid>
  );
}
