import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import {
  SessionService,
  SessionServiceState,
  SessionServiceStatus,
  createAuthTokenStorage,
  createSessionService,
} from "utils/session";
import { Golfer, getGolferByToken } from "api/golfer/account";

export interface GolferSessionService extends SessionService<Golfer> {}
export interface GolferSessionServiceState
  extends SessionServiceState<Golfer> {}

interface GolferSessionServiceHookProxy
  extends GolferSessionServiceState,
    Pick<GolferSessionService, "logIn" | "logOut" | "updateUser"> {}

const SessionContext = createContext<GolferSessionServiceHookProxy>({
  status: SessionServiceStatus.Initial,
  token: undefined,
  user: undefined,
  logIn: () => undefined,
  logOut: () => undefined,
  updateUser: () => undefined,
});

interface GolferSessionProviderProps {
  sessionService: GolferSessionService;
  children: React.ReactNode;
}

export function GolferSessionProvider({
  sessionService,
  children,
}: GolferSessionProviderProps) {
  const [sessionState, setSessionState] = useState<GolferSessionServiceState>(
    sessionService.getState()
  );

  const sessionProxy: GolferSessionServiceHookProxy = useMemo(
    () => ({
      status: sessionState.status,
      token: sessionState.token,
      user: sessionState.user,
      logIn: sessionService.logIn,
      logOut: sessionService.logOut,
      updateUser: sessionService.updateUser,
    }),
    [sessionState, sessionService]
  );

  const handleSessionStorageStateChange = useCallback((newSessionState) => {
    setSessionState(newSessionState);
  }, []);

  useEffect(() => {
    setSessionState(sessionService.getState());
    sessionService.addChangeListener(handleSessionStorageStateChange);
    return () =>
      sessionService.removeChangeListener(handleSessionStorageStateChange);
  }, [sessionService, handleSessionStorageStateChange]);

  return (
    <SessionContext.Provider value={sessionProxy}>
      {children}
    </SessionContext.Provider>
  );
}

export function useGolferSession() {
  return useContext(SessionContext);
}

export function createGolferSessionService(): GolferSessionService {
  return createSessionService<Golfer>(
    createAuthTokenStorage("flex-golf-fundraisers-golfer-token"),
    getGolferByToken
  );
}
