import React, { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  useTournamentById,
  TournamentFormModel,
  updateTournament,
} from "api/organizer/tournaments";
import { EnsureOrganizerLoggedIn } from "utils/organizer-session";

import EditPublishedTournamentPage from "./EditPublishedTournamentPage";
import { useOrganizationLock } from "../StartFundraiserWizard/pages/CreateTournamentPage/useOrganizationLock";

function EditPublishedTournamentPageContainer() {
  const history = useHistory();
  const { tournamentId } = useParams<{ tournamentId: string }>();

  const organization = useOrganizationLock();
  const { tournament } = useTournamentById(parseInt(tournamentId));

  const saveTournament = useCallback(
    async (formModel: TournamentFormModel): Promise<void> => {
      await updateTournament(parseInt(tournamentId), formModel);
      history.replace("/organizer/dashboard");
    },
    [history, tournamentId]
  );

  return (
    <EditPublishedTournamentPage
      saveTournament={saveTournament}
      organization={organization}
      tournament={tournament}
    />
  );
}

export default EnsureOrganizerLoggedIn(EditPublishedTournamentPageContainer);
