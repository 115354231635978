import React from "react";
import { Grid } from "@material-ui/core";

import { BasicFormRow, BasicFormRowProps } from "./BasicFormRow";
import { useFormRowStyles } from "./useFormRowStyles";

interface FormRowProps extends BasicFormRowProps {
  withLabel?: React.ReactNode;
  children: React.ReactNode;
  withHelp?: React.ReactNode;
}

export function FormRow({
  withLabel,
  children,
  withHelp,
  ...restProps
}: FormRowProps) {
  const classes = useFormRowStyles();

  return (
    <BasicFormRow {...restProps}>
      <Grid container alignItems="flex-start">
        <Grid item xs={withLabel ? 12 : false} sm={3}>
          {withLabel}
        </Grid>
        <Grid item xs={12} sm={6} className={classes.formRowInputs}>
          {children}
        </Grid>
        <Grid
          item
          xs={withHelp ? 12 : false}
          sm={withHelp ? 3 : false}
          className={classes.formRowHelp}
        >
          {withHelp}
        </Grid>
      </Grid>
    </BasicFormRow>
  );
}
