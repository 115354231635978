export function applyAspectRatio(size: number, aspect: number): number {
  if (aspect > 1) {
    return size / aspect;
  } else {
    return size * aspect;
  }
}

interface HTMLImageLike {
  width: number;
  height: number;
}

export function getInitialCropArea(
  img: HTMLImageLike,
  aspectRatio: number
): ReactCrop.Crop {
  const imageAspectRatio = img.width / img.height;
  if (imageAspectRatio === aspectRatio) {
    const cropWidth = Math.floor(img.width * 0.95);
    const cropHeight = Math.floor(img.height * 0.95);
    return {
      aspect: aspectRatio,
      unit: "px",
      x: Math.floor((img.width - cropWidth) / 2),
      y: Math.floor((img.height - cropHeight) / 2),
      width: cropWidth,
      height: cropHeight,
    };
  } else if (imageAspectRatio > aspectRatio) {
    const aspectRatioCorrection = imageAspectRatio / aspectRatio;
    const cropWidth = Math.floor(
      applyAspectRatio(img.width, aspectRatioCorrection) * 0.95
    );
    const cropHeight = Math.floor(img.height * 0.95);
    return {
      aspect: aspectRatio,
      unit: "px",
      x: Math.floor((img.width - cropWidth) / 2),
      y: Math.floor((img.height - cropHeight) / 2),
      width: cropWidth,
      height: cropHeight,
    };
  } else {
    const aspectRatioCorrection = imageAspectRatio / aspectRatio;
    const cropWidth = Math.floor(img.width * 0.95);
    const cropHeight = Math.floor(
      applyAspectRatio(img.height, aspectRatioCorrection) * 0.95
    );
    return {
      aspect: aspectRatio,
      unit: "px",
      x: Math.floor((img.width - cropWidth) / 2),
      y: Math.floor((img.height - cropHeight) / 2),
      width: cropWidth,
      height: cropHeight,
    };
  }
}
