import { AxiosResponse, CancelToken } from "axios";

import { makeOrganizerApiClient } from "../../ApiClient";
import { OrganizerTournament } from "./types";

export async function getTournaments(
  token: string,
  cancelToken?: CancelToken
): Promise<OrganizerTournament[]> {
  const response = await makeOrganizerApiClient(token).request<
    void,
    AxiosResponse<{ tournaments: OrganizerTournament[] }>
  >({
    method: "GET",
    url: `/organizer/organization/tournaments`,
    params: {
      "page[number]": 1,
      "page[size]": 100,
    },
    cancelToken: cancelToken,
  });

  return response.data.tournaments;
}
