import React from "react";

import {
  useOrganizerSession,
  EnsureOrganizerLoggedIn,
} from "utils/organizer-session";

import OrganizerDashboardPage from "./OrganizerDashboardPage";
import { useTournaments } from "./useTournaments";
import { sortTournaments } from "./OrganizerDashboardPage.utils";
import { useOrganizationLock } from "../StartFundraiserWizard/pages/CreateTournamentPage/useOrganizationLock";

function OrganizerDashboardPageContainer() {
  const { user } = useOrganizerSession();

  const { tournaments, onTournamentUpdated } = useTournaments();

  useOrganizationLock();

  return (
    <OrganizerDashboardPage
      organizer={user}
      onTournamentUpdated={onTournamentUpdated}
      tournaments={tournaments ? sortTournaments(tournaments) : undefined}
    />
  );
}

export default EnsureOrganizerLoggedIn(OrganizerDashboardPageContainer);
