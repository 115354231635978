import { TournamentRegistration } from "api/golfer/tournament-registrations";

import { useWaitForRegistrationPayment } from "./useWaitForRegistrationPayment";

interface WaitForRegistrationPaymentRenderProps {
  registration: TournamentRegistration;
  setPending: () => void;
}

interface WaitForRegistrationPaymentProps {
  registration: TournamentRegistration;
  children: (props: WaitForRegistrationPaymentRenderProps) => any;
}

export default function WaitForRegistrationPayment({
  registration: initialRegistration,
  children,
}: WaitForRegistrationPaymentProps) {
  const { registration, setPending } = useWaitForRegistrationPayment(
    initialRegistration
  );

  return children({ registration, setPending });
}
