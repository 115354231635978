import axios from "axios";
import useSWR from "swr";
import { useCallback } from "react";
import { useSnackbar } from "notistack";

import { useOrganizerSession } from "utils/organizer-session";
import { getTournaments, OrganizerTournament } from "api/organizer/tournaments";
import { getApiErrorMessage } from "utils/network";

interface UseTournamentsResult {
  tournaments: OrganizerTournament[] | undefined;
  onTournamentUpdated: (t: OrganizerTournament) => void;
}

export function useTournaments(): UseTournamentsResult {
  const { token } = useOrganizerSession();
  const { enqueueSnackbar } = useSnackbar();

  const { data, mutate } = useSWR(
    token ? [token, "tournaments"] : null,
    (token: string): Promise<OrganizerTournament[]> => {
      const cancelSource = axios.CancelToken.source();
      const promise = getTournaments(token, cancelSource.token);
      (promise as any).cancel = () =>
        cancelSource.cancel("request was cancelled");
      return promise;
    },
    {
      onError: (err) => {
        enqueueSnackbar(getApiErrorMessage(err), { variant: "error" });
      },
    }
  );

  const onTournamentUpdated = useCallback(
    (updatedTournament: OrganizerTournament) => {
      mutate(
        async (
          tournaments: OrganizerTournament[] | undefined
        ): Promise<OrganizerTournament[]> => {
          return tournaments
            ? tournaments.map((r) =>
                r.id === updatedTournament.id ? updatedTournament : r
              )
            : [];
        }
      );
    },
    [mutate]
  );

  return { tournaments: data, onTournamentUpdated };
}
