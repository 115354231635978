import React from "react";

function getComponentDisplayName<P>(Component: React.ComponentType<P>) {
  return Component.displayName || Component.name || "Component";
}

export function getComponentWrappedName<P>(
  wrapperName: string,
  WrappedComponent: React.ComponentType<P>
) {
  return `${wrapperName}(${getComponentDisplayName(WrappedComponent)})`;
}
