import axios from "axios";
import useSWR from "swr";
import { useSnackbar } from "notistack";

import {
  TournamentRegistration,
  getGolferTournamentRegistrations,
} from "api/golfer/tournament-registrations";
import { useGolferSession } from "utils/golfer-session";
import { getApiErrorMessage } from "utils/network";

export function useTournamentRegistrations():
  | TournamentRegistration[]
  | undefined {
  const { token } = useGolferSession();
  const { enqueueSnackbar } = useSnackbar();

  const { data } = useSWR(
    token ? [token, "tournament-registrations"] : null,
    (token: string): Promise<TournamentRegistration[]> => {
      const cancelSource = axios.CancelToken.source();
      const promise = getGolferTournamentRegistrations(
        token,
        cancelSource.token
      );
      (promise as any).cancel = () =>
        cancelSource.cancel("request was cancelled");
      return promise;
    },
    {
      onError: (err) => {
        enqueueSnackbar(getApiErrorMessage(err), { variant: "error" });
      },
    }
  );

  return data;
}
