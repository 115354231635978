import React, { useCallback, useEffect } from "react";
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { useHistory } from "react-router-dom";

import {
  registerAndGetOrganizer,
  OganizerRegistrationModel,
  parseOganizerRegistrationErrors,
} from "api/organizer/account";
import { useOrganizerSession } from "utils/organizer-session";
import { getApiErrorMessage } from "utils/network";
import { SessionServiceStatus } from "utils/session";

import CreateOrganizerAccountPage from "./CreateOrganizerAccountPage";
import {
  InitialFormModel,
  validateOganizerRegistrationModel,
} from "./CreateOrganizerAccountPage.utils";

export default function CreateOrganizerAccountPageContainer() {
  const { logIn, status } = useOrganizerSession();

  const history = useHistory();

  useEffect(() => {
    if (status === SessionServiceStatus.Authenticated) {
      history.replace("/organizer/update-organization");
    }
  }, [history, status]);

  const onSubmit = useCallback(
    async (formModel: OganizerRegistrationModel): Promise<any> => {
      try {
        const { token, user } = await registerAndGetOrganizer(formModel);
        logIn(token, user);
      } catch (err) {
        const errors = parseOganizerRegistrationErrors(err);
        if (Object.keys(errors).length > 0) {
          return errors;
        } else {
          return { [FORM_ERROR]: getApiErrorMessage(err) };
        }
      }
    },
    [logIn]
  );

  return (
    <Form<OganizerRegistrationModel>
      onSubmit={onSubmit}
      initialValues={InitialFormModel}
      validate={validateOganizerRegistrationModel}
      validateOnBlur
    >
      {({ handleSubmit, submitting, submitError }) => (
        <CreateOrganizerAccountPage
          handleSubmit={handleSubmit}
          submitting={submitting}
          submitError={submitError}
        />
      )}
    </Form>
  );
}
