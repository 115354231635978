import React from "react";
import { Box, Avatar } from "@material-ui/core";

import { TextSkeleton } from "components/core/Skeleton";

import { useTournamentRowStyles } from "./TournamentRow.styles";

export default function TournamentRowSkeleton() {
  const classes = useTournamentRowStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.topPanel}>
        <Box className={classes.inner}>
          <Box className={classes.avatarWrapper}>
            <Avatar variant="square" className={classes.avatar}>
              T
            </Avatar>
          </Box>
          <Box>
            <TextSkeleton variant="h6" width={200} />
            <TextSkeleton variant="body1" width={300} />
            <TextSkeleton variant="body1" width={80} />
            <TextSkeleton variant="body2" width={200} />
            <TextSkeleton variant="body2" width={180} />
          </Box>
        </Box>
        <Box className={classes.actions}>
          <TextSkeleton variant="body1" width={65} />
          <TextSkeleton variant="body1" width={90} />
          <TextSkeleton variant="body1" width={120} />
          <TextSkeleton variant="body1" width={190} />
          <TextSkeleton variant="body1" width={160} />
          <TextSkeleton variant="body1" width={140} />
          <TextSkeleton variant="body1" width={170} />
        </Box>
      </Box>
    </Box>
  );
}
