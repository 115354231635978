import React, { useCallback, useState } from "react";
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";

import { getApiErrorMessage } from "utils/network";
import { TournamentSponsor } from "api/types";

import SponsorForm from "./SponsorForm";

import {
  SponsorFormModelExtended,
  makeInitialFormModel,
  validateSponsorFormModel,
} from "./SponsorForm.utils";

interface AddSponsorFormContainerProps {
  saveFormModel: (formModel: SponsorFormModelExtended) => Promise<void>;
  initialSponsor?: TournamentSponsor;
  disabled?: boolean;
}

export default function AddSponsorFormContainer({
  saveFormModel,
  initialSponsor,
  disabled = false,
}: AddSponsorFormContainerProps) {
  const [revision, setRevision] = useState<number>(0);

  const onSubmit = useCallback(
    async (formModel: SponsorFormModelExtended): Promise<any> => {
      try {
        await saveFormModel(formModel);
        setRevision((rev) => rev + 1);
      } catch (err) {
        return { [FORM_ERROR]: getApiErrorMessage(err) };
      }
    },
    [saveFormModel]
  );

  return (
    <Form<SponsorFormModelExtended>
      onSubmit={onSubmit}
      initialValues={makeInitialFormModel(initialSponsor, revision)}
      validate={validateSponsorFormModel}
      validateOnBlur
    >
      {({ handleSubmit, submitting, submitError }) => (
        <SponsorForm
          handleSubmit={handleSubmit}
          submitting={submitting}
          submitError={submitError}
          disabled={disabled}
        />
      )}
    </Form>
  );
}
