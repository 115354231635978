import { darken, lighten } from "@material-ui/core/styles";

export const Palette = {
  primary: {
    light: lighten("#665ef1", 0.2),
    main: "#665ef1",
    dark: darken("#665ef1", 0.2),
    contrastText: "#fff",
  },
  secondary: {
    light: lighten("#d5a644", 0.2),
    main: "#d5a644",
    dark: darken("#d5a644", 0.2),
    contrastText: "rgb(18, 37, 63)",
  },
  divider: "#c3cfd9",
};
