import memoizeOne from "memoize-one";
import { setIn } from "final-form";
import * as yup from "yup";

import { SponsorFormModel } from "api/organizer/tournament-sponsors";
import { TournamentSponsor } from "api/types";
import { createRemoteImageValue } from "components/core/ImageInput";

export interface SponsorFormModelExtended extends SponsorFormModel {
  revision: number;
}

export type SponsorFormErrors = { [k in keyof SponsorFormModel]?: string };

export const makeInitialFormModel = memoizeOne(function makeInitialFormModel(
  initialSponsor: TournamentSponsor | undefined,
  revision: number
): SponsorFormModelExtended {
  return {
    name: initialSponsor?.name ?? "",
    url: initialSponsor?.url ?? "",
    logo: initialSponsor?.logo
      ? createRemoteImageValue(initialSponsor.logo.thumb)
      : undefined,
    revision,
  };
});

const SponsorFormSchema: yup.ObjectSchema<Partial<SponsorFormModel>> = yup
  .object({
    name: yup.string().required(),
    url: yup.string().url().required(),
  })
  .defined();

export async function validateSponsorFormModel(
  formModel: SponsorFormModel
): Promise<SponsorFormErrors | undefined> {
  try {
    await SponsorFormSchema.validate(formModel, {
      abortEarly: false,
    });
  } catch (err) {
    const errors: SponsorFormErrors = err.inner.reduce(
      (formError: any, innerError: any) => {
        return setIn(formError, innerError.path, innerError.message);
      },
      {}
    );
    return errors;
  }
}
