import { useState, useEffect } from "react";

export default function CreateObjectURL({
  file,
  children,
}: {
  file: Blob;
  children: (url: string) => any;
}) {
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    const newUrl = URL.createObjectURL(file);
    setUrl(newUrl);
    return () => URL.revokeObjectURL(newUrl);
  }, [file]);

  if (url) {
    return children(url);
  } else {
    return null;
  }
}
