import React from "react";

import { OrganizerPage } from "components/layout";

import { TournamentForm } from "../StartFundraiserWizard/components/TournamentForm";
import { TournamentFormModel } from "api/organizer/tournaments";
import { Organization } from "api/organizer/organization";
import { Tournament } from "api/types";

interface EditPublishedTournamentPageProps {
  saveTournament: (formModel: TournamentFormModel) => Promise<void>;
  organization: Organization | undefined;
  tournament: Tournament | undefined;
}

export default function EditPublishedTournamentPage({
  saveTournament,
  tournament,
  organization,
}: EditPublishedTournamentPageProps) {
  return (
    <OrganizerPage>
      <TournamentForm
        saveFormModel={saveTournament}
        initialTournament={tournament}
        organization={organization}
        isPublishedTournamentMode={true}
      />
    </OrganizerPage>
  );
}
