import { Golfer, UpdateGolferAccountModel } from "api/golfer/account";

export function makeInitialFormModel(user?: Golfer): UpdateGolferAccountModel {
  return {
    first_name: user?.first_name ?? "",
    last_name: user?.last_name ?? "",
    phone: user?.phone ?? "",
    with_new_password: false,
    password: "",
    password_confirmation: "",
    address_attributes: {
      id: user?.address.id,
      country_id: user?.address.country_id,
      state_id: user?.address.state_id,
      street_1: user?.address.street_1 ?? "",
      street_2: user?.address.street_2 ?? "",
      city: user?.address.city ?? "",
      zip: user?.address.zip ?? "",
    },
  };
}
