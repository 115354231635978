import React from "react";
import { Avatar, Box } from "@material-ui/core";
import clsx from "clsx";

import { TextSkeleton } from "components/core/Skeleton";

import { useStyles } from "./TeamCell.styles";

export default function TeamCellSkeleton() {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Avatar
        variant="square"
        className={clsx(classes.photo, { [classes.photoEmpty]: true })}
      >
        <span className={classes.photoIcon}>A</span>
      </Avatar>
      <Box className={classes.content}>
        <TextSkeleton variant="body1" width="100%" />
        <TextSkeleton variant="body2" width="80%" />
      </Box>
    </Box>
  );
}
