import React from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import {
  Link,
  Grid,
  Typography,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";

import { OrganizerPage } from "components/layout";
import { useTournamentById } from "api/organizer/tournaments";
import { TextSkeleton } from "components/core/Skeleton";

import { SponsorTableRow } from "./components/SponsorTableRow";
import { EnsureOrganizerLoggedIn } from "utils/organizer-session";

function TournamentSponsorsPage() {
  const { tournamentId } = useParams<{ tournamentId: string }>();

  const { tournament, revalidate } = useTournamentById(parseInt(tournamentId));

  return (
    <OrganizerPage>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {tournament ? (
            <Typography variant="h2" component="h1">
              <Link
                color="inherit"
                underline="none"
                component={RouterLink}
                to={`/tournament/${tournament.slug}`}
              >
                {tournament.name}
              </Link>{" "}
              - Sponsors
            </Typography>
          ) : (
            <TextSkeleton variant="h2" width="50%" />
          )}
        </Grid>
        <Grid item xs={12}>
          <Button
            component={RouterLink}
            to="/organizer/dashboard"
            style={{ marginRight: 8 }}
            size="small"
          >
            Back to Dashboard
          </Button>
          {tournament && (
            <Button
              size="small"
              component={RouterLink}
              to={`/organizer/tournament/${tournament.id}/sponsors/add`}
            >
              Add Sponsor
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                {["Sponsor Name", "Sponsor Logo", "Sponsor URL", ""].map(
                  (col, index) => (
                    <TableCell key={index}>
                      <Typography variant="body2">{col}</Typography>
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {tournament?.sponsors.map((sponsor) => (
                <SponsorTableRow
                  key={sponsor.id}
                  sponsor={sponsor}
                  onRemoved={revalidate}
                />
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </OrganizerPage>
  );
}

export default EnsureOrganizerLoggedIn(TournamentSponsorsPage);
