import axios from "axios";
import useSWR from "swr";

import {
  getGuestTournamentRegistrations,
  GuestTournamentRegistration,
} from "../getGuestTournamentRegistrations";

interface UseGuestTournamentRegistrationsResult {
  registrations: GuestTournamentRegistration[];
  isInitialized: boolean;
}

export function useGuestTournamentRegistrations(
  slug: string
): UseGuestTournamentRegistrationsResult {
  const { data } = useSWR(
    ["guest-tournament-registrations", slug],
    (_: string, slug: string) => {
      const cancelSource = axios.CancelToken.source();
      const promise = getGuestTournamentRegistrations(slug, cancelSource.token);
      (promise as any).cancel = () =>
        cancelSource.cancel("request was cancelled");
      return promise;
    }
  );

  return { registrations: data ?? [], isInitialized: data != null };
}
