import React from "react";

import { OrganizerPage } from "components/layout";
import { TournamentFormModel } from "api/organizer/tournaments";
import { Organization } from "api/organizer/organization";

import { StartFundraiserHeader } from "../../components/StartFundraiserHeader";
import { TournamentForm } from "../../components/TournamentForm";
import { StartFundraiserWizardSteps } from "../../StartFundraiserWizard.steps";

interface CreateTournamentPageProps {
  saveTournament: (formModel: TournamentFormModel) => Promise<void>;
  organization: Organization | undefined;
}

export default function CreateTournamentPage({
  saveTournament,
  organization,
}: CreateTournamentPageProps) {
  return (
    <OrganizerPage>
      <StartFundraiserHeader {...StartFundraiserWizardSteps.CreateTournament} />
      <TournamentForm
        saveFormModel={saveTournament}
        disabled={!organization}
        organization={organization}
      />
    </OrganizerPage>
  );
}
