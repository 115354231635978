import React, { useMemo } from "react";
import { Button } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

import { useGolferSession } from "utils/golfer-session";
import { useOrganizerSession } from "utils/organizer-session";
import { SessionServiceStatus } from "utils/session";

import { Page } from "../Page";
import { PageContent, PageContentProps } from "../PageContent";
import { PageFooter } from "../PageFooter";
import { PageHeaderBase } from "../PageHeaderBase";
import { OrganizerPageHeader } from "../OrganizerPage";
import { GolferPageHeader } from "../GolferPage";

// import GolferPageHeader from "../GolferPageHeader";

interface SmartPageProps {
  children: React.ReactNode;
  contentProps?: Omit<PageContentProps, "children">;
}

type HeaderVariant = "golfer" | "organizer" | "guest";

export default function GolferPage({
  children,
  contentProps = {},
}: SmartPageProps) {
  const {
    status: golferSessionStatus,
    user: golfer,
    logOut: logOutGolfer,
  } = useGolferSession();
  const {
    status: organizerSessionStatus,
    user: organizer,
    logOut: logOutOrganizer,
  } = useOrganizerSession();

  const headerVariant = useMemo((): HeaderVariant => {
    if (golferSessionStatus === SessionServiceStatus.Authenticated) {
      return "golfer";
    } else if (organizerSessionStatus === SessionServiceStatus.Authenticated) {
      return "organizer";
    } else {
      return "guest";
    }
  }, [golferSessionStatus, organizerSessionStatus]);

  return (
    <Page>
      {(() => {
        switch (headerVariant) {
          case "golfer":
            return (
              <GolferPageHeader
                status={golferSessionStatus}
                user={golfer}
                onLogout={logOutGolfer}
              />
            );
          case "organizer":
            return (
              <OrganizerPageHeader
                status={organizerSessionStatus}
                user={organizer}
                onLogout={logOutOrganizer}
              />
            );
          case "guest":
            return (
              <PageHeaderBase
                navigation={
                  <>
                    <Button size="small" component={RouterLink} to="/organizer">
                      I'm an Organizer
                    </Button>
                    <Button size="small" component={RouterLink} to="/golfer">
                      I'm a Golfer
                    </Button>
                  </>
                }
              />
            );
        }
      })()}
      <PageContent my={6} alignSelf="center" flexGrow={1} {...contentProps}>
        {children}
      </PageContent>
      <PageFooter />
    </Page>
  );
}
