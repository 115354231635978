import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Typography,
  Box,
  Button,
  makeStyles,
  Grid,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { lighten } from "@material-ui/core/styles";
import currency from "currency.js";

import {
  Page,
  PageContent,
  PageHeaderBase,
  PageFooter,
} from "components/layout";
import { AppConfig } from "config/AppConfig";
import { FlexGolfTheme } from "config/theme";
import { formatPercents } from "utils/formatPercentage";
import { useFeaturedTournaments } from "api/guest/tournaments/hooks";

import GolfCourseImage from "./content/Tijeras_IMG_1811__1_.jpg";
import KidsImage from "./content/kids_group_crop__1_.jpg";
import GolfCartsImage from "./content/golf_carts.jpg";
import TeamImage from "./content/team.jpg";
import HelpImage from "./content/help.jpg";
import ShotImage from "./content/Tijeras_IMG_1745__1_.jpg";
import { FeaturedTournamentCard } from "./components/FeaturedTournamentCard";

export default function LandingPage() {
  const classes = useStyles();

  const theme = useTheme<FlexGolfTheme>();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const { tournaments: featuredTournaments } = useFeaturedTournaments();

  return (
    <Page position="relative" className={classes.page}>
      <PageHeaderBase
        transparent
        position="absolute"
        style={{ color: theme.darkBlock.text }}
        navigation={
          <>
            <Button size="small" component={RouterLink} to="/organizer">
              I'm an Organizer
            </Button>
            <Button size="small" component={RouterLink} to="/golfer">
              I'm a Golfer
            </Button>
          </>
        }
      />
      <Box
        className={classes.block}
        justifyContent="flex-end"
        height={600}
        style={{
          color: theme.darkBlock.text,
          backgroundColor: lighten(theme.darkBlock.background, 0.1),
          backgroundImage: `url("${GolfCourseImage}")`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          paddingBottom: theme.spacing(6),
        }}
      >
        <PageContent alignItems="flex-start">
          <Typography variant="h4" component="p">
            Raise Money NOW
          </Typography>
          <Box my={1}>
            <Typography variant="h6" style={{ fontWeight: 500 }}>
              with {AppConfig.Application.Name} innovative golf tournaments that
              require NO GATHERING
            </Typography>
          </Box>
          <Button
            size="large"
            component={RouterLink}
            to="/organizer/start-fundraiser"
          >
            Create Tournament
          </Button>
        </PageContent>
      </Box>
      <Box
        className={classes.block}
        bgcolor={theme.darkBlock.background}
        color={theme.darkBlock.text}
        id="WhatIsFlexGolf"
      >
        <PageContent style={{ maxWidth: 530 }}>
          <Typography variant="h4" component="p" align="center">
            What is a {AppConfig.Application.ShortName} Fundraiser?
          </Typography>
          <Box py={3}>
            <ul style={{ margin: 0 }}>
              <Typography component="li">Month-Long Golf Tournament</Typography>
              <Typography component="li">
                Participants pay the entry fee and play one round ANY day of the
                month at ANY course and submit their score electronically
                &mdash; NO GATHERING required
              </Typography>
              <Typography component="li">
                REPLACE your standard one-day golf tournament or banquet event
                or COMPLEMENT your exising fundraiser event
              </Typography>
            </ul>
          </Box>
          <Button
            size="large"
            style={{ alignSelf: "center" }}
            component={RouterLink}
            to="/organizer/start-fundraiser"
          >
            Create Tournament
          </Button>
        </PageContent>
      </Box>
      <Box
        className={classes.block}
        style={{ background: "white" }}
        id="RaiseMoneyNow"
      >
        <PageContent>
          <Box py={2}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={4} md={3}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  {isMobile ? (
                    <Typography variant="h4" component="p">
                      Whatever your cause, we can help you raise money NOW.
                    </Typography>
                  ) : (
                    <>
                      <Typography
                        variant="h4"
                        component="p"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "visible",
                          background: "white",
                          zIndex: 1,
                        }}
                      >
                        Whatever your cause, we can
                      </Typography>
                      <Typography
                        variant="h4"
                        component="p"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "visible",
                          background: "white",
                          zIndex: 1,
                          margin: theme.spacing(2, 0, 2),
                        }}
                      >
                        help you raise money NOW.
                      </Typography>
                    </>
                  )}
                  <Typography>
                    The global pandemic has cancelled and postponed fundraising
                    events around the world, leaving many that rely on those
                    funds at risk.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <img
                  style={{ objectFit: "cover", width: "100%", height: "100%" }}
                  src={KidsImage}
                  alt="Kids"
                />
              </Grid>
            </Grid>
          </Box>
        </PageContent>
      </Box>
      <Box
        className={classes.block}
        bgcolor={theme.darkBlock.background}
        color={theme.darkBlock.text}
        id="BenefitsToOrganizations"
      >
        <PageContent px={1}>
          <Typography variant="h4" component="p" align="center">
            Benefits to Organizations
          </Typography>
          <Box py={4}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4}>
                <img
                  src={GolfCartsImage}
                  alt="Golf Carts"
                  className={classes.benefitsImage}
                />
                <Typography variant="h5" component="p">
                  Increase Participation
                </Typography>
                <ul style={{ marginTop: theme.spacing(2) }}>
                  <Typography component="li">
                    NOT limited to 144 players like a typical shotgun charity
                    golf tournament
                  </Typography>
                  <Typography component="li">
                    NOT limited to constraints of seating at a dinner banquet
                  </Typography>
                  <Typography component="li">
                    NOT limited to a single region/city/county: Participants
                    play their round ANY day at ANY course ANYWHERE
                  </Typography>
                </ul>
              </Grid>
              <Grid item xs={12} sm={4}>
                <img
                  src={TeamImage}
                  alt="Team"
                  className={classes.benefitsImage}
                />
                <Typography variant="h5" component="p">
                  Reduce Costs/Efforts
                </Typography>
                <ul style={{ marginTop: theme.spacing(2) }}>
                  <Typography component="li">
                    No on-site volunteers or golf course setup is required
                  </Typography>
                  <Typography component="li">
                    No negotiation/coordination required with golf courses or
                    banquet venues
                  </Typography>
                  <Typography component="li">No dinner/food costs</Typography>
                  <Typography component="li">
                    You may offer virtual gift bags and digital prizes to
                    participants to minimize costs
                  </Typography>
                  <Typography component="li">
                    No manual scorecard review post-round, the Leaderboard
                    updates automatically as players enter their scores
                  </Typography>
                </ul>
              </Grid>
              <Grid item xs={12} sm={4}>
                <img
                  src={HelpImage}
                  alt="Help"
                  className={classes.benefitsImage}
                />
                <Typography variant="h5" component="p">
                  Maximize Donations
                </Typography>
                <Typography style={{ marginTop: theme.spacing(2) }}>
                  Utilize third party mobile bidding software throughout the
                  tournament month to run successfull Silent Auction,
                  Bid-for-a-Cause and other donation generating programs. And
                  with a month long tournament you'll have ample opportunities
                  to showcase your Sponsors to your participants via email and
                  social media, giving them MUCH more value than a tee box sign
                  at a typical one-day charity golf tournament.
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Button
            size="large"
            style={{ alignSelf: "center" }}
            component={RouterLink}
            to="/organizer/start-fundraiser"
          >
            Create Tournament
          </Button>
        </PageContent>
      </Box>
      <Box className={classes.block} id="BenefitsToParticipants">
        <PageContent>
          <Grid container spacing={0} alignItems="stretch">
            <Grid item xs={12} md={8}>
              <img
                src={ShotImage}
                alt="Shot"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                bgcolor={theme.palette.grey[300]}
                p={3}
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
              >
                <Typography variant="h3">Benefits to Participants</Typography>
                <Typography variant="h5" style={{ textTransform: "uppercase" }}>
                  Convenience
                </Typography>
                <Typography>
                  Play ANY course near them ANY day of the tournament month
                </Typography>
                <Typography variant="h5" style={{ textTransform: "uppercase" }}>
                  Cost
                </Typography>
                <Typography>
                  Some will play a $200 course, others their local $25 municipal
                  course and others will play at their private golf club.
                  Players determine their total cost to participate!
                </Typography>
                <Typography variant="h5" style={{ textTransform: "uppercase" }}>
                  Fun, for good!
                </Typography>
                <Typography>
                  Fun, REAL golf tournament (this is not VIRTUAL, players are
                  playing real golf) with prizes in support of a great cause
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </PageContent>
      </Box>
      <Box
        className={classes.block}
        bgcolor={theme.darkBlock.background}
        color={theme.darkBlock.text}
        id="HowItWorks"
      >
        <PageContent px={1}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8} lg={9}>
              <Typography variant="h4" component="p">
                How it Works
              </Typography>
              <ol>
                <Typography component="li">
                  You create a {AppConfig.Application.ShortName} Fundraiser
                  tournament and set the entry fee, start and end dates and
                  prizes
                </Typography>
                <Typography component="li">
                  You invite your audience to participate in the golf tournament
                  by email and social media
                </Typography>
                <Typography component="li">
                  Participants pay the entry fee to play in your tournament
                </Typography>
                <Typography component="li">
                  Participants play 1 round at ANY course ANYWHERE and submit
                  their score electronically
                </Typography>
                <Typography component="li">
                  If desired, you distribute a gift/swag bag to your
                  participants
                </Typography>
                <Typography component="li">
                  You update players on the Leaderboard, Silent Auction items,
                  Bid-for-a-Cause, Prizes, Sponsors and features about the great
                  work done by your organization
                </Typography>
                <Typography component="li">
                  You distribute prizes to the top of the Leaderboard at the end
                  of the tournament
                </Typography>
              </ol>
              <Typography variant="h4" component="p">
                You succesfully raise critical funds at a critical time
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Grid container spacing={4} alignItems="stretch">
                <Grid item xs={6} md={12}>
                  <Box
                    px={1}
                    py={2}
                    border="1px solid green"
                    color="green"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    height="100%"
                  >
                    <Typography
                      variant="h3"
                      className={classes.metricCardTitle}
                      component="p"
                      align="center"
                    >
                      {formatPercents(
                        100 - ExampleTournament.registration_comission
                      )}
                    </Typography>
                    <Typography variant="body2" component="p" align="center">
                      of gross entry fees are retained by the Organizer, with
                      the other{" "}
                      {formatPercents(ExampleTournament.registration_comission)}{" "}
                      going to {AppConfig.Application.Name} for credit card and
                      other fees
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={12}>
                  <Box
                    px={1}
                    py={2}
                    border="1px solid green"
                    color="green"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    height="100%"
                  >
                    <Typography
                      variant="h3"
                      className={classes.metricCardTitle}
                      component="p"
                      align="center"
                    >
                      {currency(ExampleTournament.fee, { precision: 0 })
                        .multiply(ExampleTournament.participants)
                        .divide(100)
                        .multiply(
                          100 - ExampleTournament.registration_comission
                        )
                        .format()}
                    </Typography>
                    <Typography variant="body2" component="p" align="center">
                      of NET revenue to the Organizer if
                    </Typography>
                    <Typography align="center">
                      <b>{ExampleTournament.participants}</b> participants pay
                    </Typography>
                    <Typography align="center">
                      <b>
                        {currency(ExampleTournament.fee, {
                          precision: 0,
                        }).format()}
                      </b>{" "}
                      entry fee
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </PageContent>
      </Box>
      <Box className={classes.block} id="FeaturedFundraisers">
        <PageContent>
          {featuredTournaments && featuredTournaments.length > 0 && (
            <>
              <Box pb={4}>
                <Typography variant="h3" component="p" align="center">
                  Featured {AppConfig.Application.ShortName} Fundraisers
                </Typography>
              </Box>
              <Grid container spacing={4} alignItems="stretch">
                {featuredTournaments.map((tournament) => (
                  <Grid item xs={12} md={6} lg={4} key={tournament.id}>
                    <FeaturedTournamentCard tournament={tournament} />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          <Box py={4}>
            <Typography variant="h4" component="p" align="center">
              Create your {AppConfig.Application.ShortName} Fundraiser today!
            </Typography>
          </Box>
          <Button
            size="large"
            style={{ alignSelf: "center" }}
            component={RouterLink}
            to="/organizer/start-fundraiser"
          >
            Create Tournament
          </Button>
        </PageContent>
      </Box>
      <PageFooter />
    </Page>
  );
}

const ExampleTournament = {
  fee: 150,
  participants: 160,
  registration_comission: 15,
};

const useStyles = makeStyles((theme) => ({
  page: {
    "& ul, & ol": {
      paddingLeft: theme.spacing(2.5),
      "& > li": {
        margin: theme.spacing(0.75, 0),
      },
    },
  },
  block: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  benefitsImage: {
    width: "100%",
    height: 300,
    objectFit: "cover",
    marginBottom: theme.spacing(2),
  },
  metricCardTitle: {
    [theme.breakpoints.down("xs")]: {
      ...theme.typography.h4,
    },
  },
}));
