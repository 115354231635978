import memoizeOne from "memoize-one";
import * as yup from "yup";
import { setIn } from "final-form";

import {
  TournamentRegistration,
  SubmitScoreFormModel,
  SubmitScoreFormErrors,
} from "api/golfer/tournament-registrations";
import { GolfCourse } from "api/types";

export const makeInitialFormModel = memoizeOne(function makeInitialFormModel(
  registration: TournamentRegistration | undefined
): SubmitScoreFormModel {
  return {
    course: registration?.tournament.course,
    tee_id: undefined,
    score: undefined,
  };
});

const SubmitScoreModelSchema: yup.ObjectSchema<SubmitScoreFormModel> = yup
  .object({
    course: yup.object<GolfCourse>().required("required"),
    tee_id: yup.number().required("required"),
    score: yup.number().min(45).required("required"),
  })
  .defined();

export async function validateSubmitScoreModel(
  formModel: SubmitScoreFormModel
): Promise<SubmitScoreFormErrors | undefined> {
  try {
    await SubmitScoreModelSchema.validate(formModel, {
      abortEarly: false,
    });
  } catch (err) {
    const errors: SubmitScoreFormErrors = err.inner.reduce(
      (formError: any, innerError: any) => {
        return setIn(formError, innerError.path, innerError.message);
      },
      {}
    );

    return errors;
  }
}
