import { CancelToken } from "axios";

import { GolferApiClientAuto } from "api/ApiClient";
import { remapError } from "utils/network/validations";

export interface MissingCourseModel {
  name: string;
  tee_name: string;
  details: string;
}

export type MissingCourseErrors = Partial<
  Record<keyof MissingCourseModel, string>
>;

export async function addMissingCourse(
  missed_course: MissingCourseModel,
  cancelToken?: CancelToken
): Promise<void> {
  await GolferApiClientAuto.request({
    method: "POST",
    url: `/golfer//missed_courses`,
    data: {
      missed_course,
    },
    cancelToken: cancelToken,
  });
}

export function parseMissingCourseErrors(error: Error): MissingCourseErrors {
  const errors: MissingCourseErrors = {};
  const apiErrors = (error as any).response?.data?.error?.validations ?? {};
  remapError(errors, "name", apiErrors.name);
  remapError(errors, "tee_name", apiErrors.tee_name);
  remapError(errors, "details", apiErrors.details);
  return errors;
}
