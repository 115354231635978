import React from "react";
import { Box, Grid } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";

import { TextSkeleton } from "components/core/Skeleton";

export default function IndividualEntrantRowSkeleton() {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={6} sm={4} md={3}>
        <Box display="flex" alignItems="center">
          <PersonIcon style={{ cursor: "help" }} color="primary" />
          <Box ml={1} flexGrow={1}>
            <TextSkeleton variant="h6" width="45%" />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
