import { AxiosResponse, CancelToken } from "axios";

import { makeOrganizerApiClient } from "../../ApiClient";
import { OrganizerTournament } from "./types";

export async function getTournamentById(
  token: string,
  id: number,
  cancelToken?: CancelToken
): Promise<OrganizerTournament> {
  const response = await makeOrganizerApiClient(token).request<
    void,
    AxiosResponse<{ tournament: OrganizerTournament }>
  >({
    method: "GET",
    url: `/organizer/organization/tournaments/${id}`,
    cancelToken: cancelToken,
  });
  return response.data.tournament;
}
