import React from "react";
import { Typography } from "@material-ui/core";

import { TournamentParticipant } from "../../TournamentParticipantsPage.utils";

interface TeamCellProps {
  participant: TournamentParticipant;
}

export default function ScoreCell({ participant }: TeamCellProps) {
  if (typeof participant.registration.score === "number") {
    return <Typography>{participant.registration.score}</Typography>;
  } else if (typeof participant.team?.score === "number") {
    return <Typography>{participant.team.score}</Typography>;
  } else {
    return <Typography color="textSecondary">n/a</Typography>;
  }
}
