import { CancelToken } from "axios";

import { OrganizerApiClientAuto } from "../../ApiClient";
import { convertTournamentFormModelToFormData } from "./utils";
import { TournamentFormModel } from "./types";

export async function updateTournament(
  tournamentId: number,
  formModel: Partial<TournamentFormModel>,
  cancelToken?: CancelToken
): Promise<void> {
  await OrganizerApiClientAuto.request({
    method: "PATCH",
    url: `/organizer/organization/tournaments/${tournamentId}`,
    data: convertTournamentFormModelToFormData(formModel),
    cancelToken: cancelToken,
  });
}
