import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Link,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import {
  usePopupState,
  bindToggle,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { SessionServiceStatus } from "utils/session";
import { Golfer } from "api/golfer/account";

import { PageHeaderBase } from "../PageHeaderBase";
import { formatGolferHandle } from "utils/golfer";

interface GolferPageHeaderProps {
  status: SessionServiceStatus;
  user?: Golfer;
  onLogout: () => void;
}

export default function GolferPageHeader({
  status,
  user,
  onLogout,
}: GolferPageHeaderProps) {
  const popupState = usePopupState({
    variant: "popper",
    popupId: `golfer-navigation`,
  });

  return (
    <PageHeaderBase
      to="/golfer"
      navigation={(() => {
        switch (status) {
          case SessionServiceStatus.Authenticated:
            return (
              <>
                <IconButton
                  color="inherit"
                  {...bindToggle(popupState)}
                  aria-label="Organizer's navigation menu"
                >
                  <MenuIcon />
                </IconButton>
                <Menu {...bindMenu(popupState)}>
                  {user && (
                    <ListSubheader>{formatGolferHandle(user)}</ListSubheader>
                  )}
                  <MenuItem
                    button
                    component={RouterLink}
                    to="/golfer/dashboard"
                    onClick={popupState.close}
                  >
                    <ListItemText primary="My Dashboard" />
                  </MenuItem>
                  <MenuItem
                    button
                    component={RouterLink}
                    to="/golfer/update-account"
                    onClick={popupState.close}
                  >
                    <ListItemText primary="My Account" />
                  </MenuItem>
                  <MenuItem
                    button
                    onClick={() => {
                      popupState.close();
                      onLogout();
                    }}
                  >
                    Log Out
                  </MenuItem>
                </Menu>
              </>
            );
          case SessionServiceStatus.Unauthenticated:
            return (
              <>
                <Link
                  variant="body1"
                  color="inherit"
                  underline="none"
                  component={RouterLink}
                  to="/organizer"
                >
                  I'm an Organizer
                </Link>
                <Link
                  variant="body1"
                  color="inherit"
                  underline="none"
                  component={RouterLink}
                  to="/golfer/login"
                >
                  Log In
                </Link>
              </>
            );
          default:
            return null;
        }
      })()}
    />
  );
}
