import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  TypographyProps,
  Box,
  TableRow,
  TableCell,
  OutlinedInput,
} from "@material-ui/core";
import { Field, FormSpy } from "react-final-form";
import PersonIcon from "@material-ui/icons/Person";
import GroupIcon from "@material-ui/icons/Group";
import axios from "axios";

import {
  searchCourses,
  calculateNetToPar,
  getCourseTees,
} from "api/organizer/courses";
import { TeeIdSelect } from "components/core/TeeIdSelect";
import { formatNetToPar } from "utils/tournament/leaderboard";
import { UpdateScoreFormModel } from "api/organizer/tournament-registrations";
import { CourseSelectLab } from "components/core/CourseSelectLab";

import { RegistrationScoreEntry } from "../../TournamentScoresPage.utils";

interface RegistrationEntryTableRowProps {
  handleSubmit: (...args: any[]) => void;
  submitting: boolean;
  entry: RegistrationScoreEntry;
  isEditing: boolean;
  onEdit: () => void;
  onCancel: () => void;
}

export default function RegistrationEntryTableRow({
  handleSubmit,
  submitting,
  entry,
  isEditing,
  onEdit,
  onCancel,
}: RegistrationEntryTableRowProps) {
  const formId = `registration-${entry.id}`;

  const getFieldId = (unscopedFieldId: string): string =>
    `${formId}-${unscopedFieldId}`;

  return (
    <TableRow>
      <TableCell style={{ width: "20%" }}>
        <Box display="flex" flexDirection="row" alignItems="center">
          {entry.kind === "team" && <GroupIcon color="primary" />}
          {entry.kind === "individual" && <PersonIcon color="primary" />}
          <Box ml={1}>
            <Typography>{entry.name}</Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell style={{ width: "10%" }}>
        {isEditing ? (
          <Field name="score">
            {({ input, meta }) => (
              <OutlinedInput
                id={getFieldId("score")}
                type="text"
                placeholder="72"
                fullWidth
                error={Boolean(meta.touched && meta.error)}
                inputProps={{
                  form: formId,
                }}
                {...input}
              />
            )}
          </Field>
        ) : (
          <Typography align="right">
            {entry.score_registration?.score ?? "-"}
          </Typography>
        )}
      </TableCell>
      <TableCell style={{ width: "25%" }}>
        {isEditing ? (
          <Field name="tee_id">
            {({ input: { onChange: changeTeeId } }) => (
              <Field format={(val) => val} name="course">
                {({ input: { checked, type, multiple, ...input }, meta }) => (
                  <CourseSelectLab
                    id={getFieldId("course")}
                    searchCourses={searchCourses}
                    placeholder="Select Golf Course"
                    {...input}
                    onChange={(evt: any) => {
                      input.onChange(evt);
                      changeTeeId(undefined);
                    }}
                  />
                )}
              </Field>
            )}
          </Field>
        ) : (
          <Typography>
            {entry.score_registration?.course?.name ?? "-"}
          </Typography>
        )}
      </TableCell>
      <TableCell style={{ width: "25%" }}>
        {isEditing ? (
          <Field format={(val) => val} name="course">
            {({ input: { value: course } }) => (
              <Field<number | undefined> name="tee_id">
                {({ input, meta }) => (
                  <TeeIdSelect
                    fullWidth
                    input={<OutlinedInput inputProps={{ form: formId }} />}
                    id={getFieldId("tee-id")}
                    courseId={course?.id}
                    getCourseTees={getCourseTees}
                    disabled={!isEditing}
                    {...input}
                  />
                )}
              </Field>
            )}
          </Field>
        ) : (
          <Typography>{entry.score_registration?.tee_name ?? "-"}</Typography>
        )}
      </TableCell>
      <TableCell style={{ width: "10%" }}>
        {isEditing && (
          <FormSpy<UpdateScoreFormModel> subscription={{ values: true }}>
            {({ values: { tee_id, score } }) => (
              <RealtimeNetToPar
                initialNetToPar={entry.score_registration?.net_to_par}
                tee_id={tee_id}
                score={score}
              />
            )}
          </FormSpy>
        )}
        <Typography
          align="right"
          variant={isEditing ? "caption" : "body1"}
          component="p"
          color={isEditing ? "textSecondary" : "textPrimary"}
        >
          {formatNetToPar(entry.score_registration?.net_to_par, "-")}
        </Typography>
      </TableCell>
      <TableCell style={{ width: "10%" }}>
        {(() => {
          switch (true) {
            case !isEditing:
              return (
                <Button size="small" onClick={onEdit}>
                  {entry.score_registration ? "Edit" : "Add"}
                </Button>
              );
            case isEditing:
              return (
                <form id={formId} onSubmit={handleSubmit}>
                  <Box>
                    <Button size="small" type="submit" disabled={submitting}>
                      Save
                    </Button>
                    <Button
                      variant="text"
                      size="small"
                      disabled={submitting}
                      onClick={onCancel}
                    >
                      Cancel
                    </Button>
                  </Box>
                </form>
              );
          }
        })()}
      </TableCell>
    </TableRow>
  );
}

interface RealtimeNetToParProps extends TypographyProps {
  initialNetToPar: number | undefined;
  tee_id: number | undefined;
  score: string | undefined;
}

function RealtimeNetToPar({
  tee_id,
  score,
  initialNetToPar,
  ...restProps
}: RealtimeNetToParProps) {
  const [latestNetToPar, setNetToPar] = useState<number | undefined>(
    initialNetToPar
  );

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    calculateNetToPar({ tee_id, score }, cancelSource.token)
      .then(setNetToPar)
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setNetToPar(undefined);
        }
      });
    return () => cancelSource.cancel("request was cancelled");
  }, [tee_id, score]);

  return (
    <Typography align="right" color="primary" {...restProps}>
      {typeof latestNetToPar === "number"
        ? formatNetToPar(latestNetToPar)
        : "#"}
    </Typography>
  );
}
