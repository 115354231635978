import memoizeOne from "memoize-one";
import { getUnixTime, parseISO } from "date-fns";

import { Tournament } from "api/types";
import { TournamentRegistration } from "api/golfer/tournament-registrations";
import { isTournamentStarted, isTournamentInProgress } from "utils/tournament";

function getTournamentStatusWeight(tournament: Tournament): number {
  if (!isTournamentStarted(tournament)) {
    return 2;
  } else if (isTournamentInProgress(tournament)) {
    return 1;
  } else {
    return 3;
  }
}

export const sortTournamentRegistrations = memoizeOne(
  function sortTournamentRegistrations(
    registrations: TournamentRegistration[]
  ): TournamentRegistration[] {
    return [...registrations].sort((r1, r2) => {
      const w1 = getTournamentStatusWeight(r1.tournament);
      const w2 = getTournamentStatusWeight(r2.tournament);
      if (w1 === w2) {
        return (
          getUnixTime(parseISO(r1.tournament.start_date)) -
          getUnixTime(parseISO(r2.tournament.start_date))
        );
      } else {
        return w1 - w2;
      }
    });
  }
);
