import React, { useState, useCallback } from "react";
import {
  Popper,
  Grow,
  ClickAwayListener,
  Box,
  makeStyles,
} from "@material-ui/core";
import {
  usePopupState,
  bindToggle,
  bindPopper,
} from "material-ui-popup-state/hooks";

import ConfirmationPopperBody from "./ConfirmationPopperBody";

interface ConfirmationPopperRenderProps {
  onClick: (e: React.SyntheticEvent<any, Event>) => void;
  disabled: boolean;
}

interface ConfirmationPopperProps {
  id: string;
  onConfirm: () => void;
  children: (props: ConfirmationPopperRenderProps) => React.ReactElement;
  onCancel?: () => void;
  disabled?: boolean;
}

export default function ConfirmationPopper({
  id,
  onConfirm,
  onCancel,
  children,
  disabled = false,
}: ConfirmationPopperProps) {
  const classes = useStyles();

  const [arrowRef, setArrowRef] = useState<HTMLSpanElement | null>(null);
  const popupState = usePopupState({
    variant: "popper",
    popupId: `confirmation-popper-${id}`,
  });
  const { close } = popupState;

  const handleConfirmClick = useCallback(() => {
    close();
    onConfirm();
  }, [close, onConfirm]);

  const handleCancelClick = useCallback(() => {
    close();
    if (onCancel) {
      onCancel();
    }
  }, [close, onCancel]);

  return (
    <>
      {children({ ...bindToggle(popupState), disabled: disabled })}
      <Popper
        transition
        className={classes.popper}
        popperOptions={{
          modifiers: {
            arrow: {
              enabled: Boolean(arrowRef),
              element: arrowRef,
            },
          },
        }}
        {...bindPopper(popupState)}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={() => close()}>
            <Grow {...TransitionProps} timeout={350}>
              <Box className={classes.popperBody}>
                <span className={classes.arrow} ref={setArrowRef} />
                <ConfirmationPopperBody
                  onConfirmClick={handleConfirmClick}
                  onCancelClick={handleCancelClick}
                />
              </Box>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: theme.zIndex.modal,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.95em",
      width: "2em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent currentColor transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.95em",
      width: "2em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `currentColor transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.95em",
      height: "2em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent currentColor transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.95em",
      height: "2em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent currentColor`,
      },
    },
  },
  popperBody: {
    position: "relative",
  },
  arrow: {
    position: "absolute",
    fontSize: 6,
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
      color: "rgb(228, 228, 228)",
    },
  },
}));
