import React from "react";
import { Paper, Box } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

interface AlertProps {
  children: React.ReactNode;
  fullWidth?: boolean;
  style?: any;
  elevation?: number;
}

const Alert = React.forwardRef(
  ({ children, fullWidth, style, elevation = 0 }: AlertProps, ref) => (
    <Paper
      ref={ref}
      elevation={elevation}
      style={{
        backgroundColor: "rgb(253, 236, 234)",
        color: "rgb(97, 26, 21)",
        padding: "8px 12px",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        width: fullWidth ? "100%" : "auto",
        ...style,
      }}
    >
      <ErrorOutlineIcon color="inherit" style={{ marginRight: 12 }} />
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        alignItems="stretch"
      >
        {children}
      </Box>
    </Paper>
  )
);

export default Alert;
