import { CancelToken } from "axios";

import { remapError, remapNestedErrors } from "utils/network/validations";

import { GolferApiClientAuto } from "../../ApiClient";
import { AddressAttributes, AddressAttributesErrors } from "../../types";

export interface UpdateGolferAccountModel {
  first_name: string;
  last_name: string;
  phone: string;
  address_attributes: AddressAttributes;
  with_new_password: boolean;
  password: string;
  password_confirmation: string;
}

interface UpdateGolferAccountFormData {
  first_name: string;
  last_name: string;
  phone: string;
  address_attributes: AddressAttributes;
  password?: string;
  password_confirmation?: string;
}

function makeFormData(
  updateModel: UpdateGolferAccountModel
): UpdateGolferAccountFormData {
  const formData: UpdateGolferAccountFormData = {
    first_name: updateModel.first_name,
    last_name: updateModel.last_name,
    phone: updateModel.phone,
    address_attributes: updateModel.address_attributes,
  };

  if (updateModel.with_new_password) {
    formData.password = updateModel.password;
    formData.password_confirmation = updateModel.password_confirmation;
  }

  return formData;
}

type UpdateGolferAccountErrors = {
  [k in Exclude<keyof UpdateGolferAccountModel, "address_attributes">]?: string;
} & {
  address_attributes?: AddressAttributesErrors;
};

export async function updateGolferAccount(
  updateModel: UpdateGolferAccountModel,
  cancelToken?: CancelToken
): Promise<void> {
  await GolferApiClientAuto.request({
    method: "PATCH",
    url: "/golfer/user",
    data: {
      user: makeFormData(updateModel),
    },
    cancelToken: cancelToken,
  });
}

export function parseUpdateGolferAccountErrors(
  error: Error
): UpdateGolferAccountErrors {
  const errors: UpdateGolferAccountErrors = {};
  const apiErrors = (error as any).response?.data?.error?.validations ?? {};
  remapError(errors, "first_name", apiErrors.first_name);
  remapError(errors, "last_name", apiErrors.last_name);
  remapError(errors, "phone", apiErrors.phone);
  remapError(errors, "password", apiErrors.password);
  remapError(errors, "password_confirmation", apiErrors.password_confirmation);
  remapNestedErrors(
    errors,
    "address_attributes",
    (address_attributes_errors: AddressAttributesErrors) => {
      remapError(
        address_attributes_errors,
        "state_id",
        apiErrors.address?.state_id
      );
      remapError(
        address_attributes_errors,
        "state_id",
        apiErrors.address?.state
      );
      remapError(
        address_attributes_errors,
        "street_1",
        apiErrors.address?.street_1
      );
      remapError(
        address_attributes_errors,
        "street_2",
        apiErrors.address?.street_2
      );
      remapError(address_attributes_errors, "city", apiErrors.address?.city);
      remapError(address_attributes_errors, "zip", apiErrors.address?.zip);
    }
  );
  return errors;
}
