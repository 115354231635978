import { AppConfig } from "config/AppConfig";

import { StartFundraiserHeaderProps } from "./components/StartFundraiserHeader";

type StartFundraiserWizardStepType =
  | "CreateAccount"
  | "CreateOrganization"
  | "CreateTournament"
  | "TournamentPreview";

export const StartFundraiserWizardSteps: Record<
  StartFundraiserWizardStepType,
  Pick<StartFundraiserHeaderProps, "progress" | "step" | "stepDetail">
> = {
  CreateAccount: {
    progress: 15,
    step: "Step 1: Create an Organizer Account",
    stepDetail: `These details will be shown as the contact information for your ${AppConfig.Application.ShortName} Fundraiser`,
  },
  CreateOrganization: {
    progress: 30,
    step: "Step 2: Organization Details",
    stepDetail: `These details will be shown as the charity information for your ${AppConfig.Application.ShortName} Fundraiser`,
  },
  CreateTournament: {
    progress: 65,
    step: "Step 3: Tournament Details",
    stepDetail: `These details will be shown as the tournament information for your ${AppConfig.Application.ShortName} Fundraiser`,
  },
  TournamentPreview: {
    progress: 90,
    step: "Step 4: Tournament Preview",
    stepDetail: "This is how your registration link will look!",
  },
};
