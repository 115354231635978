export function appendField(
  fd: FormData,
  key: string,
  value: string | number | File | Blob | boolean | undefined
) {
  if (value instanceof File || value instanceof Blob) {
    fd.append(key, value, (value as any).name ?? "unknown");
  } else if (value !== null && value !== undefined) {
    fd.append(key, String(value));
  }
}
