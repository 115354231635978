import { GuestTournamentRegistration } from "api/guest/tournaments";

export interface TournamentEntrantTeam {
  kind: "team";
  id: string;
  leader_registration: GuestTournamentRegistration;
  registrations: GuestTournamentRegistration[];
}

export interface TournamentEntrantIndividual {
  kind: "individual";
  id: string;
  registration: GuestTournamentRegistration;
}

export type TournamentEntrant =
  | TournamentEntrantTeam
  | TournamentEntrantIndividual;

export interface TournamentFinisherTeam extends TournamentEntrantTeam {
  score_registration: GuestTournamentRegistration;
}

export interface TournamentFinisherIndividual
  extends TournamentEntrantIndividual {}

export type TournamentFinisher =
  | TournamentFinisherTeam
  | TournamentFinisherIndividual;

export function isTeamEntrant(
  entrant: TournamentEntrant
): entrant is TournamentEntrantTeam {
  return entrant.kind === "team";
}

export function isIndividualEntrant(
  entrant: TournamentEntrant
): entrant is TournamentEntrantIndividual {
  return entrant.kind === "individual";
}

export function isTeamFinisher(
  finisher: TournamentFinisher
): finisher is TournamentFinisherTeam {
  return finisher.kind === "team";
}

export function isIndividualFinisher(
  finisher: TournamentFinisher
): finisher is TournamentFinisherIndividual {
  return finisher.kind === "individual";
}
