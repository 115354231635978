import memoizeOne from "memoize-one";

import { GuestTournamentRegistration } from "api/guest/tournaments";

import { TournamentEntrant } from "./TournamentEntrant.types";

export const groupTournamentEntrants = memoizeOne(
  function groupTournamentEntrants(
    registrations: GuestTournamentRegistration[]
  ): TournamentEntrant[] {
    const teamRegistrations: Record<number, GuestTournamentRegistration[]> = {};
    const individualRegistrations: GuestTournamentRegistration[] = [];
    registrations.forEach((registration) => {
      if (registration.team_id) {
        if (!teamRegistrations[registration.team_id]) {
          teamRegistrations[registration.team_id] = [];
        }
        teamRegistrations[registration.team_id].push(registration);
      } else {
        individualRegistrations.push(registration);
      }
    });

    const participants: TournamentEntrant[] = [];

    Object.entries(teamRegistrations).forEach(([team_id, registrations]) => {
      // TODO: Correctly detect leader registration
      // const leader_registration = registrations.find((reg) => reg.golfer_id === reg.team.golfer_id);
      const leader_registration = registrations[0];
      if (leader_registration) {
        participants.push({
          kind: "team",
          id: `team-${team_id}-${leader_registration.id}`,
          leader_registration,
          registrations,
        });
      }
    });

    individualRegistrations.forEach((registration) => {
      participants.push({
        kind: "individual",
        id: `golfer-${registration.golfer_id}-${registration.id}`,
        registration,
      });
    });

    return participants;
  }
);
