import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Typography,
  Box,
  Avatar,
  Chip,
  Link,
  Button,
  Tooltip,
} from "@material-ui/core";
import { format, parseISO, differenceInCalendarDays } from "date-fns";
import GolfCourseIcon from "@material-ui/icons/GolfCourse";
import currency from "currency.js";
import memoizeOne from "memoize-one";
import clsx from "clsx";

import { OrganizerTournament } from "api/organizer/tournaments";
import {
  isTournamentStarted,
  isTournamentInProgress,
  isTournamentEnded,
} from "utils/tournament";
import { formatTournamentForm } from "components/pages/TournamentLandingPage/components/TournamentView/TournamentView.utils";
import { CopyToClipboardIcon } from "components/core/CopyToClipboardIcon";
import { TournamentStatus } from "api/types";

import { useTournamentRowStyles } from "./TournamentRow.styles";
import { PublishTournamentButton } from "../PublishTournamentButton";

interface TournamentRowProps {
  tournament: OrganizerTournament;
  onTournamentUpdated(t: OrganizerTournament): void;
}

export default function TournamentRow({
  tournament,
  onTournamentUpdated,
}: TournamentRowProps) {
  const classes = useTournamentRowStyles();

  return (
    <Box
      className={classes.root}
      style={{ opacity: isTournamentEnded(tournament) ? 0.7 : 1 }}
    >
      <Box className={classes.topPanel}>
        <Box className={classes.inner}>
          <Box className={classes.avatarWrapper}>
            <Avatar
              variant="square"
              className={classes.avatar}
              src={tournament.image?.medium}
              alt="Organization Logo"
            >
              <GolfCourseIcon
                style={{ color: "#9eadb9", fontSize: "1.5rem" }}
              />
            </Avatar>
          </Box>
          <Box className={classes.details}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              maxWidth="100%"
            >
              <Link
                color="inherit"
                variant="h6"
                component={RouterLink}
                to={`/tournament/${tournament.slug}`}
                noWrap
                title={tournament.name}
                style={{ flexShrink: 1 }}
              >
                {tournament.name}
              </Link>
              {(() => {
                switch (true) {
                  case tournament.status === TournamentStatus.Draft:
                    return (
                      <Chip
                        className={clsx(classes.tag, classes.draftTag)}
                        size="small"
                        label="Draft"
                      />
                    );
                  case isTournamentInProgress(tournament):
                    return (
                      <Chip
                        className={classes.tag}
                        color="secondary"
                        size="small"
                        label="In Progress"
                      />
                    );
                  case isTournamentEnded(tournament):
                    return (
                      <Chip
                        className={classes.tag}
                        size="small"
                        label="Finished"
                      />
                    );
                  default:
                    return null;
                }
              })()}
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              maxWidth="100%"
            >
              <Link
                variant="body1"
                display="inline"
                component={RouterLink}
                to={`/tournament/${tournament.slug}`}
                noWrap
                style={{ flexShrink: 1 }}
                title="Link to tournament"
                target="_blank"
              >
                {window.location.origin}/tournament/{tournament.slug}
              </Link>
              <CopyToClipboardIcon
                style={{ marginLeft: 4 }}
                textToCopy={`${window.location.origin}/tournament/${tournament.slug}`}
                size="small"
                aria-label="Share the link with ones who you want to join the tournament"
                title="Share the link with ones who you want to join the tournament"
              />
            </Box>
            <Typography variant="body1" color="textSecondary">
              {formatTournamentForm(tournament.form)}
            </Typography>
            {tournament.course && (
              <Typography color="textSecondary" variant="body2">
                <b>Recommended Course: </b>
                {tournament.course.name}
              </Typography>
            )}
            <Typography color="textSecondary" variant="body2">
              {format(parseISO(tournament.start_date), "MM/dd/yyyy")} to{" "}
              {format(parseISO(tournament.end_date), "MM/dd/yyyy")}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.actions}>
          {tournament.status === TournamentStatus.Draft && (
            <>
              <Link
                variant="body1"
                underline="always"
                component={RouterLink}
                to={`/organizer/start-fundraiser/edit-tournament/${tournament.id}`}
              >
                Edit
              </Link>
              <Link
                variant="body1"
                underline="always"
                component={RouterLink}
                to={`/organizer/start-fundraiser/preview-tournament/${tournament.id}`}
              >
                Preview
              </Link>
              {isTournamentEnded(tournament) ? (
                <Tooltip title="Can not be published because tournament end date is in past">
                  <Chip size="small" color="primary" label="Unpublished" />
                </Tooltip>
              ) : (
                <PublishTournamentButton
                  tournament={tournament}
                  onPublished={onTournamentUpdated}
                >
                  {(props) => (
                    <Button variant="contained" color="secondary" {...props}>
                      Publish
                    </Button>
                  )}
                </PublishTournamentButton>
              )}
            </>
          )}
          {tournament.status === TournamentStatus.Published && (
            <>
              <Link
                variant="body1"
                underline="always"
                component={RouterLink}
                to={`/organizer/edit-tournament/${tournament.id}`}
              >
                Edit
              </Link>
              <Link
                variant="body1"
                underline="always"
                component={RouterLink}
                to={`/tournament/${tournament.slug}/entry-list`}
              >
                Entry List
              </Link>
              {isTournamentStarted(tournament) && (
                <Link
                  variant="body1"
                  underline="always"
                  component={RouterLink}
                  to={`/tournament/${tournament.slug}/leaderboard`}
                >
                  Leaderboard
                </Link>
              )}
              <Link
                variant="body1"
                underline="always"
                component={RouterLink}
                to={`/organizer/tournament/${tournament.id}/participants`}
              >
                Manage Participants
              </Link>
              <Link
                variant="body1"
                underline="always"
                component={RouterLink}
                to={`/organizer/tournament/${tournament.id}/transactions`}
              >
                View Transactions
              </Link>
              <Link
                variant="body1"
                underline="always"
                component={RouterLink}
                to={`/organizer/tournament/${tournament.id}/scores`}
              >
                Score Manager
              </Link>
              <Link
                variant="body1"
                underline="always"
                component={RouterLink}
                to={`/organizer/tournament/${tournament.id}/sponsors`}
              >
                Manage Sponsors
              </Link>
            </>
          )}
        </Box>
      </Box>
      {tournament.status === TournamentStatus.Published &&
        isTournamentInProgress(tournament) && (
          <Box className={classes.metrics}>
            <Box className={classes.metric}>
              <Typography variant="h3" component="p">
                {tournament.participants_count}
              </Typography>
              <Typography variant="body2"># of Participants</Typography>
            </Box>
            <Box className={classes.metric}>
              <Typography variant="h3" component="p">
                {currency(tournament.amount_raised_for_charity).format()}
              </Typography>
              <Typography variant="body2">Total Raised</Typography>
            </Box>
            <Box className={classes.metric}>
              <Typography variant="h3" component="p">
                {getTournamentDaysLeft(tournament)}
              </Typography>
              <Typography variant="body2">Days Left</Typography>
            </Box>
          </Box>
        )}
    </Box>
  );
}

const getTournamentDaysLeft = memoizeOne(function getTournamentDaysLeft(
  tournament: Pick<OrganizerTournament, "end_date">
): number {
  return differenceInCalendarDays(parseISO(tournament.end_date), new Date());
});
