import React from "react";
import { Box, BoxProps, makeStyles } from "@material-ui/core";
import clsx from "clsx";

import { PageConfig } from "./PageConfig";

export interface PageContentProps extends BoxProps {
  children: React.ReactNode;
}

const usePageContentStyles = makeStyles((theme) => ({
  root: {
    maxWidth: PageConfig.ContentMaxWidthPx,
    width: `calc(100% - ${theme.spacing(PageConfig.MobileGuttersUnits * 2)}px)`,
    marginLeft: theme.spacing(PageConfig.MobileGuttersUnits),
    marginRight: theme.spacing(PageConfig.MobileGuttersUnits),
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${theme.spacing(
        PageConfig.TabletGuttersUnits * 2
      )}px)`,
      marginLeft: theme.spacing(PageConfig.TabletGuttersUnits),
      marginRight: theme.spacing(PageConfig.TabletGuttersUnits),
    },
  },
}));

export function PageContent({
  className,
  children,
  ...restProps
}: PageContentProps) {
  const classes = usePageContentStyles();
  return (
    <Box
      className={clsx(classes.root, className)}
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      {...restProps}
    >
      {children}
    </Box>
  );
}
