import * as yup from "yup";

import { ImageInputValue } from "components/core/ImageInput";
import { makeValidator } from "utils/forms";

export interface UploadPictureFormModel {
  photo: ImageInputValue | undefined;
}

export type UploadPictureFormErrors = Partial<
  Record<keyof UploadPictureFormModel, string>
>;

const UploadPictureSchema: yup.ObjectSchema<Partial<
  UploadPictureFormModel
>> = yup
  .object({
    photo: yup.object<ImageInputValue>().required("photo is required"),
  })
  .defined();

export const validateUploadPictureFormModel = makeValidator<
  UploadPictureFormModel,
  UploadPictureFormErrors
>(UploadPictureSchema);
