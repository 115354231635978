import { FlexGolfTheme } from "./FlexGolfTheme.type";

export function applyTypographyPatch(theme: FlexGolfTheme): void {
  theme.typography.h1 = {
    ...theme.typography.h1,
    fontWeight: 400,
    fontSize: "3rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "6rem",
    },
  };

  theme.typography.h2 = {
    ...theme.typography.h2,
    fontWeight: 400,
    fontSize: "2.5rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "3.75rem",
    },
  };

  theme.typography.h3 = {
    ...theme.typography.h3,
    fontWeight: 500,
    fontSize: "2rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "3rem",
    },
  };

  theme.typography.h4 = {
    ...theme.typography.h4,
    fontWeight: 500,
    fontSize: "1.715rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "2.125rem",
    },
  };

  theme.typography.h5 = {
    ...theme.typography.h5,
    fontWeight: 500,
    fontSize: "1.215rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5rem",
    },
  };

  theme.typography.h6 = {
    ...theme.typography.h6,
    fontWeight: 700,
    fontSize: "1.1rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.25rem",
    },
  };

  theme.typography.body1 = {
    ...theme.typography.body1,
    fontWeight: 400,
    fontSize: "1rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.12rem",
    },
  };

  theme.typography.body2 = {
    ...theme.typography.body1,
    fontWeight: 400,
    fontSize: "0.88rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
    },
  };

  theme.typography.caption = {
    ...theme.typography.caption,
    fontWeight: 400,
    fontSize: "0.77rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "0.85rem",
    },
  };
}
