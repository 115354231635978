import React from "react";
import {
  Button,
  Collapse,
  Typography,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Box,
  Fade,
  FormHelperText,
  InputAdornment,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Field } from "react-final-form";
import MaskedInput from "react-text-mask";
import { createNumberMask } from "text-mask-addons";
import { CancelToken } from "axios";

import {
  FormRow,
  FormRowInputLabel,
  FormRowOutlinedInput,
  FormRowImageInput,
  FormRowWithHelperText,
} from "components/core/FormRow";
import { Alert } from "components/core/Alert";
import { TournamentFormat, GolfCourse } from "api/types";
import { CourseSelectLab } from "components/core/CourseSelectLab";
import { formatPercents } from "utils/formatPercentage";
import { Organization } from "api/organizer/organization";

import {
  formatRegistrationFeeDeducted,
  getTournamentFormatHelperText,
} from "./TournamentForm.utils";

interface TournamentFormProps {
  handleSubmit: (...args: any[]) => void;
  submitting: boolean;
  submitError: string | undefined;
  searchCourses: (
    inputValue: string,
    cancelToken: CancelToken
  ) => Promise<GolfCourse[]>;
  organization: Organization | undefined;
  disabled: boolean;
  isPublishedTournamentMode: boolean;
}

export default function TournamentForm({
  handleSubmit,
  submitting,
  submitError,
  searchCourses,
  organization,
  disabled,
  isPublishedTournamentMode,
}: TournamentFormProps) {
  return (
    <form
      onSubmit={handleSubmit}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      }}
    >
      <FormRow
        withLabel={
          <FormRowInputLabel htmlFor="tournament-name">
            Tournament Name
          </FormRowInputLabel>
        }
      >
        <FormRowOutlinedInput
          id="tournament-name"
          name="name"
          placeholder="My Local Charity Open"
          disabled={isPublishedTournamentMode || disabled}
        />
      </FormRow>
      <FormRow
        withLabel={
          <FormRowInputLabel htmlFor="tournament-image">
            Tournament Image
          </FormRowInputLabel>
        }
      >
        <FormRowImageInput
          id="tournament-image"
          name="image"
          aspectRatio={4.5 / 1}
          helperText="For the best experience image should have width to height ratio of 4.5:1, e.g. 1350x300 or larger"
          disabled={disabled}
        />
      </FormRow>
      <FormRow
        withLabel={
          <FormRowInputLabel htmlFor="tournament-form">
            Tournament Format
          </FormRowInputLabel>
        }
      >
        <Field<TournamentFormat> name="form">
          {({ input, meta }) => (
            <Box display="flex" flexDirection="column">
              <RadioGroup row aria-label="tournament format" {...input}>
                <FormControlLabel
                  value={TournamentFormat.Scramble}
                  control={<Radio />}
                  label="Scramble"
                  disabled={isPublishedTournamentMode || disabled}
                />
                <FormControlLabel
                  value={TournamentFormat.BestBall}
                  control={<Radio />}
                  label="Best Ball"
                  disabled={isPublishedTournamentMode || disabled}
                />
                <FormControlLabel
                  value={TournamentFormat.Individual}
                  control={<Radio />}
                  label="Individual"
                  disabled={isPublishedTournamentMode || disabled}
                />
              </RadioGroup>
              <Fade
                in={
                  Boolean(getTournamentFormatHelperText(input.value)) ||
                  Boolean((meta.touched && meta.error) || meta.submitError)
                }
              >
                <FormHelperText
                  error={Boolean(
                    (meta.touched && meta.error) || meta.submitError
                  )}
                >
                  {(meta.touched ? meta.error : null) ||
                    meta.submitError ||
                    getTournamentFormatHelperText(input.value)}
                </FormHelperText>
              </Fade>
            </Box>
          )}
        </Field>
      </FormRow>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="tournament-start-date">
              Start Date
            </FormRowInputLabel>
          }
        >
          <Field name="start_date">
            {({ input, meta }) => (
              <KeyboardDatePicker
                id="tournament-start-date"
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                autoOk
                disabled={isPublishedTournamentMode || disabled}
                {...input}
              />
            )}
          </Field>
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="tournament-end-date">
              End Date
            </FormRowInputLabel>
          }
        >
          <Field name="end_date">
            {({ input, meta }) => (
              <KeyboardDatePicker
                id="tournament-end-date"
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                autoOk
                disabled={isPublishedTournamentMode || disabled}
                {...input}
              />
            )}
          </Field>
        </FormRow>
      </MuiPickersUtilsProvider>
      <FormRow
        withLabel={
          <FormRowInputLabel htmlFor="tournament-details">
            Tournament Details
          </FormRowInputLabel>
        }
        withHelp={
          <>
            <Typography>This is your pitch!</Typography>
            <Typography>
              Why are you doing this? What does the fundraiser support?
            </Typography>
            <Typography>
              Tell them about the wonderful things you do!
            </Typography>
          </>
        }
      >
        <FormRowOutlinedInput
          id="tournament-details"
          name="details"
          multiline
          inputProps={{ style: { height: 130, overflow: "auto" } }}
          disabled={disabled}
        />
      </FormRow>
      <FormRow
        withLabel={
          <FormRowInputLabel htmlFor="tournament-prizes">
            Swag & Prizes
          </FormRowInputLabel>
        }
        withHelp={
          <Typography>
            What do all participants receive (virtual gift bag, opportunity
            prize entry, T-Shirt)? What are the prizes? Remember, supporting
            your cause is the #1 priority, not competition.
          </Typography>
        }
      >
        <FormRowOutlinedInput
          id="tournament-prizes"
          name="prizes"
          multiline
          inputProps={{ style: { height: 70, overflow: "auto" } }}
          placeholder="Local Charity Open Golf Towel"
          disabled={disabled}
        />
      </FormRow>
      <FormRow
        withLabel={
          <FormRowInputLabel htmlFor="tournament-course">
            Preferred Golf Course
          </FormRowInputLabel>
        }
        withHelp={
          <Typography>
            Players can play anywhere they want, but you may want to identify a
            past tournament partner you want to support.
          </Typography>
        }
      >
        <Field format={(val) => val} name="course">
          {({ input, meta }) => (
            <FormRowWithHelperText
              helperText={meta.touched ? meta.error || meta.submitError : null}
              isErrored={Boolean(
                meta.touched && (meta.error || meta.submitError)
              )}
            >
              <CourseSelectLab
                id="tournament-course"
                searchCourses={searchCourses}
                placeholder="None"
                disabled={isPublishedTournamentMode || disabled}
                {...input}
              />
            </FormRowWithHelperText>
          )}
        </Field>
      </FormRow>
      <FormRow
        withLabel={
          <FormRowInputLabel htmlFor="tournament-registration-fee">
            Set Registration Fee
          </FormRowInputLabel>
        }
        withHelp={
          <Field name="registration_fee">
            {({ input: { value } }) => (
              <Typography>
                Your organization will receive{" "}
                <b>{formatRegistrationFeeDeducted(value, organization)}</b> for
                each registrant.
              </Typography>
            )}
          </Field>
        }
      >
        <FormRowOutlinedInput
          id="tournament-registration-fee"
          name="registration_fee"
          style={{ width: 250 }}
          placeholder="50"
          inputComponent={CurrencyTextMask}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          disabled={isPublishedTournamentMode || disabled}
        />
      </FormRow>
      <FormRow
        withLabel={<FormRowInputLabel>Offer Mulligans?</FormRowInputLabel>}
        withHelp={
          <Typography>
            Participants can pay for "do-overs". Purchasing a mulligan gives
            participants one free stroke on the front and back. You receive{" "}
            <b>
              {organization
                ? formatPercents(100 - organization.mulligan_commission)
                : "-"}
            </b>{" "}
            of mulligans!
          </Typography>
        }
      >
        <Field<boolean> type="checkbox" name="mulligan">
          {({ input }) => (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    {...input}
                    disabled={isPublishedTournamentMode || disabled}
                    color="primary"
                  />
                }
                label="Yes"
                style={{ marginRight: 50 }}
              />
              <FormRowOutlinedInput
                id="tournament-mulligan-price"
                name="mulligan_price"
                placeholder="20"
                style={{ width: 250 }}
                disabled={
                  isPublishedTournamentMode || disabled || !input.checked
                }
                inputComponent={CurrencyTextMask}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
            </>
          )}
        </Field>
      </FormRow>
      <FormRow
        withLabel={<FormRowInputLabel>Offer T-Shirt?</FormRowInputLabel>}
        withHelp={
          <Typography>
            You should build cost of a shirt into the registration fee
          </Typography>
        }
      >
        <Field<boolean> type="checkbox" name="offering_tshirt">
          {({ input }) => (
            <FormControlLabel
              control={
                <Checkbox
                  {...input}
                  disabled={isPublishedTournamentMode || disabled}
                  color="primary"
                />
              }
              label="Yes"
              style={{ marginRight: 50 }}
            />
          )}
        </Field>
      </FormRow>
      <Collapse in={Boolean(submitError)}>
        <FormRow>
          <Alert fullWidth>
            <Typography>{submitError}</Typography>
          </Alert>
        </FormRow>
      </Collapse>
      <FormRow>
        <Button type="submit" disabled={disabled || submitting}>
          Save and Next
        </Button>
      </FormRow>
    </form>
  );
}

const CurrencyMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  decimalLimit: 2,
});

function CurrencyTextMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={CurrencyMask}
      placeholderChar={"\u2000"}
    />
  );
}
