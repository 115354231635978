import React from "react";
import { GolferPage } from "components/layout";
import { Typography, Box } from "@material-ui/core";

export default function HTMLContentPage({ content }: { content: string }) {
  return (
    <GolferPage>
      <Typography
        variant="body1"
        component={Box}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </GolferPage>
  );
}
