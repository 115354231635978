import React from "react";
import {
  Typography,
  Button,
  OutlinedInput,
  Collapse,
  Box,
  Link,
} from "@material-ui/core";
import { Field, useField } from "react-final-form";

import { Alert } from "components/core/Alert";
import { GolferPage } from "components/layout";
import { Golfer } from "api/golfer/account";
import {
  FormRow,
  FormRowInputLabel,
  FormRowOutlinedInput,
  FormRowWithHelperText,
} from "components/core/FormRow";
import { StateIdSelect } from "components/core/StateIdSelect";

interface UpdateGolferAccountPageProps {
  user: Golfer | undefined;
  handleSubmit: (...args: any[]) => void;
  submitting: boolean;
  submitError: string | undefined;
}

export default function UpdateGolferAccountPage({
  user,
  handleSubmit,
  submitting,
  submitError,
}: UpdateGolferAccountPageProps) {
  const with_new_password = useField<boolean>("with_new_password").input.value;

  return (
    <GolferPage>
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <FormRow>
          <Typography variant="h5" component="h1">
            Update your account details
          </Typography>
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="golfer-email">Email</FormRowInputLabel>
          }
        >
          <OutlinedInput
            fullWidth={true}
            id="golfer-email"
            value={user?.email ?? ""}
            disabled
            inputProps={{ readOnly: true }}
          />
        </FormRow>
        <FormRow withLabel={<FormRowInputLabel>Name</FormRowInputLabel>}>
          <FormRowOutlinedInput
            name="first_name"
            type="text"
            placeholder="John"
          />
          <FormRowOutlinedInput
            name="last_name"
            type="text"
            placeholder="Doe"
          />
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="golfer-phone">Phone</FormRowInputLabel>
          }
        >
          <FormRowOutlinedInput
            id="golfer-phone"
            name="phone"
            type="tel"
            placeholder="555-555-5555"
          />
        </FormRow>

        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="golfer-street-1">
              Street Address 1
            </FormRowInputLabel>
          }
        >
          <FormRowOutlinedInput
            id="golfer-street-1"
            name="address_attributes.street_1"
            placeholder="123 Charity st."
          />
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="golfer-street-2">
              Street Address 2
            </FormRowInputLabel>
          }
        >
          <FormRowOutlinedInput
            id="golfer-street-2"
            name="address_attributes.street_2"
            placeholder="Suite 123"
          />
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="golfer-city">City</FormRowInputLabel>
          }
        >
          <FormRowOutlinedInput
            id="golfer-city"
            name="address_attributes.city"
          />
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="golfer-state">State</FormRowInputLabel>
          }
        >
          <Field<number | undefined> name="address_attributes.country_id">
            {({ input: { value: countryId } }) => (
              <Field<number | undefined> name="address_attributes.state_id">
                {({ input, meta }) => (
                  <FormRowWithHelperText
                    helperText={
                      meta.touched ? meta.error || meta.submitError : null
                    }
                    isErrored={Boolean(
                      meta.touched && (meta.error || meta.submitError)
                    )}
                  >
                    <StateIdSelect
                      fullWidth
                      style={{ width: 220 }}
                      input={
                        <OutlinedInput
                          error={
                            meta.submitError || (meta.touched && meta.error)
                          }
                        />
                      }
                      id="golfer-state"
                      countryId={countryId}
                      {...input}
                    />
                  </FormRowWithHelperText>
                )}
              </Field>
            )}
          </Field>
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="golfer-zip">Zip</FormRowInputLabel>
          }
        >
          <FormRowOutlinedInput
            style={{ width: 120 }}
            id="golfer-zip"
            type="text"
            name="address_attributes.zip"
            placeholder="12345"
          />
        </FormRow>
        <FormRow>
          <Field<boolean> name="with_new_password">
            {({ input }) => (
              <Link
                color="primary"
                variant="body1"
                component="button"
                type="button"
                onClick={() => input.onChange(!input.value)}
              >
                {input.value ? "Do not change password" : "Change password"}
              </Link>
            )}
          </Field>
        </FormRow>
        <Collapse in={with_new_password}>
          <Box>
            <FormRow
              withLabel={
                <FormRowInputLabel htmlFor="golfer-password">
                  Password
                </FormRowInputLabel>
              }
            >
              <FormRowOutlinedInput
                id="golfer-password"
                name="password"
                type="password"
              />
            </FormRow>
            <FormRow
              withLabel={
                <FormRowInputLabel htmlFor="golfer-password-confirmation">
                  Confirm Password
                </FormRowInputLabel>
              }
            >
              <FormRowOutlinedInput
                id="golfer-password-confirmation"
                name="password_confirmation"
                type="password"
              />
            </FormRow>
          </Box>
        </Collapse>
        <Collapse in={Boolean(submitError)}>
          <FormRow>
            <Alert fullWidth>
              <Typography variant="body1">{submitError}</Typography>
            </Alert>
          </FormRow>
        </Collapse>
        <FormRow>
          <Button type="submit" disabled={submitting}>
            Save
          </Button>
        </FormRow>
      </form>
    </GolferPage>
  );
}
