import React from "react";
import { Typography, Box, Avatar } from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";

import { GolferPage } from "components/layout";
import {
  TournamentRegistration,
  TournamentRegistrationPaymentStatus,
} from "api/golfer/tournament-registrations";

import { PaymentSucceed } from "./components/PaymentSucceed";
import { PaymentFailed } from "./components/PaymentFailed";
import { PaymentPending } from "./components/PaymentPending";
import { WaitForRegistrationPayment } from "./components/WaitForRegistrationPayment";

interface ThankYouPageProps {
  registration: TournamentRegistration | undefined;
}

export default function ThankYouPage({
  registration: initialRegistration,
}: ThankYouPageProps) {
  return (
    <GolferPage contentProps={{ alignItems: "center" }}>
      <Typography variant="h3" component="h1" align="center">
        Thank you for registering for{" "}
        {initialRegistration
          ? `the ${initialRegistration.tournament.name}`
          : "a Tournament"}
      </Typography>
      <Box
        my={2}
        style={{
          width: 82,
          height: 82,
          boxShadow: "0px 0px 8px 3px rgba(200,200,200,0.3)",
        }}
      >
        <Avatar
          variant="square"
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "transparent",
          }}
          src={initialRegistration?.tournament.organization.logo.medium}
          alt="Organization Logo"
        >
          <ImageIcon style={{ color: "#9eadb9", fontSize: "1.5rem" }} />
        </Avatar>
      </Box>
      {initialRegistration && (
        <WaitForRegistrationPayment registration={initialRegistration}>
          {({ registration }) => {
            switch (registration.status) {
              case TournamentRegistrationPaymentStatus.Pending:
                return <PaymentPending registration={registration} />;
              case TournamentRegistrationPaymentStatus.Failed:
                return <PaymentFailed registration={registration} />;
              case TournamentRegistrationPaymentStatus.Success:
                return <PaymentSucceed registration={registration} />;
              default:
                return null;
            }
          }}
        </WaitForRegistrationPayment>
      )}
    </GolferPage>
  );
}
