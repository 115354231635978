import React from "react";
import { Helmet } from "react-helmet";

import { AppConfig } from "config/AppConfig";

export function getPageTitle(title: string): string {
  return `${title} | ${AppConfig.Application.ShortName}`;
}

interface PageTitleProps {
  title: string;
  disableSuffix?: boolean;
}

export function PageTitle({ title, disableSuffix = false }: PageTitleProps) {
  const actualTitle = disableSuffix ? title : getPageTitle(title);

  return (
    <Helmet>
      <title>{actualTitle}</title>
      <meta property="og:title" content={actualTitle} />
    </Helmet>
  );
}
