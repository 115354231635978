import { remapError } from "utils/network/validations";
import { isLocalImageValue } from "components/core/ImageInput";
import { formatISO } from "date-fns";

import { TournamentFormModel, TournamentErrors } from "./types";
import { appendField } from "../../utils";

function cleaupMaskedNumber(num: string | undefined): string | undefined {
  if (!num) {
    return num;
  }
  return num.replace(",", "");
}

export function convertTournamentFormModelToFormData(
  formModel: Partial<TournamentFormModel>
): FormData {
  const fd = new FormData();
  appendField(fd, "tournament[name]", formModel.name);
  appendField(fd, "tournament[course_id]", formModel.course?.id);
  appendField(fd, "tournament[form]", formModel.form);
  if (formModel.start_date) {
    appendField(
      fd,
      "tournament[start_date]",
      formatISO(formModel.start_date, { representation: "date" })
    );
  }
  if (formModel.end_date) {
    appendField(
      fd,
      "tournament[end_date]",
      formatISO(formModel.end_date, { representation: "date" })
    );
  }
  appendField(fd, "tournament[details]", formModel.details);
  appendField(fd, "tournament[prizes]", formModel.prizes);
  appendField(fd, "tournament[mulligan]", formModel.mulligan);
  appendField(
    fd,
    "tournament[registration_fee]",
    cleaupMaskedNumber(formModel.registration_fee)
  );
  if (formModel.image && isLocalImageValue(formModel.image)) {
    appendField(fd, "tournament[image]", formModel.image.file);
  } else if (!formModel.image) {
    appendField(fd, "tournament[image]", "");
  }
  if (formModel.mulligan) {
    appendField(
      fd,
      "tournament[mulligan_price]",
      cleaupMaskedNumber(formModel.mulligan_price)
    );
  }
  appendField(fd, "tournament[offering_tshirt]", formModel.offering_tshirt);
  return fd;
}

export function parseTournamentErrors(error: Error): TournamentErrors {
  const errors: TournamentErrors = {};
  const apiErrors = (error as any).response?.data?.error?.validations ?? {};
  remapError(errors, "name", apiErrors.name);
  remapError(errors, "course", apiErrors.course);
  remapError(errors, "course", apiErrors.course_id);
  remapError(errors, "form", apiErrors.form);
  remapError(errors, "start_date", apiErrors.start_date);
  remapError(errors, "end_date", apiErrors.end_date);
  remapError(errors, "details", apiErrors.details);
  remapError(errors, "prizes", apiErrors.prizes);
  remapError(errors, "mulligan", apiErrors.mulligan);
  remapError(errors, "mulligan_price", apiErrors.mulligan_price);
  remapError(errors, "registration_fee", apiErrors.registration_fee);
  remapError(errors, "offering_tshirt", apiErrors.offering_tshirt);
  remapError(errors, "image", apiErrors.image);
  return errors;
}
