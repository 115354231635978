import { useState, useMemo, useCallback } from "react";

import { CropperDialogProps } from "./CropperDialogProps";

export function useCropperDialogState(): [
  Omit<CropperDialogProps, "onCrop" | "aspectRatio">,
  (i: { asDataUrl: string; fileName: string }) => void
] {
  const [sourceImage, setSourceImage] = useState<
    { asDataUrl: string; fileName: string } | undefined
  >(undefined);
  const [isOpen, setOpen] = useState<boolean>(false);

  const dialogProps: Omit<
    CropperDialogProps,
    "onCrop" | "aspectRatio"
  > = useMemo(
    () => ({
      sourceImage,
      isOpen,
      onClose: () => setOpen(false),
      onExited: () => setSourceImage(undefined),
    }),
    [isOpen, sourceImage]
  );

  const openDialog = useCallback(
    (incomingSourceImage: { asDataUrl: string; fileName: string }) => {
      setSourceImage(incomingSourceImage);
      setOpen(true);
    },
    []
  );

  return [dialogProps, openDialog];
}
