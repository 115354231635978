import React, { useState } from "react";
import { Avatar, Box, ButtonBase } from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import clsx from "clsx";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { useStyles } from "./TeamCell.styles";

interface TeamCellProps {
  photo: {
    src: string | undefined;
    largeSrc: string | undefined;
    alt: string;
  };
  children: React.ReactNode;
}

export default function TeamCell({
  photo: { src, largeSrc, alt },
  children,
}: TeamCellProps) {
  const classes = useStyles();
  const [isLighboxOpen, setLightboxOpen] = useState(false);

  return (
    <Box className={classes.root}>
      <Avatar
        variant="square"
        src={src}
        alt={alt}
        className={clsx(classes.photo, { [classes.photoEmpty]: !Boolean(src) })}
        {...(largeSrc
          ? {
              component: ButtonBase,
              onClick: () => setLightboxOpen(true),
            }
          : {})}
      >
        <ImageIcon className={classes.photoIcon} />
      </Avatar>
      <Box className={classes.content}>{children}</Box>
      {isLighboxOpen && largeSrc && (
        <Lightbox
          mainSrc={largeSrc}
          onCloseRequest={() => setLightboxOpen(false)}
        />
      )}
    </Box>
  );
}
