import React, { useCallback } from "react";
import { NativeSelect, NativeSelectProps } from "@material-ui/core";

import { TournamentTeam } from "api/types";

interface TeamIdSelectProps extends Omit<NativeSelectProps, "onChange"> {
  value: number | undefined;
  onChange: (nextValue: number | undefined) => void;
  teams: TournamentTeam[];
}

export default function TeamIdSelect({
  value,
  onChange,
  teams,
  ...restProps
}: TeamIdSelectProps) {
  const handleChange = useCallback(
    (evt: React.ChangeEvent<HTMLSelectElement>) => {
      if (evt.target.value) {
        onChange(parseInt(evt.target.value));
      } else {
        onChange(undefined);
      }
    },
    [onChange]
  );

  return (
    <NativeSelect value={value ?? ""} onChange={handleChange} {...restProps}>
      <option value="" disabled></option>
      {teams.map((team) => (
        <option key={team.id} value={team.id}>
          {team.name}
        </option>
      ))}
    </NativeSelect>
  );
}
