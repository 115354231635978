import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button, Link, Box, Avatar, Typography, Chip } from "@material-ui/core";
import { format, parseISO, differenceInDays } from "date-fns";
import ImageIcon from "@material-ui/icons/Image";
import ShareIcon from "@material-ui/icons/Share";
import CheckIcon from "@material-ui/icons/Check";
import clsx from "clsx";
import memoizeOne from "memoize-one";

import {
  TournamentRegistration,
  TournamentRegistrationPaymentStatus,
} from "api/golfer/tournament-registrations";
import {
  isTournamentInProgress,
  isTournamentEnded,
  isTournamentStarted,
} from "utils/tournament";
import { CopyToClipboardIcon } from "components/core/CopyToClipboardIcon";
import { GuestTournament } from "api/guest/tournaments";

import { useRegistrationRowStyles } from "./RegistrationRow.styles";

interface RegistrationRowProps {
  registration: TournamentRegistration;
}

function getScore(registration: TournamentRegistration): number | undefined {
  if (registration.team) {
    return registration.team.score;
  } else {
    return registration.score;
  }
}

function isScoreSubmitted(registration: TournamentRegistration): boolean {
  return getScore(registration) != null;
}

function isLeaderRegistration(registration: TournamentRegistration): boolean {
  return Boolean(
    registration.team && registration.team.golfer_id === registration.golfer_id
  );
}

export default function RegistrationRow({
  registration,
}: RegistrationRowProps) {
  const classes = useRegistrationRowStyles();

  return (
    <Box
      className={clsx(classes.root, {
        [classes.errored]:
          registration.status === TournamentRegistrationPaymentStatus.Failed,
      })}
    >
      <Box className={classes.inner}>
        <Box className={classes.avatarWrapper}>
          <Avatar
            variant="square"
            className={classes.avatar}
            src={registration.tournament.organization.logo.medium}
            alt="Organization Logo"
          >
            <ImageIcon style={{ color: "#9eadb9", fontSize: "1.5rem" }} />
          </Avatar>
        </Box>
        <Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Link
              variant="h6"
              component={RouterLink}
              to={`/tournament/${registration.tournament.slug}`}
            >
              {registration.tournament.name}
            </Link>
            {!isTournamentStarted(registration.tournament) && (
              <Chip
                style={{ marginLeft: 8 }}
                size="small"
                label={getStartsInLabel(registration.tournament)}
              />
            )}
            {isTournamentInProgress(registration.tournament) && (
              <Chip
                style={{ marginLeft: 8 }}
                color="secondary"
                size="small"
                label="In Progress"
              />
            )}
            {isTournamentEnded(registration.tournament) && (
              <Chip style={{ marginLeft: 8 }} size="small" label="Finished" />
            )}
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="body1" color="textSecondary">
              {registration.team ? (
                <span>
                  <b>Team: </b>
                  {registration.team.name}
                </span>
              ) : (
                <i>Individual</i>
              )}
            </Typography>
            {isRegistrationSharable(registration) && (
              <CopyToClipboardIcon
                style={{ marginLeft: 8 }}
                textToCopy={`${window.location.origin}/tournament/${registration.tournament.slug}/register?team_id=${registration.team?.id}`}
                copyIcon={<ShareIcon color="secondary" />}
                justCopiedIcon={<CheckIcon style={{ color: "green" }} />}
                size="small"
                aria-label="Share the link with your teammates to get them register"
                title="Share the link with your teammates to get them register"
              />
            )}
          </Box>
          {registration.tournament.course && (
            <Typography variant="body1" color="textSecondary">
              <b>Preferred Course: </b>
              {registration.tournament.course.name}
            </Typography>
          )}
          <Typography variant="body2" color="textSecondary">
            {format(parseISO(registration.tournament.start_date), "MM/dd/yyyy")}{" "}
            to{" "}
            {format(parseISO(registration.tournament.end_date), "MM/dd/yyyy")}
          </Typography>
        </Box>
      </Box>
      <Box alignSelf="center" flexShrink={0}>
        {((): React.ReactNode => {
          switch (true) {
            case registration.status ===
              TournamentRegistrationPaymentStatus.Pending:
              return (
                <Button disabled color="default">
                  Waiting for Payment
                </Button>
              );
            case registration.status ===
              TournamentRegistrationPaymentStatus.Failed:
              return (
                <Button
                  component={RouterLink}
                  to={{
                    pathname: "/golfer/update-payment-method",
                    state: { registration },
                  }}
                  className={classes.paymentErrorButton}
                  disabled={isTournamentEnded(registration.tournament)}
                >
                  Payment Error
                </Button>
              );
            case !isTournamentStarted(registration.tournament):
              return (
                <Link
                  variant="body1"
                  underline="always"
                  component={RouterLink}
                  to={`/tournament/${registration.tournament.slug}/entry-list`}
                >
                  Entry List
                </Link>
              );
            case isTournamentInProgress(registration.tournament) &&
              !isScoreSubmitted(registration):
              return (
                <Button
                  component={RouterLink}
                  to={`/golfer/registration/${registration.id}`}
                >
                  Submit Score
                </Button>
              );
            case isScoreSubmitted(registration):
              return (
                <Button
                  variant="outlined"
                  component={RouterLink}
                  to={`/tournament/${registration.tournament.slug}/leaderboard`}
                >
                  Your Score: {getScore(registration)}
                </Button>
              );
            case isTournamentEnded(registration.tournament):
              return (
                <Link
                  variant="body1"
                  underline="always"
                  component={RouterLink}
                  to={`/tournament/${registration.tournament.slug}/leaderboard`}
                >
                  Leaderboard
                </Link>
              );
            default:
              return null;
          }
        })()}
      </Box>
    </Box>
  );
}

function isRegistrationSharable(registration: TournamentRegistration): boolean {
  return (
    isLeaderRegistration(registration) &&
    registration.status === TournamentRegistrationPaymentStatus.Success &&
    !isScoreSubmitted(registration) &&
    !isTournamentEnded(registration.tournament)
  );
}

const getStartsInLabel = memoizeOne(function getStartsInLabel(
  tournament: GuestTournament
): string {
  const diff = differenceInDays(parseISO(tournament.start_date), new Date());
  if (diff === 0) {
    return "Starts tomorrow";
  }
  if (diff === 1) {
    return "Starts in a day";
  }
  return `Starts in ${diff} days`;
});
