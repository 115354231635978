import React from "react";
import { useField, useForm } from "react-final-form";

import { ImageInput } from "../ImageInput";
import { FormRowWithHelperText } from "./FormRowWithHelperText";

interface FormRowImageInputProps {
  id: string;
  name: string;
  helperText?: string;
  disabled?: boolean;
  aspectRatio: number;
}

export function FormRowImageInput({
  id,
  name,
  helperText,
  disabled = false,
  aspectRatio,
}: FormRowImageInputProps) {
  const form = useForm();
  const {
    input: { onChange, ...restOfInput },
    meta,
  } = useField(name);

  const isErrored = Boolean(meta.touched && (meta.error || meta.submitError));

  return (
    <FormRowWithHelperText
      helperText={
        meta.touched ? meta.error || meta.submitError || helperText : helperText
      }
      isErrored={isErrored}
    >
      <ImageInput
        id={id}
        disabled={disabled}
        aspectRatio={aspectRatio}
        onChange={(...args) => {
          onChange(...args);
          form.resetFieldState(name);
        }}
        {...restOfInput}
      />
    </FormRowWithHelperText>
  );
}
