import React from "react";
import {
  TableRow,
  TableCell,
  Typography,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

import logo from "components/layout/PageHeaderBase/logo.png";
import { AppConfig } from "config/AppConfig";

export default function TableRowHead() {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell align="center">
        <Typography className={classes.label}>Place</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography className={classes.label}>Team</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography className={classes.label}>
          <span>Gross </span>
          <Tooltip title="Gross is the actual number of strokes shot for the round">
            <InfoIcon
              color="secondary"
              style={{ verticalAlign: "sub", fontSize: "inherit" }}
            />
          </Tooltip>
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography className={classes.label}>
          <img
            src={logo}
            alt={`${AppConfig.Application.Name} Logo`}
            width={608 / 20} // NOTE: actual size of the image divided by 20
            height={453 / 20} // NOTE: actual size of the image divided by 20
            style={{ display: "inline", verticalAlign: "middle" }}
          />
          <span> Net to Par </span>
          <Tooltip title="Flex Net to Par is the difference between Gross Score and Target Score">
            <InfoIcon
              color="secondary"
              style={{ verticalAlign: "sub", fontSize: "inherit" }}
            />
          </Tooltip>
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography className={classes.label}>
          <span>Target Score </span>
          <Tooltip title="Target score is Par based on the difficulty of the course and tee that you played">
            <InfoIcon
              color="secondary"
              style={{ verticalAlign: "sub", fontSize: "inherit" }}
            />
          </Tooltip>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.label}>Course</Typography>
      </TableCell>
    </TableRow>
  );
}

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: "bolder",
    whiteSpace: "nowrap",
  },
}));
