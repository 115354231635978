import React from "react";
import { useParams } from "react-router-dom";

import { useTournamentById } from "api/organizer/tournaments";
import { useOrganizerTournamentRegistrations } from "api/organizer/tournament-registrations";
import { useTournamentTeams } from "api/organizer/tournament-teams";
import { EnsureOrganizerLoggedIn } from "utils/organizer-session";

import TournamentParticipantsPage from "./TournamentParticipantsPage";
import {
  makeTournamentParticipantsList,
  sortTournamentParticipants,
} from "./TournamentParticipantsPage.utils";

function TournamentParticipantsPageContainer() {
  const { tournamentId } = useParams<{ tournamentId: string }>();

  const { tournament } = useTournamentById(parseInt(tournamentId));
  const {
    registrations,
    isInitialized,
    onRegistrationUpdated,
  } = useOrganizerTournamentRegistrations(parseInt(tournamentId));
  const teams = useTournamentTeams(parseInt(tournamentId));

  const participants = makeTournamentParticipantsList(registrations, teams);

  return (
    <TournamentParticipantsPage
      tournament={tournament}
      participants={sortTournamentParticipants(participants)}
      teams={teams}
      isInitialized={isInitialized}
      onRegistrationUpdated={onRegistrationUpdated}
    />
  );
}

export default EnsureOrganizerLoggedIn(TournamentParticipantsPageContainer);
