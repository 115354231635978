import { GuestTournament } from "api/guest/tournaments";
import { TournamentTeam, TShirtSize } from "api/types";
import { FORM_ERROR } from "final-form";

export enum TournamentRegistrationMode {
  CreateTeam = "create-team",
  JoinTeam = "join-team",
  Individual = "individual",
}

export interface JoinTournamentFormModel {
  mode: TournamentRegistrationMode;
  mulligan: boolean;
  require_stripe_token: boolean;
  stripe_token?: string | undefined;
  promo_code: string;
  tos_accepted: boolean;
  notes: string;
  is_tshirt_required: boolean;
  tshirt_size: TShirtSize | undefined;
  new_team: {
    name: string;
    code: string;
    with_pre_paid_slots: boolean;
    number_of_pre_paid_slots: string;
  };
  join_team: {
    team: TournamentTeam | undefined;
    code: string;
  };
}

export interface JoinTournamentFormModelErrors {
  stripe_token?: string;
  promo_code?: string;
  notes?: string;
  tos_accepted?: string;
  tshirt_size?: string;
  new_team?: {
    name?: string;
    code?: string;
    number_of_pre_paid_slots?: string;
  };
  join_team?: {
    team?: string;
    code?: string;
  };
  [FORM_ERROR]?: string;
}

interface TournamentRegistrationTeam {
  id: number;
  name: string;
  golfer_id: number;
  score: number | undefined;
}

export enum TournamentRegistrationPaymentStatus {
  Pending = "payment_pending",
  Success = "payment_succeed",
  Failed = "payment_failed",
}

export interface TournamentRegistration {
  id: number;
  kind: "team" | "individual";
  golfer_id: number;
  tournament_id: number;
  team_id: number | undefined;
  course_id: number | undefined;
  tee_id: number | undefined;
  score: number | undefined;
  net_to_par: number | undefined;
  mulligan: boolean;
  total_price: string;
  amount_raised_for_charity: string;
  updated_at: string;
  created_at: string;
  tournament: GuestTournament;
  team: TournamentRegistrationTeam | undefined;
  status: TournamentRegistrationPaymentStatus;
  payment_status_message: string | undefined;
}
