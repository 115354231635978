import { CancelToken } from "axios";

import { BaseApiClient } from "../../ApiClient";

export async function requestPasswordResetEmail(
  email: string,
  cancelToken?: CancelToken
): Promise<void> {
  await BaseApiClient.request({
    method: "POST",
    url: "/golfer/password",
    data: { email },
    cancelToken: cancelToken,
  });
}
