import React from "react";
import { Typography, Box } from "@material-ui/core";

import { GuestTournament } from "api/guest/tournaments";

export default function GolferTermsOfService({
  tournament,
}: {
  tournament: GuestTournament | undefined;
}) {
  return (
    <Typography variant="body1" component={Box}>
      <p>Golfer Registration Terms</p>
      <p>
        In consideration of you accepting this entry, I, the participant,
        intending to be legally bound and hereby waive or release any and all
        right and claims for damages or injuries that I may have against the
        Event Director, {tournament?.organization.name ?? ""},{" "}
        {tournament?.name ?? ""}, Flex Golf Fundraisers, Flex Golf Tour, Flex
        Golf LLC and all of their agents assisting with the event, sponsors and
        their representatives and employees for any and all injuries to me or my
        personal property.
      </p>
      <p>
        I agree to follow all rules and guidelines of the golf course including
        all precautions and safety measures required and/or recommended by the
        golf course, which the golf course may change or amend at their
        discretion. Failure to adhere to the rules, guidelines and safety
        precautions of the golf course may jeopardize my health and safety
        and/or the health and safety of other guests and golf course staff and
        may lead to my dismissal from the premises.
      </p>
      <p>
        This release includes all injuries and/or damages suffered by me before,
        during or after the event. I recognize, intend and understand that this
        release is binding on my heirs, executors, administrators, or assignees.
        I also authorize the use of photographs or videos that include my image
        for promotional, informational, or other reasons deemed to be in the
        best interest of the event. I certify as a material condition to my
        being permitted to enter this tournament that I am physically fit and
        sufficiently trained for the completion of this event and that my
        physical condition has been verified by a licensed Medical Doctor.
        Furthermore, I also grant permission for the use of my name and or
        picture in any broadcast, photograph, or other account of this golf
        tournament. I understand that all registration fees are nonrefundable
        and non-transferable.
      </p>
      <p>
        I understand and agree to the Terms of Use and Privacy Policy of Flex
        Golf LLC posted at flexgolffundraisers.com.
      </p>
    </Typography>
  );
}
