import useSWR from "swr";
import axios from "axios";

import { useOrganizerSession } from "utils/organizer-session";
import { getTournamentById } from "./getTournamentById";
import { OrganizerTournament } from "./types";

export function useTournamentById(
  tournamentId: number
): { tournament: OrganizerTournament | undefined; revalidate: () => void } {
  const { token } = useOrganizerSession();
  const { data, revalidate } = useSWR(
    token ? [token, "tournament", tournamentId] : null,
    (
      token: string,
      _: any,
      tournamentId: number
    ): Promise<OrganizerTournament> => {
      const cancelSource = axios.CancelToken.source();
      const promise = getTournamentById(
        token,
        tournamentId,
        cancelSource.token
      );
      (promise as any).cancel = () =>
        cancelSource.cancel("request was cancelled");
      return promise;
    }
  );

  return { tournament: data, revalidate };
}
