import React from "react";
import { TableCell, TableRow, Typography } from "@material-ui/core";
import { format, parseISO } from "date-fns";

import { formatGolferName } from "utils/tournament/leaderboard";
import { TournamentTeam } from "api/types";
import { OrganizerTournamentRegistration } from "api/organizer/tournament-registrations";

import { TournamentParticipant } from "../../TournamentParticipantsPage.utils";
import { ScoreCell } from "../ScoreCell";
import { TeamCell } from "../TeamCell";
import { TShirtSizeNames } from "utils/TShirtSizeNames";

interface ParticipantsTableRowProps {
  participant: TournamentParticipant;
  teams: TournamentTeam[];
  onRegistrationUpdated: (r: OrganizerTournamentRegistration) => void;
  withTShirtSizeColumn: boolean;
}

export default function ParticipantsTableRow({
  participant,
  teams,
  onRegistrationUpdated,
  withTShirtSizeColumn,
}: ParticipantsTableRowProps) {
  return (
    <TableRow>
      <TableCell>
        <Typography>
          {format(parseISO(participant.registration.created_at), "MM/dd/yyyy")}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {formatGolferName(participant.registration.golfer)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>{participant.registration.golfer.email}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{participant.registration.golfer.address.city}</Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {participant.registration.golfer.address.state_name ?? ""}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>{participant.registration.golfer.address.zip}</Typography>
      </TableCell>
      <TableCell>
        <TeamCell
          participant={participant}
          teams={teams}
          onRegistrationUpdated={onRegistrationUpdated}
        />
      </TableCell>
      <TableCell>
        <ScoreCell participant={participant} />
      </TableCell>
      {withTShirtSizeColumn && (
        <TableCell>
          <Typography>
            {participant.registration.tshirt_size
              ? TShirtSizeNames[participant.registration.tshirt_size]
              : "-"}
          </Typography>
        </TableCell>
      )}
    </TableRow>
  );
}
