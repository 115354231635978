import { CancelToken } from "axios";

import { OrganizerApiClientAuto } from "../../ApiClient";
import {
  SponsorFormModel,
  convertCreateSponsorModelToFormData,
} from "./SponsorFormModel";

export async function updateTournamentSponsor(
  sponsorId: number,
  formModel: SponsorFormModel,
  cancelToken?: CancelToken
): Promise<void> {
  await OrganizerApiClientAuto.request({
    method: "PATCH",
    url: `/organizer//organization/sponsors/${sponsorId}`,
    data: convertCreateSponsorModelToFormData(formModel),
    cancelToken: cancelToken,
  });
}
