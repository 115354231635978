import React from "react";
import { Button, Typography, Grid } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

export default function ThankYou() {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h5">Thank You!</Typography>
        <Typography variant="h5">
          We'll let you know when the final results are posted!
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button component={RouterLink} to="/golfer/dashboard">
          Go to Dashboard
        </Button>
      </Grid>
    </Grid>
  );
}
