import React, { useCallback } from "react";
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { useParams, useHistory, useLocation } from "react-router-dom";
import {
  CardNumberElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Stripe, StripeElements, Token } from "@stripe/stripe-js";

import { useTournamentBySlug } from "api/guest/tournaments/hooks";
import {
  createTournamentRegistration,
  JoinTournamentFormModel,
  parseTournamentRegistrationErrors,
} from "api/golfer/tournament-registrations";
import { getApiErrorMessage } from "utils/network";
import { useTournamentTeams } from "api/golfer/tournament-teams";

import JoinTournamentPage from "./JoinTournamentPage";
import {
  makeInitialFormModel,
  validateJoinTournamentFormModel,
} from "./JoinTournamentPage.utils";
import { withStripeElements } from "utils/withStripeElements.hoc";

function JoinTournamentPageContainer() {
  const { slug } = useParams<{ slug: string | undefined }>();
  const history = useHistory();
  const location = useLocation();
  const { tournament } = useTournamentBySlug(slug);
  const stripe = useStripe();
  const elements = useElements();

  const teams = useTournamentTeams(tournament?.id);

  const onSubmit = useCallback(
    async (formModel: JoinTournamentFormModel): Promise<any> => {
      const extendedFormModel: JoinTournamentFormModel = {
        ...formModel,
      };
      if (formModel.require_stripe_token) {
        try {
          extendedFormModel.stripe_token = (
            await getStripeToken(stripe, elements)
          ).id;
        } catch (err) {
          return { stripe_token: err.message };
        }
      }
      try {
        const registration = await createTournamentRegistration(
          tournament?.id!,
          extendedFormModel
        );
        history.replace({
          pathname: `/tournament/${tournament?.slug}/register/thank-you`,
          state: { registration },
        });
      } catch (error) {
        const errors = parseTournamentRegistrationErrors(error);
        if (Object.keys(errors).length > 0) {
          return errors;
        } else {
          return { [FORM_ERROR]: getApiErrorMessage(error) };
        }
      }
    },
    [tournament, stripe, elements, history]
  );

  return (
    <Form<JoinTournamentFormModel>
      onSubmit={onSubmit}
      initialValues={makeInitialFormModel(tournament, teams, location.search)}
      validate={validateJoinTournamentFormModel}
      validateOnBlur
    >
      {({ handleSubmit, submitting, submitError }) => (
        <JoinTournamentPage
          tournament={tournament}
          teams={teams}
          handleSubmit={handleSubmit}
          submitting={submitting}
          submitError={submitError}
        />
      )}
    </Form>
  );
}

export default withStripeElements(JoinTournamentPageContainer);

async function getStripeToken(
  stripe: Stripe | null,
  elements: StripeElements | null
): Promise<Token> {
  if (!stripe || !elements) {
    throw Error(
      "Could not collect card information: Stripe is not initialized yet"
    );
  }

  const cardElement = elements.getElement(CardNumberElement);

  if (!cardElement) {
    throw Error("Could not collect card information");
  }

  const { error, token: stripe_token } = await stripe.createToken(cardElement);

  if (!stripe_token) {
    throw Error(
      `Could not collect card information: ${error?.message || "Unknown error"}`
    );
  }

  return stripe_token;
}
