import { TShirtSize } from "api/types";

export const TShirtSizeNames: Record<TShirtSize, string> = {
  [TShirtSize.MenS]: "Men S",
  [TShirtSize.MenM]: "Men M",
  [TShirtSize.MenL]: "Men L",
  [TShirtSize.MenXL]: "Men XL",
  [TShirtSize.MenXXL]: "Men XXL",
  [TShirtSize.MenXXXL]: "Men XXXL",
  [TShirtSize.WomenS]: "Women S",
  [TShirtSize.WomenM]: "Women M",
  [TShirtSize.WomenL]: "Women L",
  [TShirtSize.WomenXL]: "Women XL",
  [TShirtSize.WomenXXL]: "Women XXL",
};
