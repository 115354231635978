import { Golfer } from "api/golfer/account";

export function formatGolferHandle(golfer: Golfer | undefined): string {
  if (!golfer) {
    return "#";
  }
  const name = [golfer.first_name, golfer.last_name].filter(Boolean).join(" ");
  if (name) {
    return name;
  }
  if (golfer.email) {
    return golfer.email;
  }
  return "#";
}
