import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { EnsureGolferLoggedIn } from "utils/golfer-session";

import { JoinTournamentPage } from "./pages/JoinTournamentPage";
import { ThankYouPage } from "./pages/ThankYouPage";

const JoinTournamentSection = () => (
  <Switch>
    <Route
      exact
      path="/tournament/:slug/register"
      component={JoinTournamentPage}
    />
    <Route
      exact
      path="/tournament/:slug/register/thank-you"
      component={ThankYouPage}
    />
    <Route render={() => <Redirect to="/tournament/:slug/register" />} />
  </Switch>
);

export default EnsureGolferLoggedIn(
  JoinTournamentSection,
  "/golfer/create-account"
);
