import React from "react";
import { useParams } from "react-router-dom";

import { useTournamentById } from "api/organizer/tournaments";
import { useOrganizerTournamentRegistrations } from "api/organizer/tournament-registrations";
import { EnsureOrganizerLoggedIn } from "utils/organizer-session";

import TournamentScoresPage from "./TournamentScoresPage";
import {
  groupRegistrationScoreEntries,
  sortRegistrationScoreEntries,
} from "./TournamentScoresPage.utils";

function TournamentScoresPageContainer() {
  const { tournamentId } = useParams<{ tournamentId: string }>();

  const { tournament } = useTournamentById(parseInt(tournamentId));
  const {
    registrations,
    onRegistrationUpdated,
  } = useOrganizerTournamentRegistrations(parseInt(tournamentId));

  const entries = groupRegistrationScoreEntries(registrations);

  return (
    <TournamentScoresPage
      tournament={tournament}
      entries={sortRegistrationScoreEntries(entries)}
      onRegistrationUpdated={onRegistrationUpdated}
    />
  );
}

export default EnsureOrganizerLoggedIn(TournamentScoresPageContainer);
