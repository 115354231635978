import { AxiosResponse, CancelToken } from "axios";

import { BaseApiClient } from "../../ApiClient";
import { Golfer, getGolferByToken } from "./getGolferByToken";

export async function login(
  email: string,
  password: string,
  cancelToken?: CancelToken
): Promise<string> {
  const response = await BaseApiClient.request<
    void,
    AxiosResponse<{ user_access_token: { key: string } }>
  >({
    method: "POST",
    url: "/golfer/session",
    data: {
      email,
      password,
    },
    cancelToken: cancelToken,
  });
  return response.data.user_access_token.key;
}

export async function loginAndGetGolfer(
  email: string,
  password: string,
  cancelToken?: CancelToken
): Promise<{ user: Golfer; token: string }> {
  const t = await login(email, password, cancelToken);
  return await getGolferByToken(t, cancelToken);
}
