import { Tournament } from "api/types";

import { isTournamentStarted } from "./isTournamentStarted";
import { isTournamentEnded } from "./isTournamentEnded";

export function isTournamentInProgress(
  tournament: Pick<Tournament, "start_date" | "end_date">
): boolean {
  return isTournamentStarted(tournament) && !isTournamentEnded(tournament);
}
