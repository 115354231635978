import memoizeOne from "memoize-one";
import { getUnixTime, parseISO } from "date-fns";

import { OrganizerTournamentRegistration } from "api/organizer/tournament-registrations";
import { TournamentTeam } from "api/organizer/tournament-teams";

export interface TournamentParticipant {
  id: number;
  registration: OrganizerTournamentRegistration;
  kind: "team" | "individual";
  team?: TournamentTeam;
}

export const makeTournamentParticipantsList = memoizeOne(
  function makeTournamentParticipantsList(
    registrations: OrganizerTournamentRegistration[],
    teams: TournamentTeam[]
  ): TournamentParticipant[] {
    const teamsIndex = teams.reduce<Record<number, TournamentTeam>>(
      (acc, team) => {
        return { ...acc, [team.id]: team };
      },
      {}
    );

    return registrations.map((reg) => ({
      id: reg.id,
      kind: reg.kind,
      registration: reg,
      ...(reg.team_id && teamsIndex[reg.team_id]
        ? { team: teamsIndex[reg.team_id] }
        : {}),
    }));
  }
);

export const sortTournamentParticipants = memoizeOne(
  function sortTournamentParticipants(
    participants: TournamentParticipant[]
  ): TournamentParticipant[] {
    return [...participants].sort((p1, p2) => {
      return (
        getUnixTime(parseISO(p2.registration.created_at)) -
        getUnixTime(parseISO(p1.registration.created_at))
      );
    });
  }
);
