import React from "react";
import { TableRow, TableCell, Box, Typography } from "@material-ui/core";

export default function TableRowNoResults() {
  return (
    <TableRow>
      <TableCell colSpan={6}>
        <Box display="flex" alignItems="center" justifyContent="center" my={3}>
          <Typography variant="h5" style={{ opacity: 0.4 }}>
            No results yet
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
}
