import React from "react";
import { Field } from "react-final-form";
import { OutlinedInput, OutlinedInputProps } from "@material-ui/core";

import { FormRowWithHelperText } from "./FormRowWithHelperText";

interface FormRowInputProps extends OutlinedInputProps {
  name: string;
  helperText?: string;
}

function identity<T>(val: T): T {
  return val;
}

export function FormRowOutlinedURLInput({
  name,
  helperText,
  ...restProps
}: FormRowInputProps) {
  return (
    <Field name={name} parse={identity}>
      {({ input, meta }) => (
        <FormRowWithHelperText
          helperText={
            meta.touched
              ? meta.error || meta.submitError || helperText
              : helperText
          }
          isErrored={Boolean(meta.submitError || (meta.touched && meta.error))}
        >
          <OutlinedInput
            fullWidth={true}
            error={Boolean(meta.submitError || (meta.touched && meta.error))}
            type="url"
            {...restProps}
            {...input}
            onBlur={(evt) => {
              if (evt.target.value && !hasProto(evt.target.value)) {
                input.onChange(`https://${evt.target.value}`);
              }
              input.onBlur(evt);
            }}
          />
        </FormRowWithHelperText>
      )}
    </Field>
  );
}

function hasProto(url: string): boolean {
  return Boolean(url.match(/^[a-zA-Z]+:\/\//));
}
