import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { TournamentRegistration } from "api/golfer/tournament-registrations";

import ThankYouPage from "./ThankYouPage";

export default function ThankYouPageContainer() {
  const { state } = useLocation<{
    registration: TournamentRegistration | undefined;
  }>();
  const history = useHistory();

  const registration = state?.registration;

  useEffect(() => {
    if (!registration) {
      history.replace("/golfer/dashboard");
    }
  }, [history, registration]);

  return <ThankYouPage registration={registration} />;
}
