import { useState, useEffect, useCallback } from "react";
import axios from "axios";

import {
  TournamentRegistration,
  TournamentRegistrationPaymentStatus,
  getGolferTournamentRegistrationById,
} from "api/golfer/tournament-registrations";
import { useGolferSession } from "utils/golfer-session";

interface UseWaitForRegistrationPaymentResult {
  registration: TournamentRegistration;
  setPending: () => void;
}

export function useWaitForRegistrationPayment(
  initialRegistration: TournamentRegistration
): UseWaitForRegistrationPaymentResult {
  const [registration, setRegistration] = useState<TournamentRegistration>(
    initialRegistration
  );
  const { token } = useGolferSession();

  useEffect(() => {
    if (
      !token ||
      registration.status !== TournamentRegistrationPaymentStatus.Pending
    ) {
      return;
    }
    const cancelSource = axios.CancelToken.source();
    const timeout = setTimeout(() => {
      getGolferTournamentRegistrationById(
        token,
        registration.id,
        cancelSource.token
      ).then(setRegistration);
    }, 1000);

    return () => {
      clearTimeout(timeout);
      cancelSource.cancel("request was cancelled");
    };
  }, [token, registration]);

  const setPending = useCallback(() => {
    setRegistration((reg) => ({
      ...reg,
      status: TournamentRegistrationPaymentStatus.Pending,
    }));
  }, []);

  return { registration, setPending };
}
