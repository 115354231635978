import { AxiosResponse, CancelToken } from "axios";

import { Organization } from "./types";
import { makeOrganizerApiClient } from "../../ApiClient";

export async function getOrganization(
  token: string,
  cancelToken?: CancelToken
): Promise<Organization> {
  const response = await makeOrganizerApiClient(token).request<
    void,
    AxiosResponse<{ organization: Organization | undefined }>
  >({
    method: "GET",
    url: "/organizer/organization",
    cancelToken: cancelToken,
  });
  if (!response.data.organization) {
    throw Error("Organization does not exist");
  }
  return response.data.organization;
}
