import { CancelToken } from "axios";

import { makeGolferApiClient } from "api/ApiClient";

import { TournamentRegistration } from "./TournamentRegistration.types";

export async function getGolferTournamentRegistrationById(
  token: string,
  registrationId: number,
  cancelToken?: CancelToken
): Promise<TournamentRegistration> {
  const response = await makeGolferApiClient(token).request<{
    tournament_registration: TournamentRegistration;
  }>({
    method: "GET",
    url: `/golfer/tournament_registrations/${registrationId}`,
    cancelToken: cancelToken,
  });

  return response.data.tournament_registration;
}
