import React, { useCallback, useEffect } from "react";
import { Collapse, Typography, Button } from "@material-ui/core";
import { Field, Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useHistory, useLocation } from "react-router-dom";

import { GolferPage } from "components/layout";
import {
  BasicFormRow,
  FormRow,
  FormRowInputLabel,
} from "components/core/FormRow";
import { Alert } from "components/core/Alert";
import { getApiErrorMessage } from "utils/network";
import {
  TournamentRegistrationPaymentStatus,
  updateTournamentRegistrationPaymentMethod,
  TournamentRegistration,
} from "api/golfer/tournament-registrations";
import { EnsureGolferLoggedIn } from "utils/golfer-session";
import { withStripeElements } from "utils/withStripeElements.hoc";
import { StripeFieldWrapper } from "components/core/StripeFieldWrapper";

const StripeElementOptions = { style: { base: { fontSize: "16px" } } };

interface RegistrationPaymentMethodForm {
  stripe_token: string | undefined;
}

function UpdateRegistrationPaymentMethodPage() {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const {
    state: { registration },
  } = useLocation<{ registration: TournamentRegistration | undefined }>();

  useEffect(() => {
    if (registration?.status !== TournamentRegistrationPaymentStatus.Failed) {
      history.replace("/organizer/dashboard");
    }
  }, [history, registration]);

  const onSubmit = useCallback(async (): Promise<any> => {
    if (!stripe || !elements) {
      return {
        stripe_token:
          "Could not collect card information: Stripe is not initialized yet",
      };
    }

    const cardElement = elements.getElement(CardNumberElement);

    if (!cardElement) {
      return { stripe_token: "Could not collect card information" };
    }

    const { error, token: stripe_token } = await stripe.createToken(
      cardElement
    );

    if (error) {
      return {
        stripe_token: `Could not collect card information: ${error.message}`,
      };
    }

    try {
      await updateTournamentRegistrationPaymentMethod(
        registration?.id!,
        stripe_token?.id!
      );
      history.replace("/golfer/dashboard");
    } catch (error) {
      return { [FORM_ERROR]: getApiErrorMessage(error) };
    }
  }, [history, stripe, elements, registration]);

  return (
    <GolferPage>
      <Form<RegistrationPaymentMethodForm>
        initialValues={{ stripe_token: undefined }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, submitting, submitError }) => (
          <form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
            }}
          >
            {registration?.payment_status_message && (
              <BasicFormRow>
                <Alert fullWidth>
                  <Typography variant="body1">
                    {registration.payment_status_message}
                  </Typography>
                </Alert>
              </BasicFormRow>
            )}
            <BasicFormRow>
              <Typography variant="h4">
                Update Payment Method to fix error
              </Typography>
            </BasicFormRow>
            <FormRow
              withLabel={<FormRowInputLabel>Credit Card #</FormRowInputLabel>}
            >
              <StripeFieldWrapper>
                <CardNumberElement options={StripeElementOptions} />
              </StripeFieldWrapper>
            </FormRow>
            <FormRow
              withLabel={<FormRowInputLabel>Expiration</FormRowInputLabel>}
            >
              <StripeFieldWrapper width={200}>
                <CardExpiryElement options={StripeElementOptions} />
              </StripeFieldWrapper>
            </FormRow>
            <FormRow
              withLabel={<FormRowInputLabel>CVV / CVC</FormRowInputLabel>}
            >
              <StripeFieldWrapper width={200}>
                <CardCvcElement options={StripeElementOptions} />
              </StripeFieldWrapper>
            </FormRow>
            <Field name="stripe_token">
              {({ meta }) => (
                <Collapse in={Boolean(meta.submitError)}>
                  <BasicFormRow>
                    <Alert fullWidth>
                      <Typography variant="body1">
                        {meta.submitError}
                      </Typography>
                    </Alert>
                  </BasicFormRow>
                </Collapse>
              )}
            </Field>
            <Collapse in={Boolean(submitError)}>
              <BasicFormRow>
                <Alert fullWidth>
                  <Typography variant="body1">{submitError}</Typography>
                </Alert>
              </BasicFormRow>
            </Collapse>
            <FormRow>
              <Button disabled={submitting} type="submit">
                Update Payment Method
              </Button>
            </FormRow>
          </form>
        )}
      </Form>
    </GolferPage>
  );
}

export default EnsureGolferLoggedIn(
  withStripeElements(UpdateRegistrationPaymentMethodPage)
);
