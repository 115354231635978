import React, { useState, useEffect, useCallback } from "react";
import axios, { CancelToken } from "axios";
import { Autocomplete } from "@material-ui/lab";
import { TextField, Typography, Box } from "@material-ui/core";

import { Address, GolfCourse } from "api/types";

interface CourseSelectLabProps {
  id: string;
  name: string;
  value: GolfCourse | undefined;
  onChange: (nextValue: GolfCourse | undefined) => void;
  searchCourses: (
    inputValue: string,
    cancelToken: CancelToken
  ) => Promise<GolfCourse[]>;
  onBlur(evt: React.FocusEvent<HTMLElement>): void;
  onFocus(evt: React.FocusEvent<HTMLElement>): void;
  placeholder: string;
  disabled?: boolean;
}

const getOptionLabel = (option: GolfCourse): string => option.name;

export default function CourseSelectLab({
  id,
  name,
  value,
  onChange,
  searchCourses,
  onBlur,
  onFocus,
  placeholder,
  disabled = false,
}: CourseSelectLabProps) {
  const [isLoading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [courses, setCourses] = useState<GolfCourse[]>([]);

  const searchCoursesEnhanced = useCallback(
    async (inputValue, cancelToken) => {
      if (inputValue) {
        return await searchCourses(inputValue, cancelToken);
      } else {
        return [];
      }
    },
    [searchCourses]
  );

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    setLoading(true);
    searchCoursesEnhanced(inputValue, cancelSource.token)
      .then((courses) => {
        setCourses(courses);
        setLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setCourses([]);
          setLoading(false);
        }
      });
    return () => cancelSource.cancel("request was cancelled");
  }, [searchCoursesEnhanced, inputValue]);

  return (
    <Autocomplete<GolfCourse>
      id={id}
      getOptionLabel={getOptionLabel}
      getOptionSelected={(option: GolfCourse, value: GolfCourse): boolean =>
        Boolean(value && value.id === option.id)
      }
      filterOptions={(options: GolfCourse[]) => options}
      options={courses}
      autoComplete
      includeInputInList
      value={value ?? null}
      onChange={(evt, val) => onChange(val ?? undefined)}
      inputValue={inputValue}
      disabled={disabled}
      onInputChange={(evt, value) => {
        setInputValue(value ?? "");
      }}
      noOptionsText={
        inputValue ? (isLoading ? "..." : "No results") : "Type to search..."
      }
      fullWidth
      renderInput={({ InputProps, inputProps, ...restProps }) => (
        <TextField
          variant="outlined"
          name={name}
          InputProps={{ style: { padding: 1.5 }, ...InputProps }}
          placeholder={placeholder}
          inputProps={{
            ...inputProps,
            onBlur: (evt: React.FocusEvent<HTMLElement>) => {
              (inputProps as any).onBlur(evt);
              onBlur(evt);
            },
            onFocus: (evt: React.FocusEvent<HTMLElement>) => {
              (inputProps as any).onFocus(evt);
              onFocus(evt);
            },
          }}
          {...restProps}
        />
      )}
      renderOption={(course) => (
        <Box>
          <Typography variant="body1">{course.name}</Typography>
          <Typography variant="body2" color="textSecondary">
            {formatAddress(course.address)}
          </Typography>
        </Box>
      )}
    />
  );
}

function formatAddress(address: Address | undefined): string {
  if (!address) {
    return "-";
  }
  const place = [address.city, address.state_name].filter(Boolean).join(", ");
  return [place, address.zip].filter(Boolean).join(" ") || "-";
}
