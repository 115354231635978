import currency from "currency.js";
import memoizeOne from "memoize-one";

import { GuestTournament } from "api/guest/tournaments";
import { PromoCodeInfo } from "api/golfer/promo-codes";
import { formatPercents } from "utils/formatPercentage";

export interface RegistrationCost {
  base_fee: currency;
  mulligan_fee: currency | undefined;
  promo_code_info: PromoCodeInfo | undefined;
  single_slot_total: currency;
  number_of_additional_slots: number;
  total: currency;
}

export const computeRegistrationCost = memoizeOne(
  function computeRegistrationCost(
    tournament: GuestTournament | undefined,
    withMulligan: boolean,
    promoCodeInfo: PromoCodeInfo | undefined,
    numberOfAdditionalSlots: number
  ): RegistrationCost {
    const base_fee = currency(tournament?.registration_fee ?? "");
    const mulligan_fee = withMulligan
      ? currency(tournament?.mulligan_price ?? "")
      : undefined;
    const single_slot_total = computeRegistrationCostTotal(
      base_fee,
      mulligan_fee,
      promoCodeInfo
    );
    return {
      base_fee,
      mulligan_fee,
      promo_code_info: promoCodeInfo,
      single_slot_total,
      number_of_additional_slots: numberOfAdditionalSlots,
      total:
        numberOfAdditionalSlots === 0
          ? single_slot_total
          : single_slot_total.multiply(numberOfAdditionalSlots + 1),
    };
  }
);

function computeRegistrationCostTotal(
  base_fee: currency,
  mulligan_fee: currency | undefined,
  promo_code_info: PromoCodeInfo | undefined
): currency {
  const sub_total = mulligan_fee ? base_fee.add(mulligan_fee) : base_fee;
  return promo_code_info
    ? applyPromoCode(sub_total, promo_code_info)
    : sub_total;
}

function applyPromoCode(
  price: currency,
  promoCodeInfo: PromoCodeInfo | undefined
): currency {
  if (promoCodeInfo?.kind === "percentage") {
    return price.divide(100).multiply(100 - promoCodeInfo.discount);
  } else if (promoCodeInfo?.kind === "fixed") {
    const with_promo = price.subtract(currency(promoCodeInfo.discount));
    if (with_promo.intValue >= 0) {
      return with_promo;
    } else {
      return currency(0);
    }
  } else {
    return price;
  }
}

export function formatPromoCodeInfo(promoCodeInfo: PromoCodeInfo): string {
  if (promoCodeInfo.kind === "percentage") {
    return `-${formatPercents(promoCodeInfo.discount)}`;
  } else {
    return `-${currency(promoCodeInfo.discount).format()}`;
  }
}
