import React, { useCallback } from "react";
import { useParams, Link as RouterLink, useHistory } from "react-router-dom";
import { Button, Box } from "@material-ui/core";

import { OrganizerPage } from "components/layout";
import { useOrganizerSession } from "utils/organizer-session";
import { useTournamentById } from "api/organizer/tournaments";
import { useOrganization } from "api/organizer/organization";
import { TournamentView } from "components/pages/TournamentLandingPage/components/TournamentView";
import { isTournamentEnded } from "utils/tournament";

import { StartFundraiserHeader } from "../../components/StartFundraiserHeader";
import { StartFundraiserWizardSteps } from "../../StartFundraiserWizard.steps";
import { PublishTournamentButton } from "../../../OrganizerDashboardPage/components/PublishTournamentButton";

export default function TournamentPreviewPage() {
  const { tournamentId } = useParams<{ tournamentId: string }>();
  const { user } = useOrganizerSession();

  const [organization] = useOrganization();
  const { tournament } = useTournamentById(parseInt(tournamentId));
  const history = useHistory();

  const onTournamentPublished = useCallback(
    (tournament) => {
      history.replace(
        `/organizer/start-fundraiser/congrats-tournament/${tournament.id}`
      );
    },
    [history]
  );

  return (
    <OrganizerPage>
      <StartFundraiserHeader
        {...StartFundraiserWizardSteps.TournamentPreview}
      />
      <Box my={4}>
        <TournamentView
          tournament={tournament}
          organizerDetails={{
            logo: organization?.logo,
            email: organization?.email,
            first_name: user?.first_name,
            last_name: user?.last_name,
            phone: user?.phone,
          }}
        />
      </Box>
      <Box>
        {tournament && !isTournamentEnded(tournament) && (
          <PublishTournamentButton
            tournament={tournament}
            onPublished={onTournamentPublished}
          >
            {(props) => (
              <Button
                variant="contained"
                type="button"
                color="secondary"
                {...props}
              >
                Publish
              </Button>
            )}
          </PublishTournamentButton>
        )}
        <Button
          variant="text"
          type="button"
          component={RouterLink}
          to={`/organizer/start-fundraiser/edit-tournament/${tournamentId}`}
        >
          Edit
        </Button>
      </Box>
    </OrganizerPage>
  );
}
