import React from "react";
import { Button, Collapse, Typography, OutlinedInput } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import { Field } from "react-final-form";

import { OrganizerPage } from "components/layout";
import { Alert } from "components/core/Alert";
import {
  FormRow,
  FormRowInputLabel,
  FormRowOutlinedInput,
  FormRowImageInput,
  FormRowWithHelperText,
  FormRowOutlinedURLInput,
} from "components/core/FormRow";
import { StateIdSelect } from "components/core/StateIdSelect";

import { StartFundraiserHeader } from "../StartFundraiserWizard/components/StartFundraiserHeader";
import { StartFundraiserWizardSteps } from "../StartFundraiserWizard/StartFundraiserWizard.steps";

interface UpsertOrganizationPageProps {
  handleSubmit: (...args: any[]) => void;
  isLoading: boolean;
  submitting: boolean;
  submitError: string | undefined;
}

export default function UpsertOrganizationPage({
  handleSubmit,
  isLoading,
  submitting,
  submitError,
}: UpsertOrganizationPageProps) {
  return (
    <OrganizerPage>
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <StartFundraiserHeader
          {...StartFundraiserWizardSteps.CreateOrganization}
        />
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="organization-name">
              Organization Name
            </FormRowInputLabel>
          }
        >
          <FormRowOutlinedInput
            id="organization-name"
            name="name"
            placeholder="My Awesome Charity"
          />
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="organization-email">
              Charity Email
            </FormRowInputLabel>
          }
        >
          <FormRowOutlinedInput
            id="organization-email"
            name="email"
            type="email"
            placeholder="my.awesome.charity@example.com"
          />
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="organization-url">
              Charity Website
            </FormRowInputLabel>
          }
        >
          <FormRowOutlinedURLInput
            id="organization-url"
            name="url"
            placeholder="https://example.com/my-awesome-charity"
          />
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="organization-street-1">
              Street Address 1
            </FormRowInputLabel>
          }
        >
          <FormRowOutlinedInput
            id="organization-street-1"
            name="address_attributes.street_1"
            placeholder="123 Charity st."
          />
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="organization-street-2">
              Street Address 2
            </FormRowInputLabel>
          }
        >
          <FormRowOutlinedInput
            id="organization-street-2"
            name="address_attributes.street_2"
            placeholder="Suite 123"
          />
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="organization-city">
              City
            </FormRowInputLabel>
          }
        >
          <FormRowOutlinedInput
            id="organization-city"
            name="address_attributes.city"
          />
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="organization-state">
              State
            </FormRowInputLabel>
          }
        >
          <Field<number | undefined> name="address_attributes.country_id">
            {({ input: { value: countryId } }) => (
              <Field<number | undefined> name="address_attributes.state_id">
                {({ input, meta }) => (
                  <FormRowWithHelperText
                    helperText={
                      meta.touched ? meta.error || meta.submitError : null
                    }
                    isErrored={Boolean(
                      meta.touched && (meta.error || meta.submitError)
                    )}
                  >
                    <StateIdSelect
                      fullWidth
                      style={{ width: 220 }}
                      input={
                        <OutlinedInput
                          error={
                            meta.submitError || (meta.touched && meta.error)
                          }
                        />
                      }
                      id="organization-state"
                      countryId={countryId}
                      {...input}
                    />
                  </FormRowWithHelperText>
                )}
              </Field>
            )}
          </Field>
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="organization-zip">
              Zip
            </FormRowInputLabel>
          }
        >
          <FormRowOutlinedInput
            style={{ width: 120 }}
            id="organization-zip"
            type="text"
            name="address_attributes.zip"
            placeholder="12345"
          />
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="organization-logo">
              Logo
            </FormRowInputLabel>
          }
        >
          <FormRowImageInput
            id="organization-logo"
            name="logo"
            aspectRatio={1}
            helperText="For the best experience logo should be squared, e.g. 100x100 or larger."
          />
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="organization-ssn">
              TIN / SSN <LockIcon style={{ verticalAlign: "middle" }} />
            </FormRowInputLabel>
          }
        >
          <FormRowOutlinedInput
            style={{ width: 120 }}
            id="organization-ssn"
            type="text"
            name="tin_ssn_ciphertext"
            placeholder="111-22-3333"
            helperText="This information is needed for tax purposes and will not be displayed anywhere"
          />
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="organization-facebook-url">
              Facebook URL
            </FormRowInputLabel>
          }
        >
          <FormRowOutlinedURLInput
            id="organization-facebook-url"
            name="facebook_url"
            placeholder="https://fb.me/JohnDoe"
          />
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="organization-twitter-url">
              Twitter URL
            </FormRowInputLabel>
          }
        >
          <FormRowOutlinedURLInput
            id="organization-twitter-url"
            name="twitter_url"
            placeholder="https://twitter.com/JohnDoe"
          />
        </FormRow>
        <FormRow
          withLabel={
            <FormRowInputLabel htmlFor="organization-instagram-url">
              Instagram URL
            </FormRowInputLabel>
          }
        >
          <FormRowOutlinedURLInput
            id="organization-instagram-url"
            name="instagram_url"
            placeholder="https://instagram.com/JohnDoe"
          />
        </FormRow>
        <Collapse in={Boolean(submitError)}>
          <FormRow>
            <Alert fullWidth>
              <Typography variant="body1">{submitError}</Typography>
            </Alert>
          </FormRow>
        </Collapse>
        <FormRow>
          <Button type="submit" disabled={isLoading || submitting}>
            Save and Next
          </Button>
        </FormRow>
      </form>
    </OrganizerPage>
  );
}
