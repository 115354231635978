import React from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  Link,
  Avatar,
  Typography,
  ButtonBase,
  makeStyles,
} from "@material-ui/core";
import { Helmet } from "react-helmet";

import { SmartPage } from "components/layout";
import { useTournamentBySlug } from "api/guest/tournaments/hooks";
import {
  isTournamentRegistrationAvailable,
  isTournamentStarted,
} from "utils/tournament";
import { PageTitle, PageDescription } from "components/core/Helmets";
import { Alert } from "components/core/Alert";
import { TournamentStatus } from "api/types";

import { TournamentView } from "./components/TournamentView";

export default function TournamentLandingPage() {
  const { slug } = useParams<{ slug: string }>();
  const classes = useStyles();

  const { tournament, error } = useTournamentBySlug(slug);

  return (
    <SmartPage>
      {tournament && (
        <>
          <PageTitle title={tournament.name} />
          <PageDescription description={tournament.details.slice(0, 160)} />
          <Helmet>
            <meta property="og:image" content={tournament.image?.large} />
          </Helmet>
        </>
      )}
      {!error && (
        <>
          {tournament?.status === TournamentStatus.Draft && (
            <Box mb={2}>
              <Alert fullWidth>
                <Typography>This tournament is not published yet ;(</Typography>
              </Alert>
            </Box>
          )}
          <TournamentView
            tournament={tournament}
            organizerDetails={{
              logo: tournament?.organization.logo,
              email: tournament?.organization.email,
              first_name: tournament?.organization.organizer.first_name,
              last_name: tournament?.organization.organizer.last_name,
              phone: tournament?.organization.organizer.phone,
            }}
            withLinks={
              tournament?.status === TournamentStatus.Published && (
                <>
                  <Link
                    variant="body1"
                    underline="always"
                    component={RouterLink}
                    to={`/tournament/${slug}/entry-list`}
                    target="_blank"
                  >
                    Entry List
                  </Link>
                  {tournament && isTournamentStarted(tournament) && (
                    <Link
                      variant="body1"
                      underline="always"
                      component={RouterLink}
                      to={`/tournament/${slug}/leaderboard`}
                      target="_blank"
                    >
                      Leaderboard
                    </Link>
                  )}
                </>
              )
            }
          />
          <Box my={4}>
            {isTournamentRegistrationAvailable(tournament) && (
              <Button
                component={RouterLink}
                to={`/tournament/${tournament?.slug}/register`}
              >
                Register
              </Button>
            )}
          </Box>
          {tournament && tournament.sponsors.length > 0 && (
            <>
              <Typography variant="h5">Tournament Sponsors</Typography>
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="center"
              >
                {tournament?.sponsors.map((sponsor) => (
                  <ButtonBase
                    key={sponsor.id}
                    component="a"
                    href={sponsor.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.sponsor}
                  >
                    <Avatar
                      variant="square"
                      src={sponsor.logo?.medium}
                      alt={sponsor.name}
                      style={{ width: 120, height: 120 }}
                      imgProps={{ style: { objectFit: "contain" } }}
                    />
                  </ButtonBase>
                ))}
              </Box>
            </>
          )}
        </>
      )}
      {error && (
        <Box>
          <Alert fullWidth>
            <Typography>{error.message}</Typography>
          </Alert>
        </Box>
      )}
    </SmartPage>
  );
}

const useStyles = makeStyles((theme) => ({
  sponsor: {
    margin: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(6),
    },
  },
}));
