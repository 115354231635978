import React from "react";

import { OrganizerPage } from "components/layout";
import { TournamentFormModel } from "api/organizer/tournaments";
import { Tournament } from "api/types";
import { Organization } from "api/organizer/organization";

import { StartFundraiserHeader } from "../../components/StartFundraiserHeader";
import { TournamentForm } from "../../components/TournamentForm";
import { StartFundraiserWizardSteps } from "../../StartFundraiserWizard.steps";

interface EditTournamentPageProps {
  saveTournament: (formModel: TournamentFormModel) => Promise<void>;
  organization: Organization | undefined;
  tournament: Tournament | undefined;
}

export default function CreateTournamenEditTournamentPagetPage({
  saveTournament,
  tournament,
  organization,
}: EditTournamentPageProps) {
  return (
    <OrganizerPage>
      <StartFundraiserHeader {...StartFundraiserWizardSteps.CreateTournament} />
      <TournamentForm
        saveFormModel={saveTournament}
        initialTournament={tournament}
        organization={organization}
      />
    </OrganizerPage>
  );
}
