import { CancelToken } from "axios";

import { makeOrganizerApiClient } from "../../ApiClient";
import { TournamentTeam as TournamentTeamBase } from "../../types";

export interface TournamentTeam extends TournamentTeamBase {
  score: number | undefined;
  golfer_id: number;
}

export async function getTournamentTeams(
  token: string,
  tournamentId: number,
  cancelToken?: CancelToken
): Promise<TournamentTeam[]> {
  const response = await makeOrganizerApiClient(token).request<{
    teams: TournamentTeam[];
  }>({
    method: "GET",
    url: `/organizer/organization/tournaments/${tournamentId}/teams`,
    params: {
      "page[number]": 1,
      "page[size]": 100,
    },
    cancelToken: cancelToken,
  });

  return response.data.teams;
}
