import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  IconButton,
  Button,
  Fade,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Field } from "react-final-form";

import { Alert } from "components/core/Alert";
import { FormRowWithHelperText } from "components/core/FormRow";

import { RequestToAddCourseDialogProps } from "./RequestToAddCourseDialogProps";

interface RequestToAddCourseDialogUIProps
  extends RequestToAddCourseDialogProps {
  submitting: boolean;
  submitError: string | undefined;
  handleSubmit(...args: any[]): void;
}

export default function RequestToAddCourseDialog({
  isOpen,
  onClose,
  onExited,
  submitting,
  handleSubmit,
  submitError,
}: RequestToAddCourseDialogUIProps) {
  return (
    <Dialog open={isOpen} onClose={onClose} onExited={onExited}>
      <DialogTitle disableTypography>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5">Request to add a missing Course</Typography>
          <IconButton
            color="primary"
            style={{ float: "right" }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form id="request-to-add-course-form" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field<string> name="name">
                {({ input, meta }) => (
                  <FormRowWithHelperText
                    helperText={
                      meta.touched ? meta.error || meta.submitError : null
                    }
                    isErrored={Boolean(
                      meta.touched && (meta.error || meta.submitError)
                    )}
                  >
                    <InputLabel htmlFor="request-to-add-course-form-name">
                      Course Name
                    </InputLabel>
                    <Box mt={1}>
                      <OutlinedInput
                        fullWidth
                        id="request-to-add-course-form-name"
                        error={Boolean(
                          meta.touched && (meta.error || meta.submitError)
                        )}
                        {...input}
                      />
                    </Box>
                  </FormRowWithHelperText>
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field<string> name="tee_name">
                {({ input, meta }) => (
                  <FormRowWithHelperText
                    helperText={
                      meta.touched ? meta.error || meta.submitError : null
                    }
                    isErrored={Boolean(
                      meta.touched && (meta.error || meta.submitError)
                    )}
                  >
                    <InputLabel htmlFor="request-to-add-course-form-tee-name">
                      Tee Name
                    </InputLabel>
                    <Box mt={1}>
                      <OutlinedInput
                        fullWidth
                        id="request-to-add-course-form-tee-name"
                        error={Boolean(
                          meta.touched && (meta.error || meta.submitError)
                        )}
                        {...input}
                      />
                    </Box>
                  </FormRowWithHelperText>
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field<string> name="details">
                {({ input, meta }) => (
                  <FormRowWithHelperText
                    helperText={
                      meta.touched ? meta.error || meta.submitError : null
                    }
                    isErrored={Boolean(
                      meta.touched && (meta.error || meta.submitError)
                    )}
                  >
                    <InputLabel htmlFor="request-to-add-course-form-details">
                      Details
                    </InputLabel>
                    <Box mt={1}>
                      <OutlinedInput
                        fullWidth
                        multiline
                        inputProps={{
                          style: { height: 130, overflow: "auto" },
                        }}
                        id="request-to-add-course-form-details"
                        {...input}
                      />
                    </Box>
                  </FormRowWithHelperText>
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Fade in={Boolean(submitError)}>
                <Alert>
                  <Typography variant="body1">{submitError}</Typography>
                </Alert>
              </Fade>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          variant="text"
          onClick={onClose}
          disabled={submitting}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          form="request-to-add-course-form"
          disabled={submitting}
        >
          Send a Request
        </Button>
      </DialogActions>
    </Dialog>
  );
}
