import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
  Box,
} from "@material-ui/core";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useSnackbar } from "notistack";
import CloseIcon from "@material-ui/icons/Close";

import { CropperDialogProps } from "./CropperDialogProps";
import { getCroppedImg, isEligibleCrop } from "./getCroppedImg";
import { getInitialCropArea } from "./getInitialCropArea";

export default function CropperDialog({
  isOpen,
  onClose,
  onExited,
  sourceImage,
  onCrop,
  aspectRatio,
}: CropperDialogProps) {
  const [crop, setCrop] = useState<ReactCrop.Crop>({ aspect: aspectRatio });
  const imgRef = useRef<HTMLImageElement | undefined>();
  const [isCropping, setCropping] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!sourceImage) {
      setCrop({ aspect: 1 });
      imgRef.current = undefined;
    }
  }, [aspectRatio, sourceImage, aspectRatio]);

  useEffect(() => {
    if (crop && crop.aspect !== aspectRatio) {
      setCrop({
        aspect: aspectRatio,
      });
    }
  }, [crop, aspectRatio]);

  const onCompleteCrop = useCallback(async () => {
    if (sourceImage && imgRef.current && isEligibleCrop(crop)) {
      try {
        setCropping(true);
        const croppedImage = await getCroppedImg(
          imgRef.current,
          crop,
          sourceImage.fileName
        );
        setCropping(false);
        onCrop(croppedImage);
        onClose();
      } catch (err) {
        setCropping(false);
        enqueueSnackbar(err.message, { variant: "error" });
      }
    }
  }, [enqueueSnackbar, onClose, sourceImage, onCrop, crop]);

  return (
    <Dialog open={isOpen} onClose={onClose} onExited={onExited}>
      <DialogTitle disableTypography>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5">Crop your image</Typography>
          <IconButton
            color="primary"
            style={{ float: "right" }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {sourceImage && (
          <ReactCrop
            src={sourceImage.asDataUrl}
            crop={crop}
            onChange={(newCrop) => setCrop(newCrop)}
            onImageLoaded={(img) => {
              imgRef.current = img;
              setCrop(getInitialCropArea(img, aspectRatio));
              return false;
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" type="button" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="button"
          onClick={onCompleteCrop}
          disabled={isCropping || !imgRef.current || !isEligibleCrop(crop)}
        >
          Crop
        </Button>
      </DialogActions>
    </Dialog>
  );
}
