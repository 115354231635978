import React, { useCallback } from "react";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Grid,
  InputLabel,
  OutlinedInput,
  Grow,
  Typography,
} from "@material-ui/core";

import { GolferPage } from "components/layout";
import { Alert } from "components/core/Alert";
import { resetPassword } from "api/golfer/account";
import { EnsureGolferNotLoggedIn } from "utils/golfer-session";
import { getApiErrorMessage } from "utils/network";
import { PasswordsModel } from "api/types";

function GolferForgotPasswordPage() {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { token } = useParams<{ token: string }>();

  const onSubmit = useCallback(
    async (formModel: PasswordsModel): Promise<any> => {
      try {
        await resetPassword(token, formModel);
        enqueueSnackbar("Your password was succesfully updated", {
          variant: "success",
        });
        history.replace("/golfer/login");
      } catch (err) {
        return { [FORM_ERROR]: getApiErrorMessage(err) };
      }
    },
    [enqueueSnackbar, token, history]
  );

  return (
    <GolferPage contentProps={{ style: { maxWidth: 400 } }}>
      <Form<PasswordsModel>
        onSubmit={onSubmit}
        initialValues={{ password: "", password_confirmation: "" }}
      >
        {({ handleSubmit, submitting, submitError }) => (
          <form onSubmit={handleSubmit}>
            <Typography variant="h4" component="h1" align="center">
              Reset Password
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grow in={Boolean(submitError)}>
                  <Alert>
                    <Typography variant="body1">
                      {submitError ?? " "}
                    </Typography>
                  </Alert>
                </Grow>
              </Grid>
              <Field<string> name="password">
                {({ input, meta }) => (
                  <Grid item xs={12}>
                    <InputLabel htmlFor="reset-password">
                      New Password
                    </InputLabel>
                    <OutlinedInput
                      fullWidth
                      id="reset-password"
                      type="password"
                      autoFocus
                      autoComplete="new-password"
                      {...input}
                    />
                  </Grid>
                )}
              </Field>
              <Field<string> name="password_confirmation">
                {({ input, meta }) => (
                  <Grid item xs={12}>
                    <InputLabel htmlFor="reset-password-confirmation">
                      Confirm New Password
                    </InputLabel>
                    <OutlinedInput
                      fullWidth
                      id="reset-password-confirmation"
                      type="password"
                      autoComplete="new-password"
                      {...input}
                    />
                  </Grid>
                )}
              </Field>
              <Grid item xs={12}>
                <Button fullWidth type="submit" disabled={submitting}>
                  Save New Password
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Form>
    </GolferPage>
  );
}

export default EnsureGolferNotLoggedIn(GolferForgotPasswordPage);
