import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  photo: {
    width: 120,
    height: 120,
  },
  photoEmpty: {
    background: theme.palette.grey[200],
  },
  photoIcon: {
    fontSize: "3rem",
  },
  content: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
  },
}));
