import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Link,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import {
  usePopupState,
  bindToggle,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { SessionServiceStatus } from "utils/session";
import { formatOrganizerHandle } from "utils/organizer";
import { Organizer } from "api/organizer/account";

import { PageHeaderBase } from "../PageHeaderBase";

interface OrganizerPageHeaderProps {
  status: SessionServiceStatus;
  user?: Organizer;
  onLogout: () => void;
}

export default function OrganizerPageHeader({
  status,
  user,
  onLogout,
}: OrganizerPageHeaderProps) {
  const popupState = usePopupState({
    variant: "popper",
    popupId: `organizer-navigation`,
  });

  return (
    <PageHeaderBase
      to="/organizer"
      navigation={(() => {
        switch (status) {
          case SessionServiceStatus.Authenticated:
            return (
              <>
                <IconButton
                  color="inherit"
                  {...bindToggle(popupState)}
                  aria-label="Organizer's navigation menu"
                >
                  <MenuIcon />
                </IconButton>
                <Menu {...bindMenu(popupState)}>
                  <ListSubheader>{formatOrganizerHandle(user)}</ListSubheader>
                  <MenuItem
                    button
                    component={RouterLink}
                    to="/organizer/dashboard"
                    onClick={popupState.close}
                  >
                    <ListItemText primary="My Dashboard" />
                  </MenuItem>
                  <MenuItem
                    button
                    component={RouterLink}
                    to="/organizer/update-organization"
                    onClick={popupState.close}
                  >
                    <ListItemText primary="Organization Details" />
                  </MenuItem>
                  <MenuItem
                    button
                    onClick={() => {
                      popupState.close();
                      onLogout();
                    }}
                  >
                    Log Out
                  </MenuItem>
                </Menu>
              </>
            );
          case SessionServiceStatus.Unauthenticated:
            return (
              <>
                <Link
                  variant="body1"
                  color="inherit"
                  underline="none"
                  component={RouterLink}
                  to="/organizer/login"
                >
                  Log In
                </Link>
                <Link
                  variant="body1"
                  color="inherit"
                  underline="none"
                  component={RouterLink}
                  to="/organizer/create-account"
                >
                  Start a Tournament
                </Link>
              </>
            );
          default:
            return null;
        }
      })()}
    />
  );
}
