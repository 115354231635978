import { CancelToken } from "axios";

import { makeGolferApiClient } from "api/ApiClient";

import { TournamentRegistration } from "./TournamentRegistration.types";

export async function getGolferTournamentRegistrations(
  token: string,
  cancelToken?: CancelToken
): Promise<TournamentRegistration[]> {
  const response = await makeGolferApiClient(token).request<{
    tournament_registrations: TournamentRegistration[];
  }>({
    method: "GET",
    url: `/golfer/tournament_registrations`,
    cancelToken: cancelToken,
  });

  return response.data.tournament_registrations;
}
