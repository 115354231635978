import memoizeOne from "memoize-one";
import { getUnixTime, parseISO } from "date-fns";

import { OrganizerTournamentRegistration } from "api/organizer/tournament-registrations";
import { formatGolferName } from "utils/tournament/leaderboard";

export interface RegistrationScoreEntry {
  kind: "team" | "individual";
  id: string;
  name: string;
  main_registration: OrganizerTournamentRegistration;
  score_registration?: OrganizerTournamentRegistration;
  date_registered: string;
}

export const groupRegistrationScoreEntries = memoizeOne(
  function groupRegistrationScoreEntries(
    registrations: OrganizerTournamentRegistration[]
  ): RegistrationScoreEntry[] {
    const teamRegistrations: Record<
      number,
      OrganizerTournamentRegistration[]
    > = {};
    const individualRegistrations: OrganizerTournamentRegistration[] = [];
    registrations.forEach((registration) => {
      if (registration.team_id) {
        if (!teamRegistrations[registration.team_id]) {
          teamRegistrations[registration.team_id] = [];
        }
        teamRegistrations[registration.team_id].push(registration);
      } else {
        individualRegistrations.push(registration);
      }
    });

    const entries: RegistrationScoreEntry[] = [];

    Object.entries(teamRegistrations).forEach(([team_id, registrations]) => {
      const team_score_registration = registrations.find(
        (reg) => reg.score != null
      );
      entries.push({
        kind: "team",
        id: `team-${team_id}-${registrations[0].id}`,
        name: registrations[0].team_name ?? "<unknown team>",
        main_registration: registrations[0],
        score_registration: team_score_registration,
        date_registered: findEarliestRegistration(registrations)!.created_at,
      });
    });

    individualRegistrations.forEach((registration) => {
      entries.push({
        kind: "individual",
        id: `golfer-${registration.golfer_id}-${registration.id}`,
        name: formatGolferName(registration.golfer),
        main_registration: registration,
        score_registration:
          registration.score != null ? registration : undefined,
        date_registered: registration.created_at,
      });
    });

    return entries;
  }
);

export const sortRegistrationScoreEntries = memoizeOne(
  function sortRegistrationScoreEntries(
    entries: RegistrationScoreEntry[]
  ): RegistrationScoreEntry[] {
    return [...entries].sort((e1, e2) => {
      return (
        getUnixTime(parseISO(e2.date_registered)) -
        getUnixTime(parseISO(e1.date_registered))
      );
    });
  }
);

function findEarliestRegistration(
  registrations: OrganizerTournamentRegistration[]
): OrganizerTournamentRegistration | undefined {
  const sorted = [...registrations].sort((reg1, reg2) => {
    return (
      getUnixTime(parseISO(reg1.created_at)) -
      getUnixTime(parseISO(reg2.created_at))
    );
  });
  return sorted[0];
}
