import { Organizer } from "api/organizer/account";

export function formatOrganizerHandle(
  organizer: Organizer | undefined
): string {
  if (!organizer) {
    return "#";
  }
  const name = [organizer.first_name, organizer.last_name]
    .filter(Boolean)
    .join(" ");
  if (name) {
    return name;
  }
  if (organizer.email) {
    return organizer.email;
  }
  return "#";
}
