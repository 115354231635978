import { CancelToken } from "axios";

import { GolferApiClientAuto } from "api/ApiClient";
import { appendField } from "api/utils";

export async function uploadTournamentRegistrationPhoto(
  registrationId: number,
  photo: File,
  cancelToken?: CancelToken
): Promise<void> {
  const fd = new FormData();
  appendField(fd, "tournament_registration[photo]", photo);
  await GolferApiClientAuto.request({
    method: "PATCH",
    url: `/golfer/tournament_registrations/${registrationId}`,
    data: fd,
    cancelToken: cancelToken,
  });
}
