import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";

import { GolferPage } from "components/layout";
import { useGolferSession, EnsureGolferLoggedIn } from "utils/golfer-session";
import { InfoBox } from "components/core/InfoBox";
import { formatGolferHandle } from "utils/golfer";
import { useFeaturedTournaments } from "api/guest/tournaments/hooks";
import { AppConfig } from "config/AppConfig";
import { FeaturedTournamentCard } from "components/pages/LandingPage/components/FeaturedTournamentCard";

import { useTournamentRegistrations } from "./useTournamentRegistrations";
import {
  RegistrationRow,
  RegistrationRowSkeleton,
} from "./components/RegistrationRow";
import { sortTournamentRegistrations } from "./GolferDashboardPage.utils";

function GolferDashboardPage() {
  const { user: golfer } = useGolferSession();
  const registrations = useTournamentRegistrations();
  const { tournaments: featuredTournaments } = useFeaturedTournaments();

  return (
    <GolferPage>
      <Grid container spacing={4} alignItems="flex-end">
        <Grid item xs={12}>
          <Typography variant="h3" component="h1">
            Welcome back {formatGolferHandle(golfer)}!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">Your Tournaments</Typography>
        </Grid>
        {!registrations &&
          [1, 2, 3].map((key) => (
            <Grid item xs={12} key={key}>
              <RegistrationRowSkeleton />
            </Grid>
          ))}
        {registrations &&
          sortTournamentRegistrations(registrations).map((registration) => (
            <Grid item xs={12} key={registration.id}>
              <RegistrationRow registration={registration} />
            </Grid>
          ))}
        {registrations && registrations.length === 0 && (
          <Grid item xs={12}>
            <InfoBox>
              <Box
                py={5}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Typography variant="h5" align="center" color="textSecondary">
                  You'll see your tournaments here
                </Typography>
              </Box>
            </InfoBox>
          </Grid>
        )}
        {featuredTournaments && featuredTournaments.length > 0 && (
          <>
            <Grid item xs={12}>
              <Typography variant="h4">
                Featured {AppConfig.Application.ShortName} Fundraisers
              </Typography>
            </Grid>
            {featuredTournaments.map((tournament) => (
              <Grid item xs={12} md={6} lg={4} key={tournament.id}>
                <FeaturedTournamentCard tournament={tournament} />
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </GolferPage>
  );
}

export default EnsureGolferLoggedIn(GolferDashboardPage);
