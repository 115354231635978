import { ImageInputValue, isLocalImageValue } from "components/core/ImageInput";
import { appendField } from "api/utils";

export interface SponsorFormModel {
  name: string;
  url: string;
  logo: ImageInputValue | undefined;
}

export function convertCreateSponsorModelToFormData(
  formModel: SponsorFormModel
): FormData {
  const fd = new FormData();
  appendField(fd, "sponsor[name]", formModel.name);
  appendField(fd, "sponsor[url]", formModel.url);
  if (formModel.logo && isLocalImageValue(formModel.logo)) {
    appendField(fd, "sponsor[logo]", formModel.logo.file);
  }
  return fd;
}
