import { AxiosResponse, CancelToken } from "axios";

import { OrganizerApiClientAuto } from "../../ApiClient";
import { Tournament } from "../../types";
import { convertTournamentFormModelToFormData } from "./utils";
import { TournamentFormModel } from "./types";

export async function createTournament(
  formModel: TournamentFormModel,
  cancelToken?: CancelToken
): Promise<Tournament> {
  const response = await OrganizerApiClientAuto.request<
    void,
    AxiosResponse<{ tournament: Tournament }>
  >({
    method: "POST",
    url: "/organizer/organization/tournaments",
    data: convertTournamentFormModelToFormData(formModel),
    cancelToken: cancelToken,
  });
  return response.data.tournament;
}
