import { useState, useCallback, useEffect } from "react";
import EventEmitter from "eventemitter3";

function readSerializedLSValue<T>(
  serializedValue: string | undefined
): T | undefined {
  if (!serializedValue) {
    return undefined;
  }
  try {
    return JSON.parse(serializedValue);
  } catch {
    return undefined;
  }
}

type LSValueContainerChangeListener<T> = (newValue: T | undefined) => void;

interface LSValueContainer<T> {
  currentValue: T | undefined;
  setValue(newValue: T): void;
  clearValue(): void;
  addListener(listener: LSValueContainerChangeListener<T>): void;
  removeListener(listener: LSValueContainerChangeListener<T>): void;
}

export function createLSValueController<T>(
  lsValueKey: string
): LSValueContainer<T> {
  const emitter = new EventEmitter();

  const controller = {
    get currentValue(): T | undefined {
      return readSerializedLSValue(
        localStorage.getItem(lsValueKey) ?? undefined
      );
    },
    setValue(newValue: T): void {
      localStorage.setItem(lsValueKey, JSON.stringify(newValue));
      emitter.emit("change", newValue);
    },
    clearValue(): void {
      localStorage.removeItem(lsValueKey);
      emitter.emit("change", null);
    },
    addListener(listener: LSValueContainerChangeListener<T>): void {
      emitter.on("change", listener);
    },
    removeListener(listener: LSValueContainerChangeListener<T>): void {
      emitter.off("change", listener);
    },
  };

  window.addEventListener("storage", ({ key, newValue }) => {
    if (key === lsValueKey) {
      emitter.emit("change", readSerializedLSValue<T>(newValue ?? undefined));
    }
  });

  return controller;
}

export function useLSValue<T>(container: LSValueContainer<T>): T | undefined {
  const [currentValue, setCurrentValue] = useState<T | undefined>(
    container.currentValue
  );

  const onChange = useCallback((nextValue: T | undefined) => {
    setCurrentValue(nextValue);
  }, []);

  useEffect(() => {
    container.addListener(onChange);
    return () => container.removeListener(onChange);
  }, [container, onChange]);

  return currentValue;
}
