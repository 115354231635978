import { CancelToken } from "axios";

import { OrganizerApiClientAuto } from "../../ApiClient";
import { GolfCourse } from "../../types";

export async function searchCourses(
  query: string,
  cancelToken?: CancelToken
): Promise<GolfCourse[]> {
  const response = await OrganizerApiClientAuto.request<{
    courses: GolfCourse[];
  }>({
    method: "GET",
    url: "/organizer/courses",
    params: { query },
    cancelToken: cancelToken,
  });

  return response.data.courses;
}
