import React from "react";
import { InputLabel, InputLabelProps } from "@material-ui/core";
import clsx from "clsx";

import { useFormRowStyles } from "./useFormRowStyles";

interface FormRowInputLabelProps extends InputLabelProps {
  flex?: boolean;
}

export function FormRowInputLabel({
  className,
  children,
  flex = false,
  ...restProps
}: FormRowInputLabelProps) {
  const classes = useFormRowStyles();

  return (
    <InputLabel
      className={clsx(
        classes.formRowInputlabel,
        { [classes.formRowInputlabelFlex]: flex },
        className
      )}
      {...restProps}
    >
      {children}
    </InputLabel>
  );
}
