import axios, { AxiosInstance } from "axios";
import memoizeOne from "memoize-one";

import { addTokenToRequestConfig } from "config/addTokenToRequestConfig";
import { OrganizerSessionService } from "utils/organizer-session";

import { BaseApiClientConfig } from "./BaseApiClient";

export const makeOrganizerApiClient = memoizeOne(
  function makeOrganizerApiClient(token: string | undefined): AxiosInstance {
    return axios.create(addTokenToRequestConfig(BaseApiClientConfig, token));
  }
);

export const OrganizerApiClientAuto = axios.create(BaseApiClientConfig);

export function initializeOrganizerApiClientAuto(
  sessionService: OrganizerSessionService
) {
  OrganizerApiClientAuto.interceptors.request.use(
    function (config) {
      return addTokenToRequestConfig(config, sessionService.getState().token);
    },
    function (error) {
      return Promise.reject(error);
    }
  );
}
