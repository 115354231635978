import React from "react";
import { Typography, Box, Grid, Avatar, makeStyles } from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import currency from "currency.js";

import { TextSkeleton } from "components/core/Skeleton";
import { Tournament } from "api/types";

import { TournamentOrganizerDetails } from "./TournamentView.types";
import {
  formatTournamentDates,
  formatAddressLine1,
  formatAddressLine2,
  formatOrganizerName,
  formatTournamentForm,
} from "./TournamentView.utils";

interface TournamentViewProps {
  tournament: Tournament | undefined;
  organizerDetails: TournamentOrganizerDetails;
  withLinks?: React.ReactNode;
}

export default function TournamentView({
  tournament,
  organizerDetails,
  withLinks,
}: TournamentViewProps) {
  const classes = useStyles();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box className={classes.header}>
          {tournament ? (
            <Typography
              variant="h2"
              component="h1"
              className={classes.tournamentName}
            >
              {tournament.name}
            </Typography>
          ) : (
            <TextSkeleton variant="h2" width="65%" />
          )}
          <Box
            className={classes.tournamentLogo}
            style={{
              boxShadow: "0px 0px 8px 3px rgba(200,200,200,0.3)",
            }}
          >
            <Avatar
              variant="square"
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: "transparent",
              }}
              src={organizerDetails?.logo?.medium}
              alt="Organization Logo"
            >
              <ImageIcon style={{ color: "#9eadb9", fontSize: "1.5rem" }} />
            </Avatar>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.tournamentImage}>
          <Avatar
            variant="rounded"
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "transparent",
            }}
            src={tournament?.image?.large}
            alt="Organization Logo"
          >
            <ImageIcon style={{ color: "#9eadb9", fontSize: "5rem" }} />
          </Avatar>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8} md={9}>
        {tournament ? (
          <Typography variant="body1" style={{ whiteSpace: "pre-wrap" }}>
            {tournament?.details}
          </Typography>
        ) : (
          <>
            <TextSkeleton variant="body1" />
            <TextSkeleton variant="body1" />
            <TextSkeleton variant="body1" />
            <TextSkeleton variant="body1" width="50%" />
          </>
        )}
        <Typography variant="body1" style={{ whiteSpace: "pre-wrap" }}>
          {"\n"}
        </Typography>
        <Typography variant="body1">
          <b>Prizes / Swag:</b>
        </Typography>
        {tournament ? (
          <Typography variant="body1" style={{ whiteSpace: "pre-wrap" }}>
            {tournament?.prizes}
          </Typography>
        ) : (
          <>
            <TextSkeleton variant="body1" />
            <TextSkeleton variant="body1" width="75%" />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={4} md={3} className={classes.sidebar}>
        <Box>
          <Typography variant="h6">Tournament Format:</Typography>
          {tournament ? (
            <Typography variant="body1">
              {formatTournamentForm(tournament.form)}
            </Typography>
          ) : (
            <TextSkeleton variant="body1" width="50%" />
          )}
        </Box>
        <Box>
          <Typography variant="h6">Tournament Dates:</Typography>
          {tournament ? (
            <Typography variant="body1">
              {formatTournamentDates(tournament)}
            </Typography>
          ) : (
            <TextSkeleton variant="body1" width="50%" />
          )}
        </Box>
        <Box>
          <Typography variant="h6">Registration Fee:</Typography>
          {tournament ? (
            <Typography variant="body1">
              {currency(tournament.registration_fee).format()}
            </Typography>
          ) : (
            <TextSkeleton variant="body1" width={50} />
          )}
        </Box>
        {tournament?.course && (
          <Box>
            <Typography variant="h6">Preferred Course:</Typography>
            <Typography variant="body1">{tournament.course.name}</Typography>
            {formatAddressLine1(tournament.course.address) && (
              <Typography variant="body1">
                {formatAddressLine1(tournament.course.address)}
              </Typography>
            )}
            {formatAddressLine2(tournament.course.address) && (
              <Typography variant="body1">
                {formatAddressLine2(tournament.course.address)}
              </Typography>
            )}
          </Box>
        )}
        <Box>
          <Typography variant="body1">
            <b>
              This is a play anywhere event! You play at *any* golf course to
              participate!
            </b>
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6">Organizer Details:</Typography>
          <Typography variant="body1">
            Name: {formatOrganizerName(organizerDetails)}
          </Typography>
          <Typography variant="body1">
            Email: {organizerDetails?.email ?? ""}
          </Typography>
          <Typography variant="body1">
            Phone: {organizerDetails?.phone ?? ""}
          </Typography>
        </Box>
        {withLinks && <Box className={classes.links}>{withLinks}</Box>}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      alignItems: "center",
    },
  },
  tournamentName: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  tournamentLogo: {
    flexShrink: 0,
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(0.5),
    width: 82,
    height: 82,
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 0, 2),
    },
  },
  tournamentImage: {
    width: "100%",
    height: 300,
    boxShadow: "0px 0px 6px 3px rgba(200,200,200,0.2)",
  },
  sidebar: {
    "& > * + *": {
      marginTop: theme.spacing(4),
    },
  },
  links: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "&& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
}));
