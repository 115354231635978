import React, { useCallback } from "react";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { useSnackbar } from "notistack";
import { Link as RouterLink, useHistory } from "react-router-dom";
import {
  Button,
  Grid,
  InputLabel,
  OutlinedInput,
  Grow,
  Typography,
  Link,
  Box,
} from "@material-ui/core";

import { OrganizerPage } from "components/layout";
import { Alert } from "components/core/Alert";
import { requestPasswordResetEmail } from "api/organizer/account";
import { EnsureOrganizerNotLoggedIn } from "utils/organizer-session";
import { getApiErrorMessage } from "utils/network";

interface RequestPasswordResetFormModel {
  email: string;
}

function OrganizerForgotPasswordPage() {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const onSubmit = useCallback(
    async (formModel: RequestPasswordResetFormModel): Promise<any> => {
      try {
        await requestPasswordResetEmail(formModel.email);
        enqueueSnackbar("Reset password email was succesfully requested", {
          variant: "success",
        });
        history.replace("/organizer/login");
      } catch (err) {
        return { [FORM_ERROR]: getApiErrorMessage(err) };
      }
    },
    [enqueueSnackbar, history]
  );

  return (
    <OrganizerPage contentProps={{ style: { maxWidth: 400 } }}>
      <Form<RequestPasswordResetFormModel>
        onSubmit={onSubmit}
        initialValues={{ email: "" }}
      >
        {({ handleSubmit, submitting, submitError }) => (
          <form onSubmit={handleSubmit}>
            <Typography variant="h4" component="h1" align="center">
              Forgot Password
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grow in={Boolean(submitError)}>
                  <Alert>
                    <Typography variant="body1">
                      {submitError ?? " "}
                    </Typography>
                  </Alert>
                </Grow>
              </Grid>
              <Field<string> name="email">
                {({ input, meta }) => (
                  <Grid item xs={12}>
                    <InputLabel htmlFor="password-reset-email">
                      Email
                    </InputLabel>
                    <OutlinedInput
                      fullWidth
                      id="password-reset-email"
                      type="email"
                      placeholder="john.doe@example.com"
                      autoFocus
                      {...input}
                    />
                  </Grid>
                )}
              </Field>
              <Grid item xs={12}>
                <Button fullWidth type="submit" disabled={submitting}>
                  Request Link
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Box style={{ textAlign: "right" }}>
                  <Link component={RouterLink} to="/organizer/login">
                    Back to Login
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Form>
    </OrganizerPage>
  );
}

export default EnsureOrganizerNotLoggedIn(OrganizerForgotPasswordPage);
