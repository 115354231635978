import React from "react";
import { GolfCourseTee } from "api/types";

export default function FormatTeeDetails({
  tee,
}: {
  tee: Pick<GolfCourseTee, "gender" | "course_rating" | "slope_rating">;
}) {
  return (
    <span>
      Gender:&nbsp;{tee.gender}, Rating:&nbsp;{tee.course_rating}, Slope:&nbsp;
      {tee.slope_rating}
    </span>
  );
}
