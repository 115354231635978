import React from "react";
import { Box, Grid, Typography, Tooltip } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";

import {
  formatGolferName,
  TournamentEntrantIndividual,
} from "utils/tournament/leaderboard";

export default function IndividualEntrantRow({
  entrant,
}: {
  entrant: TournamentEntrantIndividual;
}) {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={6} sm={4} md={3}>
        <Box display="flex" alignItems="center">
          <Tooltip title="Individual">
            <PersonIcon style={{ cursor: "help" }} color="primary" />
          </Tooltip>
          <Box ml={1}>
            <Typography variant="h6" component="p">
              {formatGolferName(entrant.registration.golfer)}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
