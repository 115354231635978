import { AxiosResponse, CancelToken } from "axios";

import { BaseApiClient } from "../../ApiClient";
import { Address, Tournament, OrganizationLogo } from "../../types";

interface GuestTournamentOrganizer {
  first_name: string;
  last_name: string;
  phone: string;
}

interface GuestTournamentOrganization {
  id: number;
  name: string;
  email: string;
  url: string;
  logo: OrganizationLogo;
  address: Address;
  organizer: GuestTournamentOrganizer;
  registration_commission: number;
  mulligan_commission: number;
}

export interface GuestTournament extends Tournament {
  organization: GuestTournamentOrganization;
}

export async function getTournamentBySlug(
  slug: string,
  cancelToken?: CancelToken
): Promise<GuestTournament> {
  const response = await BaseApiClient.request<
    void,
    AxiosResponse<{ tournament: GuestTournament }>
  >({
    method: "GET",
    url: `/guest/tournaments/${slug}`,
    cancelToken: cancelToken,
  });
  return response.data.tournament;
}
