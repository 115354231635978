import React, { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  TournamentFormModel,
  useTournamentById,
  updateTournament,
} from "api/organizer/tournaments";

import EditTournamentPage from "./EditTournamentPage";
import { useOrganizationLock } from "../CreateTournamentPage/useOrganizationLock";

export default function EditTournamentPageContainer() {
  const history = useHistory();
  const { tournamentId } = useParams<{ tournamentId: string }>();

  const organization = useOrganizationLock();
  const { tournament } = useTournamentById(parseInt(tournamentId));

  const saveTournament = useCallback(
    async (formModel: TournamentFormModel): Promise<void> => {
      await updateTournament(parseInt(tournamentId), formModel);
      history.replace(
        `/organizer/start-fundraiser/preview-tournament/${tournamentId}`
      );
    },
    [history, tournamentId]
  );

  return (
    <EditTournamentPage
      saveTournament={saveTournament}
      organization={organization}
      tournament={tournament}
    />
  );
}
