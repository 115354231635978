import memoizeOne from "memoize-one";
import * as yup from "yup";

import {
  GolferRegistrationModel,
  GolferRegistrationErrors,
} from "api/golfer/account";
import { Country } from "api/common";
import {
  makeValidator,
  AddressAttributesSchema,
  PhoneNumberRegex,
} from "utils/forms";

export const makeInitialFormModel = memoizeOne(function makeInitialFormModel(
  country: Country | undefined
): GolferRegistrationModel {
  return {
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    password: "",
    password_confirmation: "",
    address_attributes: {
      country_id: country ? country.id : undefined,
      state_id: undefined,
      street_1: "",
      street_2: "",
      city: "",
      zip: "",
    },
  };
});

const GolferAccountSchema: yup.ObjectSchema<Partial<
  GolferRegistrationModel
>> = yup
  .object({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup
      .string()
      .matches(PhoneNumberRegex, "must be a valid phone number")
      .required(),
    address_attributes: AddressAttributesSchema,
    password: yup.string().required(),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password")], "passwords must match")
      .required(),
  })
  .defined();

export const validateGolferRegistrationModel = makeValidator<
  GolferRegistrationModel,
  GolferRegistrationErrors
>(GolferAccountSchema);
