import { parseISO, format } from "date-fns";

import { Address, Tournament, TournamentFormat } from "api/types";

import { TournamentOrganizerDetails } from "./TournamentView.types";

export function formatTournamentDates(tournament: Tournament): string {
  const start = format(parseISO(tournament.start_date), "MM/dd/yyyy");
  const end = format(parseISO(tournament.end_date), "MM/dd/yyyy");

  return `${start} — ${end}`;
}

export function formatAddressLine1(address: Address | undefined): string {
  if (!address) {
    return "";
  }
  return [address.street_1, address.street_2].filter(Boolean).join(", ");
}

export function formatAddressLine2(address: Address | undefined): string {
  if (!address) {
    return "";
  }
  const place = [address.city, address.state_name].filter(Boolean).join(", ");
  return [place, address.zip].filter(Boolean).join(" ");
}

export function formatOrganizerName(
  organizerDetails: TournamentOrganizerDetails
): string {
  return [organizerDetails.first_name, organizerDetails.last_name]
    .filter(Boolean)
    .join(" ");
}

export function formatTournamentForm(form: TournamentFormat): string {
  switch (form) {
    case TournamentFormat.Scramble:
      return "Scramble";
    case TournamentFormat.BestBall:
      return "Best Ball";
    case TournamentFormat.Individual:
      return "Individual";
    default:
      return "<unknown>";
  }
}
