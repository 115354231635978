import React from "react";
import { Box, Typography } from "@material-ui/core";

import { GuestTournamentRegistration } from "api/guest/tournaments";
import { FormatTeeDetails } from "components/core/TeeIdSelect";

interface CourseCellProps {
  registration: GuestTournamentRegistration;
}

export default function CourseCell({ registration }: CourseCellProps) {
  return (
    <Box>
      <Typography>{registration.course?.name}</Typography>
      {registration.tee && (
        <Typography variant="body2" color="textSecondary">
          {registration.tee.name} &mdash;{" "}
          <FormatTeeDetails tee={registration.tee} />
        </Typography>
      )}
    </Box>
  );
}
