import { CancelToken } from "axios";

import { BaseApiClient } from "../../ApiClient";
import { GolfCourseTee } from "../../types";

type Maybe<T> = T | undefined;

export interface GuestTournamentRegistration {
  id: number;
  golfer_id: number;
  tournament_id: number;
  team_id: Maybe<number>;
  course_id: Maybe<number>;
  tee_id: Maybe<number>;
  score: Maybe<number>;
  net_to_par: Maybe<number>;
  kind: "team" | "individual";
  golfer: {
    first_name: string;
    last_name: string;
  };
  team: Maybe<{ name: string }>;
  course: Maybe<{ name: string }>;
  photo: Maybe<{ medium: string; large: string; original: string }>;
  tee: Maybe<GolfCourseTee>;
}

export async function getGuestTournamentRegistrations(
  tournamentSlug: string,
  cancelToken?: CancelToken
): Promise<GuestTournamentRegistration[]> {
  const response = await BaseApiClient.request<{
    tournament_registrations: GuestTournamentRegistration[];
  }>({
    method: "GET",
    url: `/guest/tournaments/${tournamentSlug}/registrations`,
    params: {
      "page[number]": 1,
      "page[size]": 100,
    },
    cancelToken: cancelToken,
  });
  return response.data.tournament_registrations;
}
