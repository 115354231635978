import { CancelToken } from "axios";

import { makeGolferApiClient } from "../../ApiClient";
import { TournamentTeam as TournamentTeamBase } from "../../types";

export interface TournamentTeam extends TournamentTeamBase {}

export async function getTournamentTeams(
  token: string,
  tournamentId: number,
  cancelToken?: CancelToken
): Promise<TournamentTeam[]> {
  const response = await makeGolferApiClient(token).request<{
    teams: TournamentTeam[];
  }>({
    method: "GET",
    url: `/golfer/tournaments/${tournamentId}/teams`,
    params: {
      "page[number]": 1,
      "page[size]": 100,
    },
    cancelToken: cancelToken,
  });

  return response.data.teams;
}
