import * as yup from "yup";

import {
  OganizerRegistrationModel,
  OganizerRegistrationErrors,
} from "api/organizer/account";
import { makeValidator, PhoneNumberRegex } from "utils/forms";

export const InitialFormModel: OganizerRegistrationModel = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  password: "",
  password_confirmation: "",
  tos_accepted: false,
};

const GolferAccountSchema: yup.ObjectSchema<Partial<
  OganizerRegistrationModel
>> = yup
  .object({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup
      .string()
      .matches(PhoneNumberRegex, "must be a valid phone number")
      .required(),
    password: yup.string().required(),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password")], "passwords must match")
      .required(),
  })
  .defined();

export const validateOganizerRegistrationModel = makeValidator<
  OganizerRegistrationModel,
  OganizerRegistrationErrors
>(GolferAccountSchema);
