import React from "react";
import { Box } from "@material-ui/core";

import { TextSkeleton } from "components/core/Skeleton";

export default function CourseCellSkeleton() {
  return (
    <Box>
      <TextSkeleton variant="body1" width={250} />
      <TextSkeleton variant="body2" width={200} />
    </Box>
  );
}
