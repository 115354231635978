import React from "react";
import { Box, BoxProps, makeStyles } from "@material-ui/core";
import clsx from "clsx";

interface StripeFieldWrapperProps extends BoxProps {}

export default function StripeFieldWrapper({
  className,
  children,
  ...restProps
}: StripeFieldWrapperProps) {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)} {...restProps}>
      {children}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "center",
    "& .StripeElement": {
      borderRadius: theme.shape.borderRadius,
      borderStyle: "solid",
      borderWidth: 1,
      // Credits: https://github.com/mui-org/material-ui/blob/v4.10.2/packages/material-ui/src/OutlinedInput/OutlinedInput.js#L11
      borderColor:
        theme.palette.type === "light"
          ? "rgba(0, 0, 0, 0.23)"
          : "rgba(255, 255, 255, 0.23)",
      padding: "12px 12px 8px",
      boxSizing: "border-box",
      "&:hover": {
        borderColor: theme.palette.text.primary,
      },
      "&.StripeElement--focus": {
        borderColor: theme.palette.primary.main,
      },
      "&.StripeElement--invalid": {
        borderColor: theme.palette.error.main,
      },
    },
  },
}));
