import React, { useMemo } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { AppConfig } from "config/AppConfig";

import { getComponentWrappedName } from "./getComponentWrappedName";

export function withStripeElements<P>(
  Component: React.ComponentType<P>
): React.ComponentType<P> {
  function WithStripeElementsWrapper(props: P) {
    const stripePromise = useMemo(
      () => loadStripe(AppConfig.Integrations.Stripe.Token),
      []
    );

    return (
      <Elements stripe={stripePromise}>
        <Component {...props} />
      </Elements>
    );
  }

  WithStripeElementsWrapper.displayName = getComponentWrappedName(
    "WithStripeElements",
    Component
  );

  return WithStripeElementsWrapper;
}
