import React from "react";
import { SnackbarProvider } from "notistack";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { History } from "history";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";

import {
  OrganizerSessionService,
  OrganizerSessionProvider,
} from "utils/organizer-session";

import {
  GolferSessionService,
  GolferSessionProvider,
} from "utils/golfer-session";
import { FlexGolfTheme } from "config/theme";
import { AppRootHelmet } from "components/core/Helmets";

import { LandingPage } from "../../pages/LandingPage";
import { TournamentLandingPage } from "../../pages/TournamentLandingPage";
import { OrganizerSection } from "../../pages/OrganizerSection";
import {
  GolferSection,
  JoinTournamentSection,
} from "../../pages/GolferSection";
import { TournamentLeaderboardPage } from "../../pages/TournamentLeaderboardPage";
import { TournamentEntryListPage } from "../../pages/TournamentEntryListPage";
import { TermsOfServicePage, PrivacyPolicyPage } from "../../content-pages";

interface AppRootProps {
  sessionServiceOrganizer: OrganizerSessionService;
  sessionServiceGolfer: GolferSessionService;
  history: History;
  muiTheme: FlexGolfTheme;
}

export default function AppRoot({
  history,
  sessionServiceOrganizer,
  sessionServiceGolfer,
  muiTheme,
}: AppRootProps) {
  return (
    <React.StrictMode>
      <OrganizerSessionProvider sessionService={sessionServiceOrganizer}>
        <GolferSessionProvider sessionService={sessionServiceGolfer}>
          <MuiThemeProvider<FlexGolfTheme> theme={muiTheme}>
            <CssBaseline />
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Router history={history}>
                <AppRootHelmet />
                <Switch>
                  <Route exact path="/" component={LandingPage} />
                  <Route
                    exact
                    path="/tournament/:slug"
                    component={TournamentLandingPage}
                  />
                  <Route
                    path="/tournament/:slug/register"
                    component={JoinTournamentSection}
                  />
                  <Route
                    exact
                    path="/tournament/:slug/leaderboard"
                    component={TournamentLeaderboardPage}
                  />
                  <Route
                    exact
                    path="/tournament/:slug/entry-list"
                    component={TournamentEntryListPage}
                  />
                  <Route path="/organizer" component={OrganizerSection} />
                  <Route path="/golfer" component={GolferSection} />
                  <Route
                    exact
                    path="/terms-of-service"
                    component={TermsOfServicePage}
                  />
                  <Route
                    exact
                    path="/privacy-policy"
                    component={PrivacyPolicyPage}
                  />
                  <Route render={() => <Redirect to="/" />} />
                </Switch>
              </Router>
            </SnackbarProvider>
          </MuiThemeProvider>
        </GolferSessionProvider>
      </OrganizerSessionProvider>
    </React.StrictMode>
  );
}
